import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons/lib';
import { Formik, FormikHelpers } from 'formik';

import { NotificationType, notify } from '../../../service/notification-service';
import { TsoResponse } from '../../../api/model/tso-response';
import { TsoModel } from '../../../api/model/tso-model';
import { ListItem } from '../../../api/model/list-item';
import { tsoService } from '../../../api/tso-service';
import { capacityCategoryService } from '../../../api/capacity-category-service';
import { networkPointService } from '../../../api/network-point-service';
import { SelectOptionModel } from '../../../components/inputs/select-input/select-input';
import { TsoForm } from './form/tso-form';
import { TsoFormModel } from './form/tso-form-model';
import { TsoSchema } from './form/tso-schema';

import './form/tso-form.less';

export const SettingsPage: React.FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [allTsos, setAllTsos] = useState<TsoResponse[]>([]);
    const [tso, setTso] = useState<TsoModel>();
    const [allCapacityCategories, setAllCapacityCategories] = useState<ListItem[]>([]);
    const [allNetworkPoints, setAllNetworkPoints] = useState<ListItem[]>([]);

    const loadTso = (tsoId: number) => {
        setIsLoading(true);
        tsoService.getTso(tsoId)
            .then(response => {
                setTso(response);
                setIsLoading(false);
            })
            .catch((error: any) => {
                notify({
                    type: NotificationType.ERROR,
                    message: error.toString()
                });
                setIsLoading(false);
            });
    };

    const loadNetworkPoints = (tsoId: number) => {
        setIsLoading(true);
        networkPointService.getNetworkPointsByTso(tsoId)
            .then(networkPoints => {
                setAllNetworkPoints(networkPoints);
                setIsLoading(false);
            })
            .catch((error: any) => {
                notify({
                    type: NotificationType.ERROR,
                    message: error.toString()
                });
                setIsLoading(false);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            capacityCategoryService.getAllCapacityCategories(),
            tsoService.getAllTso()])
            .then(([categories, tsos]) => {
                setAllCapacityCategories(categories);
                setAllTsos(tsos);
                if (tsos.length > 0) {
                    loadTso(tsos[0].id);
                    loadNetworkPoints(tsos[0].id);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error: any) => {
                notify({
                    type: NotificationType.ERROR,
                    message: error.toString()
                });
                setIsLoading(false);
            });
    }, []);

    const initialValues: TsoFormModel = {
        name: tso ? tso.name : '',
        expirationTime: tso ? tso.expirationTime : undefined,
        validationTime: tso ? tso.validationTime : undefined,
        canApplyTradersList:tso ? tso.canApplyTradersList : false,
        networkPointIds: tso?.networkPoints?.map(n => n.id),
        capacityCategoryIds: tso?.capacityCategories?.map(c => c.id)
    };

    const handleSubmit = (values: TsoFormModel, formikHelpers: FormikHelpers<TsoFormModel>) => {
        tso?.id
            ? tsoService.updateTso(tso.id, values).then((response) => {
                notify({
                    type: NotificationType.SUCCESS,
                    message: 'TSO updated successfully'
                });
                const updatedTSOs = allTsos.filter(t => t.id !== tso.id);

                updatedTSOs.push({ id: tso.id, name: values.name ? values.name : '' });
                setAllTsos(updatedTSOs);
                setTso(response);
                formikHelpers.setSubmitting(false);
            }).catch(() => {
                notify({
                    type: NotificationType.ERROR,
                    message: 'TSO could not be updated'
                });
                formikHelpers.setSubmitting(false);
            })
            : formikHelpers.setSubmitting(false);
    };

    function mapListItemToSelectOption(listItem: ListItem): SelectOptionModel {
        return {
            label: listItem.name,
            value: listItem.id
        };
    }

    const handleSelectChange = (tsoId: number) => {
        loadTso(tsoId);
        loadNetworkPoints(tsoId);
    };

    return (
        <Row justify="center">
            <Col xs={20} sm={15}>
                <Row gutter={64}>
                    <Col xs={10} className="page-header">
                        <Typography.Title level={4}>
                            Settings
                        </Typography.Title>
                    </Col>
                </Row>
                <Spin spinning={isLoading} indicator={<LoadingOutlined/>} size="large">
                    <Formik
                        enableReinitialize={true}
                        initialValues={isLoading ? {} : initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={TsoSchema}>
                        <TsoForm
                            tso={tso}
                            allTsos={allTsos}
                            networkPointSelectOptions={allNetworkPoints.map(mapListItemToSelectOption)}
                            capacityCategorySelectOptions={allCapacityCategories.map(mapListItemToSelectOption)}
                            onSelectChange={handleSelectChange}
                        />
                    </Formik>
                </Spin>
            </Col>
        </Row>
    );
};

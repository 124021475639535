import React, { useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Col, Row, Spin, } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '../../../../components/inputs/common/form';
import { SubmitButton } from '../../../../components/button/submit-button';
import { FormItem } from '../../../../components/inputs/common/form-item';
import { TextInput } from '../../../../components/inputs/text-input/text-input';
import { USER_DETAILS_FORM_FIELDS, userDetailsFormSchema } from './user-details-form-schema';
import { userService } from '../../../../api/user-service';
import { UserRegistrationFormModel } from '../../user-registration/form/user-registration-form-model';
import { NotificationType, notify } from '../../../../service/notification-service';
import { UserDetailsFormModel } from './user-details-form-model';
import { LabeledText } from '../../../../components/text/labeled-text';
import { AccessRight } from '../../../../config/constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { Role } from '../../../../components/auth/private-route';
import { UnsavedChangesPrompt } from '../../../../components/prompt/unsaved-changes-prompt';
import { userLogin } from '../../../../redux/authentication/actions';
import { LoginResponse } from '../../../../api/model/login-response';

import './../../user-registration/form/user-registration-form.less';

export const UserDetailsForm = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [user, setUser] = useState<UserRegistrationFormModel>();
    const authentication = useSelector((state: RootState) => state.authentication);
    const [initialValues, setInitialValues] = useState<UserRegistrationFormModel>({
        companies: [],
        position: '',
        phone: '',
        lastName: '',
        firstName: '',
        email: ''
    });
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        userService.getUserDetails()
            .then((response) => {
                setUser(response);
                setInitialValues({
                    email: response.email,
                    firstName: response.firstName,
                    lastName: response.lastName,
                    phone: response.phone,
                    position: response.position
                });
                setIsLoading(false);
            }).catch(() => {
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not load user details'
                });
            });
    }, []);

    const renderUserFields = () => {
        return <>
            <FormItem name={USER_DETAILS_FORM_FIELDS.email} label={'Email'} labelType="horizontal">
                <TextInput name={USER_DETAILS_FORM_FIELDS.email} disabled={isLoading} disableOnSubmit={true}/>
            </FormItem>
            <FormItem name={USER_DETAILS_FORM_FIELDS.firstName} label={'First name'} labelType="horizontal">
                <TextInput name={USER_DETAILS_FORM_FIELDS.firstName} disabled={isLoading} disableOnSubmit={true}/>
            </FormItem>
            <FormItem name={USER_DETAILS_FORM_FIELDS.lastName} label={'Last name'} labelType="horizontal">
                <TextInput name={USER_DETAILS_FORM_FIELDS.lastName} disabled={isLoading} disableOnSubmit={true}/>
            </FormItem>
            <FormItem name={USER_DETAILS_FORM_FIELDS.position} label={'Position'} labelType="horizontal">
                <TextInput name={USER_DETAILS_FORM_FIELDS.position} disabled={isLoading} disableOnSubmit={true}/>
            </FormItem>
            <FormItem name={USER_DETAILS_FORM_FIELDS.phone} label={'Phone'} labelType="horizontal">
                <TextInput name={USER_DETAILS_FORM_FIELDS.phone} disabled={isLoading} disableOnSubmit={true}/>
            </FormItem>
        </>;
    };

    const renderCompaniesList = () => {
        return user && user.companies && user.companies.map(c => {
            return <LabeledText
                key={c.companyName}
                labelAlign={'left'}
                label={c.companyName ? c.companyName : ''}
                value={c.role === AccessRight.FULL_CONTROL_USER ? 'Full control user' : 'Read only user'}
            />;
        });
    };

    function updateUser(values: UserDetailsFormModel, formikHelpers: FormikHelpers<UserDetailsFormModel>) {
        userService.updateUserDetails(values)
            .then((response: LoginResponse) => {
                notify({
                    message: 'Updated details successfully',
                    type: NotificationType.SUCCESS
                });

                const role = response
                    ? response.isAdmin ? Role.SYSTEM_ADMIN : Role.SYSTEM_USER
                    : authentication.role;
                const selectedCompany = response
                    ? response.companyRoleMap ? response.companyRoleMap[0] : undefined
                    : authentication.selectedCompany;

                dispatch(userLogin({
                    isLoggedIn: true,
                    role: role,
                    firstName: response ? response.firstName : values.firstName,
                    lastName: response ? response.lastName : values.lastName,
                    companyRoleMap: response ? response.companyRoleMap : authentication.companyRoleMap,
                    selectedCompany: selectedCompany
                }));

                formikHelpers.setSubmitting(false);
            })
            .catch(() => {
                notify({
                    message: 'Could not update user details',
                    type: NotificationType.ERROR
                });

                formikHelpers.setSubmitting(false);
            });
    }

    return <Spin spinning={isLoading && !initialValues} indicator={<LoadingOutlined/>} size="large">
        <Formik
            onSubmit={updateUser}
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={userDetailsFormSchema}
        >
            {({ values, isSubmitting }) =>
                <Form className="user-registration-form">
                    {renderUserFields()}
                    {authentication.role !== Role.SYSTEM_ADMIN &&
                        <Row className="companies" gutter={64}>
                            <Col xs={10} className="label">Represented company (-ies)</Col>
                            <Col xs={14}>
                                {renderCompaniesList()}
                            </Col>
                        </Row>
                    }
                    <Row justify="end">
                        <Col>
                            <SubmitButton>Save</SubmitButton>
                        </Col>
                    </Row>
                    <UnsavedChangesPrompt
                        when={values !== initialValues && !isSubmitting}
                        message={'You have unsaved changes, are you sure you want to leave this page?'}
                    />
                </Form>
            }
        </Formik>
    </Spin>;
};

import React from 'react';
import { Collapse } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { Formik, FormikHelpers } from 'formik';

export interface TableFilterProps<T, V> {
    filtersVisible: boolean;
    handleSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void;
    initialValues: T;
    form: React.ReactElement<V>;
}

export const TableFilter = <T, V extends {}>(props: TableFilterProps<T, V>) => {
    const {
        filtersVisible,
        handleSubmit,
        initialValues,
        form
    } = props;

    return <Collapse ghost={true} activeKey={filtersVisible ? '1' : []}>
        <CollapsePanel
            key={'1'}
            header={''}
            showArrow={false}
            className={'collapse-header'}
            forceRender={true}
        >
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                enableReinitialize={true}
            >
                {form}
            </Formik>
        </CollapsePanel>
    </Collapse>;
};

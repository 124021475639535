import { ProposalType } from '../../trade-proposals/new-trade-proposal/form/new-trade-proposal-schema';
import { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { ResponseStatus } from '../../../../config/constants';

export enum RESPONSE_FILTER_FIELDS {
    offerTypes = 'offerTypes',
    capacityCategories = 'capacityCategories',
    networkPoints = 'networkPoints',
    tsos = 'tsos',
    procedures = 'procedures',
    period = 'period',
    capacityRange = 'capacityRange',
    totalPriceRange = 'totalPriceRange',
    responseStatuses = 'responseStatuses'
}

export interface ResponseFilterFormModel {
    offerTypes: ProposalType[];
    capacityCategories: number[];
    networkPoints: number[];
    tsos: number[];
    procedures: string[];
    period: RangeValue<Moment>;
    capacityRange: [number, number];
    totalPriceRange: [number, number];
    responseStatuses: ResponseStatus[];
}

export const CAPACITY_RANGE_MIN = 0;
export const TOTAL_PRICE_MIN = 0;

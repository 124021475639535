import React from 'react';
import { Button, Col, Row } from 'antd';
import { useFormikContext } from 'formik';

import { FormItem } from '../../../../components/inputs/common/form-item';
import { CheckboxGroupInput } from '../../../../components/inputs/checkbox-input/checkbox-group-input';
import { SelectInput, SelectOptionModel } from '../../../../components/inputs/select-input/select-input';
import { CalendarRangeInput } from '../../../../components/inputs/calendar-input/calendar-range-input';
import { SliderInput } from '../../../../components/inputs/slider-input/slider-input';
import { Form } from '../../../../components/inputs/common/form';
import { SubmitButton } from '../../../../components/button/submit-button';
import {
    CAPACITY_RANGE_MIN,
    TOTAL_PRICE_MIN,
    TRADE_PROPOSAL_FILTER_FIELDS,
    TradeProposalFilterFormModel
} from './trade-proposal-filter-form-model';
import { tsoService } from '../../../../api/tso-service';
import { networkPointService } from '../../../../api/network-point-service';
import { tradeProposalService } from '../../../../api/trade-proposal-service';
import {
    capacityUnit, priceUnit,
    TradeProposalStatus,
    TradeProposalStatusNameMap,
    TradeProposalStatusNames,
    proposalCheckboxOptions
} from '../../../../config/constants';

import './trade-proposal-filter-form.less';

interface TradeProposalFilterFormProps {
    handleReset: () => void;
    maxPrice: number;
    maxCapacity: number;
    isPublic: boolean;
    disableButtons?: boolean;
}

export const TradeProposalFilterForm = (props: TradeProposalFilterFormProps) => {

    const {
        isPublic,
        maxPrice,
        maxCapacity,
        disableButtons
    } = props;

    const formik = useFormikContext<TradeProposalFilterFormModel>();

    function loadTsos() {
        return tsoService.getAllTso();
    }

    function loadNetworkPoints() {
        return networkPointService.getAllNetworkPoints();
    }

    function loadProcedures() {
        return tradeProposalService.getAllProcedures()
            .then(procedures => {
                return procedures.map(procedure => {
                    return { id: procedure, name: procedure };
                });
            });
    }

    function mapListItemToOptions(value: {id: number | string; name: string}): SelectOptionModel {
        return {
            value: value.id,
            label: value.name
        };
    }

    function mapTradeProposalStatusToOptions(value: TradeProposalStatus[]): SelectOptionModel[] {
        return value.map(v => {
            return {
                value: v,
                label: TradeProposalStatusNameMap[v]
            };
        });
    }

    function handleFilterReset() {
        formik.resetForm();
        props.handleReset();
    }

    function renderSliders(): React.ReactNode {
        return <Col xs={12} className={'form-col'}>
            <FormItem
                className={'filter-form-item'}
                name={TRADE_PROPOSAL_FILTER_FIELDS.capacityRange}
            >
                <SliderInput
                    label={'Capacity amount'}
                    name={TRADE_PROPOSAL_FILTER_FIELDS.capacityRange}
                    range={true}
                    unit={capacityUnit}
                    min={CAPACITY_RANGE_MIN}
                    max={maxCapacity + 1}
                    step={1}
                />
            </FormItem>
            <FormItem
                className={'filter-form-item'}
                name={TRADE_PROPOSAL_FILTER_FIELDS.totalPriceRange}
            >
                <SliderInput
                    label={'Price'}
                    name={TRADE_PROPOSAL_FILTER_FIELDS.totalPriceRange}
                    range={true}
                    unit={priceUnit}
                    min={TOTAL_PRICE_MIN}
                    max={maxPrice}
                    step={0.01}
                />
            </FormItem>
        </Col>;
    }

    function renderFilterFields(): React.ReactNode {
        return <Col xs={12} className={'form-col'}>
            <FormItem name={TRADE_PROPOSAL_FILTER_FIELDS.offerTypes}>
                <CheckboxGroupInput
                    className={'offer-options'}
                    name={TRADE_PROPOSAL_FILTER_FIELDS.offerTypes}
                    options={proposalCheckboxOptions}
                />
            </FormItem>
            <FormItem
                className={'filter-form-item'}
                name={TRADE_PROPOSAL_FILTER_FIELDS.networkPoints}
                labelType={'horizontal'}
                label={'Network points'}
            >
                <SelectInput
                    mode="multiple"
                    name={TRADE_PROPOSAL_FILTER_FIELDS.networkPoints}
                    lazyLoad={formik.initialValues.networkPoints.length > 0}
                    service={loadNetworkPoints}
                    mapItemToOptions={mapListItemToOptions}
                    placeholder={'All'}
                />
            </FormItem>
            {/* TODO: uncomment when filtering by TSO is needed
            <FormItem
                className={'filter-form-item'}
                name={TRADE_PROPOSAL_FILTER_FIELDS.tsos}
                labelType={'horizontal'}
                label={'TSO'}
            >
                <SelectInput
                    mode="multiple"
                    name={TRADE_PROPOSAL_FILTER_FIELDS.tsos}
                    service={loadTsos}
                    lazyLoad={formik.initialValues.tsos.length > 0}
                    mapItemToOptions={mapListItemToOptions}
                    placeholder={'All'}
                />
            </FormItem>
            */}
            {!isPublic &&
               <FormItem
                   className={'filter-form-item'}
                   name={TRADE_PROPOSAL_FILTER_FIELDS.tradeProposalStatuses}
                   labelType={'horizontal'}
                   label={'Status'}
               >
                   <SelectInput
                       mode="multiple"
                       name={TRADE_PROPOSAL_FILTER_FIELDS.tradeProposalStatuses}
                       options={mapTradeProposalStatusToOptions(TradeProposalStatusNames)}
                       placeholder={'All'}
                   />
               </FormItem>
            }
            <FormItem
                className={'filter-form-item'}
                name={TRADE_PROPOSAL_FILTER_FIELDS.period}
                labelType={'horizontal'}
                label={'Period'}
            >
                <CalendarRangeInput name={TRADE_PROPOSAL_FILTER_FIELDS.period}/>
            </FormItem>
            {/* TODO: uncomment when filtering by Procedure is needed
            {isPublic &&
                <FormItem
                    className={'filter-form-item'}
                    name={TRADE_PROPOSAL_FILTER_FIELDS.procedures}
                    labelType={'horizontal'}
                    label={'Procedure'}
                >
                    <SelectInput
                        mode="multiple"
                        name={TRADE_PROPOSAL_FILTER_FIELDS.procedures}
                        lazyLoad={formik.initialValues.procedures && formik.initialValues.procedures.length > 0}
                        service={loadProcedures}
                        mapItemToOptions={mapListItemToOptions}
                        placeholder={'All'}
                    />
                </FormItem>
            }
            */}
        </Col>;
    }

    return <Form>
        <Row gutter={64}>
            {renderFilterFields()}
            {renderSliders()}
        </Row>
        <SubmitButton className='filter-button' disabled={disableButtons}>
            Filter
        </SubmitButton>
        <Button type="default" onClick={handleFilterReset} disabled={disableButtons}>
            Reset filters
        </Button>
    </Form>;

};

import React, { ChangeEvent } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { Moment } from 'moment';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { ArrowRightOutlined } from '@ant-design/icons/lib';
import { RangeValue } from 'rc-picker/lib/interface';
import { FormikFieldProps } from '../common/field-props';
import { gasDayDateFormat } from '../../../config/constants';

import './calendar-range-input.less';

export type CalendarInputProps = FormikFieldProps & RangePickerProps<Moment>;

export interface CalendarRangeInputProps {
    customFormat?: string;
}

type Props = CalendarInputProps & CalendarRangeInputProps;

const CalendarRangeInput: React.FC<Props> = props => {
    const {
        name,
        disabled,
        disableOnSubmit,
        onChange,
        customFormat,
        ...restProps
    } = props;

    const formik = useFormikContext();

    const format = customFormat
        ? customFormat
        : gasDayDateFormat;

    const handleChange = (moment: RangeValue<Moment> | null) => {
        setTimeout(() => {
            formik.setFieldValue(name, moment);
        }, 0);
        formik.setFieldTouched(name, true, false);
        onChange && onChange(moment, [format, format]);
    };

    const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
        formik.handleBlur(event);
    };

    return (
        <Field name={name}>
            {({ field: { value }, form: { isSubmitting } }: FieldProps) => (
                <DatePicker.RangePicker
                    {...restProps}
                    name={name}
                    value={value}
                    onCalendarChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disabled || (disableOnSubmit && isSubmitting)}
                    format={format}
                    placeholder={[format, format]}
                    separator={<ArrowRightOutlined/>}
                />
            )}
        </Field>
    );
};

export { CalendarRangeInput };

import React from 'react';
import { SubmitButton } from '../../../../../components/button/submit-button';
import { Form } from '../../../../../components/inputs/common/form';
import { FormItem } from '../../../../../components/inputs/common/form-item';
import { Row, Col } from 'antd';
import { COMPANY_INFORMATION_FORM_FIELDS } from './company-information-schema';
import { FormSectionText } from '../../../../../components/text/form-section-text';
import { TextInput } from '../../../../../components/inputs/text-input/text-input';

import './../company-registration-form.less';

export const CompanyInformationForm: React.FC = () => {
    function renderCompanyDetails(): React.ReactNode {
        return <div className="form-section">

            <FormSectionText text={'Company details'}/>

            <FormItem label={'Company name'} name={COMPANY_INFORMATION_FORM_FIELDS.companyName} labelType="horizontal">
                <TextInput name={COMPANY_INFORMATION_FORM_FIELDS.companyName}/>
            </FormItem>
            <FormItem label={'Company code'} name={COMPANY_INFORMATION_FORM_FIELDS.companyCode} labelType="horizontal">
                <TextInput name={COMPANY_INFORMATION_FORM_FIELDS.companyCode}/>
            </FormItem>
            <FormItem label={'EIC code'} name={COMPANY_INFORMATION_FORM_FIELDS.eicCode} labelType="horizontal">
                <TextInput name={COMPANY_INFORMATION_FORM_FIELDS.eicCode}/>
            </FormItem>
            <FormItem label={'ACER code'} name={COMPANY_INFORMATION_FORM_FIELDS.acerCode} labelType="horizontal">
                <TextInput name={COMPANY_INFORMATION_FORM_FIELDS.acerCode}/>
            </FormItem>
            <FormItem label={'Company VAT code'} name={COMPANY_INFORMATION_FORM_FIELDS.companyVatCode} labelType="horizontal">
                <TextInput name={COMPANY_INFORMATION_FORM_FIELDS.companyVatCode}/>
            </FormItem>
        </div>;
    }

    function renderCompanyAddress(): React.ReactNode {
        return <div className="form-section">
            <FormSectionText text={'Company address'}/>

            <FormItem label={'Street, number'} name={COMPANY_INFORMATION_FORM_FIELDS.streetNumber} labelType="horizontal">
                <TextInput name={COMPANY_INFORMATION_FORM_FIELDS.streetNumber}/>
            </FormItem>
            <FormItem label={'Postal code'} name={COMPANY_INFORMATION_FORM_FIELDS.postalCode} labelType="horizontal">
                <TextInput name={COMPANY_INFORMATION_FORM_FIELDS.postalCode}/>
            </FormItem>
            <FormItem label={'City'} name={COMPANY_INFORMATION_FORM_FIELDS.city} labelType="horizontal">
                <TextInput name={COMPANY_INFORMATION_FORM_FIELDS.city}/>
            </FormItem>
            <FormItem label={'Country'} name={COMPANY_INFORMATION_FORM_FIELDS.country} labelType="horizontal">
                <TextInput name={COMPANY_INFORMATION_FORM_FIELDS.country}/>
            </FormItem>
        </div>;
    }

    function renderSettlementInformation(): React.ReactNode {
        return <div className="form-section">

            <FormSectionText text={'Settlement information'}/>

            <FormItem label={'Bank name'} name={COMPANY_INFORMATION_FORM_FIELDS.bankName} labelType="horizontal">
                <TextInput name={COMPANY_INFORMATION_FORM_FIELDS.bankName}/>
            </FormItem>
            <FormItem label={'IBAN'} name={COMPANY_INFORMATION_FORM_FIELDS.iban} labelType="horizontal">
                <TextInput name={COMPANY_INFORMATION_FORM_FIELDS.iban}/>
            </FormItem>
            <FormItem label={'Bank code'} name={COMPANY_INFORMATION_FORM_FIELDS.bankCode} labelType="horizontal">
                <TextInput name={COMPANY_INFORMATION_FORM_FIELDS.bankCode}/>
            </FormItem>
            <FormItem label={'Invoicing email'} name={COMPANY_INFORMATION_FORM_FIELDS.invoicingEmail} labelType="horizontal">
                <TextInput name={COMPANY_INFORMATION_FORM_FIELDS.invoicingEmail}/>
            </FormItem>
        </div>;
    }

    return (
        <Row justify="center">
            <Col xs={22}>
                <Form className="company-registration-form">
                    {renderCompanyDetails()}
                    {renderCompanyAddress()}
                    {renderSettlementInformation()}
                    <Row justify="end">
                        <Col>
                            <SubmitButton> Save and Continue </SubmitButton>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};

import React, { useEffect, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Col, Row, Select, Spin, Typography, Modal } from 'antd';
import { UnlockOutlined, LockFilled, LoadingOutlined } from '@ant-design/icons';
import { WarningOutlined } from '@ant-design/icons/lib';

import { Form } from '../../../../components/inputs/common/form';
import { AccessRight } from '../../../../config/constants';
import { SubmitButton } from '../../../../components/button/submit-button';
import { FormItem } from '../../../../components/inputs/common/form-item';
import { RadioButtonsInput, ToggleButtonOption } from '../../../../components/inputs/radio-input/radio-buttons';
import { NotificationType, notify } from '../../../../service/notification-service';
import { USER_REGISTRATION_FORM_FIELDS } from './user-registration-schema';
import { UserRegistrationFormModel } from './user-registration-form-model';
import { userService } from '../../../../api/user-service';
import { UserListItemResponse } from '../../../../api/model/user-list-item-response';
import { CompanyListItem } from '../../../../api/model/company-list-item';
import { TextInput } from '../../../../components/inputs/text-input/text-input';
import { UnsavedChangesPrompt } from '../../../../components/prompt/unsaved-changes-prompt';
import { UserCompanyModel } from './user-company-model';

import './user-registration-form.less';

const { Option } = Select;

export interface UserRegistrationFormProps {
    allCompanies: CompanyListItem[];
    selectedUser: number | '' | undefined;
    onSelectUser: (userId: number | 'NEW_USER') => void;
}

export const UserRegistrationForm = (props: UserRegistrationFormProps) => {

    const {
        allCompanies,
        selectedUser,
        onSelectUser
    } = props;

    const roleOptions: ToggleButtonOption[] = [
        {
            label: 'Full control user',
            value: AccessRight.FULL_CONTROL_USER
        },
        {
            label: 'Read only user',
            value: AccessRight.READ_ONLY_USER
        }
    ];

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [companySelect, setCompanySelect] = useState<any>(null);
    const [allUsers, setAllUsers] = useState<UserListItemResponse[]>([]);

    const formik = useFormikContext<UserRegistrationFormModel>();

    useEffect(() => {
        setIsLoading(true);
        userService.getAllUsers()
            .then((users) => {
                setAllUsers(users);
                setIsLoading(false);
            })
            .catch((error: any) => {
                notify({
                    type: NotificationType.ERROR,
                    message: error.toString()
                });
                setIsLoading(false);
            });
    }, []);

    function renderUserSelect(): React.ReactNode {
        return <Row justify="end" className="user-type" gutter={64}>
            <Col xs={10} className="labeled-input-col">
                <Typography.Text className="labeled-input-text">
                    Select user
                </Typography.Text>
            </Col>
            <Col xs={14}>
                <Select
                    showSearch={true}
                    className="user-type-select"
                    defaultValue={selectedUser ? selectedUser : 'NEW_USER'}
                    onSelect={(value) => onSelectUser(value)}
                    optionFilterProp="children"
                >
                    <Select.Option value={'NEW_USER'}>
                        New user
                    </Select.Option>
                    {allUsers.map(user => {
                        return <Select.Option value={user.id} key={user.id}>
                            {user.email}
                        </Select.Option>;
                    })}
                </Select>
            </Col>
        </Row>;
    }

    const renderUserFields = () => {
        return <>
            <FormItem name={USER_REGISTRATION_FORM_FIELDS.email} label={'Email'} labelType="horizontal">
                <TextInput name={USER_REGISTRATION_FORM_FIELDS.email} disabled={isLoading}/>
            </FormItem>
            <FormItem name={USER_REGISTRATION_FORM_FIELDS.firstName} label={'First name'} labelType="horizontal">
                <TextInput name={USER_REGISTRATION_FORM_FIELDS.firstName} disabled={isLoading}/>
            </FormItem>
            <FormItem name={USER_REGISTRATION_FORM_FIELDS.lastName} label={'Last name'} labelType="horizontal">
                <TextInput name={USER_REGISTRATION_FORM_FIELDS.lastName} disabled={isLoading}/>
            </FormItem>
            <FormItem name={USER_REGISTRATION_FORM_FIELDS.position} label={'Position'} labelType="horizontal">
                <TextInput name={USER_REGISTRATION_FORM_FIELDS.position} disabled={isLoading}/>
            </FormItem>
            <FormItem name={USER_REGISTRATION_FORM_FIELDS.phone} label={'Phone'} labelType="horizontal">
                <TextInput name={USER_REGISTRATION_FORM_FIELDS.phone} disabled={isLoading}/>
            </FormItem>
        </>;
    };

    const renderUserAccessField = (company: UserCompanyModel, index: number) => {
        const userName = formik.values.firstName && formik.values.lastName
            ? formik.values.firstName + ' ' + formik.values.lastName
            : formik.values.firstName ? formik.values.firstName : formik.values.lastName;

        return <div className="is-disabled-field"
            onClick={() =>{
                Modal.confirm({
                    className: 'disable-users-modal',
                    icon: <WarningOutlined/>,
                    content: `Are you sure you want to ${company.userDisabled ? 'unlock' : 'lock'}
                                ${userName ? userName : 'this user account'}?`,
                    centered: true,
                    cancelText: 'Cancel',
                    okText: 'Confirm',
                    maskClosable: true,
                    onOk: () => formik.setFieldValue(
                        `${USER_REGISTRATION_FORM_FIELDS.companies}.${index}.userDisabled`,
                        !company.userDisabled
                    )
                });

            }}>
            {company.userDisabled
                ? <React.Fragment><LockFilled/> Enable access</React.Fragment>
                : <React.Fragment><UnlockOutlined/> Disable access</React.Fragment>}
        </div>;
    };

    const renderCompaniesList = () => {

        return formik.values.companies && formik.values.companies.length > 0 ?
            formik.values.companies.map((company, index) => (
                company &&
                    <div key={index} className="company-field">
                        <Row>
                            <Col className="company-name">
                                {company.companyName}
                            </Col>
                            <Col>
                                {company.companyDisabled ? 'Company is disabled' : renderUserAccessField(company, index)}
                            </Col>
                        </Row>
                        <RadioButtonsInput
                            name={`${USER_REGISTRATION_FORM_FIELDS.companies}.${index}.role`}
                            options={roleOptions}
                            disabled={company.companyDisabled}
                        />
                    </div>
            ))
            : null;
    };

    const handleSelect = (value: number, companyName: string) => {
        formik.setFieldValue(
            USER_REGISTRATION_FORM_FIELDS.companies,
            formik.values.companies &&
            [...formik.values.companies,
                {
                    companyId: value,
                    companyName,
                    role: AccessRight.READ_ONLY_USER,
                    disabled: false
                }]
        );
    };

    const handleDeselect = (value: number) => {
        formik.setFieldValue(
            USER_REGISTRATION_FORM_FIELDS.companies,
            formik.values.companies?.filter((company) => company.companyId !== value)
        );
    };

    const renderCompanies = () => {
        const selectedCompanies = formik.values.companies && formik.values.companies
            .filter(c => !c.companyDisabled).map(c => c.companyId ? c.companyId : '');

        return <FieldArray name={USER_REGISTRATION_FORM_FIELDS.companies}
            render={() => (
                <div>
                    {renderCompaniesList()}
                    <FormItem name={USER_REGISTRATION_FORM_FIELDS.companies}>
                        <Select
                            disabled={isLoading}
                            placeholder="Select company from the list"
                            mode="multiple"
                            ref={(select) => setCompanySelect(select)}
                            onChange={() => companySelect.blur()}
                            onSelect={(value, option) =>
                                handleSelect(parseInt(value.toString()), option.children.toString())
                            }
                            onDeselect={(value) => handleDeselect(Number(value))}
                            value={selectedCompanies}
                        >
                            {allCompanies
                                .map((company, i) =>
                                    <Option key={i} value={company.id}>{company.name}</Option>)
                            }
                        </Select>
                    </FormItem>
                </div>
            )}/>;
    };

    return <Spin spinning={isLoading} indicator={<LoadingOutlined/>} size="large">
        <Form className="user-registration-form">
            {allUsers.length > 0 && renderUserSelect()}
            {renderUserFields()}
            <Row className="companies" gutter={64}>
                <Col xs={10} className="label">Company (-ies)</Col>
                <Col xs={14}>
                    {renderCompanies()}
                </Col>
            </Row>
            <Row justify="end">
                <Col>
                    <SubmitButton disabled={formik.isSubmitting}>Save and submit</SubmitButton>
                </Col>
            </Row>
            <UnsavedChangesPrompt
                when={formik.values !== formik.initialValues && !formik.isSubmitting}
                message={'You have unsaved changes, are you sure you want to leave this page?'}
            />
        </Form>
    </Spin>;
};

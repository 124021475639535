import React, { useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { useSelector } from 'react-redux';

import { RootState } from '../../../redux/store';
import { List } from '../../../components/list/list';
import { Role } from '../../../components/auth/private-route';
import { UserDetailsForm } from './user-details/user-details-form';
import { ChangePasswordForm } from './change-password/change-password-form';
import { TradersListForm } from './traders-list/traders-list-form';

const listOptions = (setItem: (item: number) => void) => [
    {
        label: 'User details',
        action: () => setItem(0),
        roles: [Role.SYSTEM_USER, Role.SYSTEM_ADMIN]
    },
    {
        label: 'Change password',
        action: () => setItem(1),
        roles: [Role.SYSTEM_USER, Role.SYSTEM_ADMIN]
    },
    {
        label: 'Traders list',
        action: () => setItem(2),
        roles: [Role.SYSTEM_USER]
    }
];

export const UserSettingsPage = () => {

    const authentication = useSelector((state: RootState) => state.authentication);
    const [selectedItem, setSelectedItem] = useState<number>(0);
    const options = listOptions(setSelectedItem)
        .filter(option => authentication.role && option.roles.includes(authentication.role));

    function renderSelectedTab(): React.ReactNode {
        switch (selectedItem) {
            case 0:
                return <UserDetailsForm/>;
            case 1:
                return <ChangePasswordForm/>;
            case 2:
                return <TradersListForm/>;
        }
    }

    return (
        <Row justify="center">
            <Col xs={20}>
                <Row justify="space-between" >
                    <Col xs={12} sm={12}>
                        <Typography.Title level={4}>
                            User Profile
                        </Typography.Title>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={6}>
                        <List options={options} hideScroll={true}/>
                    </Col>
                    <Col xs={14}>
                        {renderSelectedTab()}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { FormikHelpers } from 'formik';
import { TwoFactorLoginForm } from './form/two-factor-login-form';
import { TwoFactorLoginFormModel } from './form/two-factor-login-form-model';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import { navigationService } from '../../../service/navigation-service';
import { authenticationService } from '../../../api/authentication-service';
import { NotificationType, notify } from '../../../service/notification-service';
import { AxiosError } from 'axios';
import { ErrorType, getErrorMessage } from '../../../utils/error-utils';
import { userLogin } from '../../../redux/authentication/actions';
import { Role } from '../../../components/auth/private-route';
import { useDispatch } from 'react-redux';

import './form/two-factor-login-form.less';

interface TwoFactorLoginPageProps {
    token: string;
}

const TwoFactorLoginPageComponent: React.FC<RouteComponentProps<TwoFactorLoginPageProps>> =
    (props: RouteComponentProps<TwoFactorLoginPageProps>) => {

        const initialValues: TwoFactorLoginFormModel = {
            code: ''
        };

        const {
            match: {
                params: {
                    token
                }
            }
        } = props;

        const [expired, setExpired] = useState<boolean>(false);
        const dispatch = useDispatch();

        useEffect(() => {
            if (token) {
                const decodedToken: any = jwt.decode(token, { complete: true });

                if (decodedToken && moment.unix(decodedToken.payload.exp).isBefore(moment())) {
                    setExpired(true);
                }
            }
        },[token]);

        const handleSubmit = (values: TwoFactorLoginFormModel, formikHelpers: FormikHelpers<TwoFactorLoginFormModel>) => {
            authenticationService.confirmLogin({
                ...values,
                token: token
            }).then((response: any) => {
                dispatch(userLogin({
                    isLoggedIn: true,
                    role: response.admin ? Role.SYSTEM_ADMIN : Role.SYSTEM_USER,
                    firstName: response.firstName,
                    lastName: response.lastName,
                    companyRoleMap: response.companyRoleMap ?? undefined,
                    selectedCompany: response.companyRoleMap ? response.companyRoleMap[0] : undefined
                }));

                navigationService.goToTradeProposalsPath();
            }).catch((error: AxiosError) => {
                if (getErrorMessage(error).errorType === ErrorType.LOGIN_ATTEMPTS_EXCEEDED_ERROR) {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Too many login attempts',
                    });
                    navigationService.goToForgotPasswordPage();
                } else {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Code incorrect',
                    });
                }
            });
            formikHelpers.setSubmitting(false);
        };

        return (
            <Row justify="center" className="login-page">
                <Col xs={20} sm={8}>
                    {expired
                        ? <>
                            <Typography.Text>
                                {'Code expired. Please try logging in again '}
                                <Button type="link" className="try-again-text" onClick={() => navigationService.goToLoginPage()}>
                                    here
                                </Button>
                            </Typography.Text>
                        </>
                        : <>
                            <Typography.Title level={4}>Confirm login</Typography.Title>
                            <TwoFactorLoginForm
                                initialValues={initialValues}
                                handleSubmit={handleSubmit}
                            />
                        </>
                    }
                </Col>
            </Row>
        );
    };

const twoFactorLoginPage = withRouter(TwoFactorLoginPageComponent);

export { twoFactorLoginPage };

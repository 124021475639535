import { Role } from '../../auth/private-route';
import { navigationService } from '../../../service/navigation-service';

export interface Link {
    label: string;
    to?: () => void;
    roles: Role[];
    subMenu?: Link[];
    path?: string;
}

export const linkList: Link[] = [
    {
        label: 'Trade proposals',
        roles: [],
        to: navigationService.goToTradeProposalsPath,
        path: navigationService.HOME_PATH
    },
    {
        label: 'Trading results',
        roles: [],
        to: navigationService.goToTradingResultsPath,
        path: navigationService.TRADING_RESULTS_PATH
    },
    {
        label: 'Reports',
        subMenu: [
            {
                label: 'Trade proposals',
                roles: [],
                to: navigationService.goToTradeProposalReportsPath,
                path: navigationService.TRADE_PROPOSAL_REPORTS_PATH
            },
            {
                label: 'Responses',
                roles: [],
                to: navigationService.goToResponseReportsPath,
                path: navigationService.RESPONSE_REPORTS_PATH
            },
            {
                label: 'Trading results',
                roles: [],
                to: navigationService.goToTradingResultReportsPath,
                path: navigationService.TRADE_RESULT_REPORTS_PATH
            }
        ],
        roles: [Role.SYSTEM_ADMIN],
        path: navigationService.REPORTS_PATH
    },
    {
        label: 'My proposals',
        roles: [Role.SYSTEM_USER],
        to: navigationService.goToMyTradeProposalsPath,
        path: navigationService.MY_TRADE_PROPOSALS_PATH
    },
    {
        label: 'My responses',
        roles: [Role.SYSTEM_USER],
        to: navigationService.goToMyResponsesPath,
        path: navigationService.MY_RESPONSES_PATH
    },
    {
        label: 'My trading results',
        roles: [Role.SYSTEM_USER],
        to: navigationService.goToMyTradingResultsPath,
        path: navigationService.MY_TRADING_RESULTS_PATH
    },
    {
        label: 'Logs',
        roles: [Role.SYSTEM_ADMIN],
        to: navigationService.goToLogsPath,
        path: navigationService.LOGS_PATH
    },
    {
        label: 'Users management',
        roles: [Role.SYSTEM_ADMIN],
        to: navigationService.goToUsersManagementPath,
        path: navigationService.USERS_MANAGEMENT_PATH
    },
    {
        label: 'Settings',
        roles: [Role.SYSTEM_ADMIN],
        to: navigationService.goToSettingsPage,
        path: navigationService.SETTINGS_PATH
    }
];

import React from 'react';
import { Formik, FormikProps } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { UnsavedChangesPrompt } from '../prompt/unsaved-changes-prompt';
import _ from 'lodash';

export interface WizardStepProps {
    onContinue?: any;
    onSubmit?: any;
    form: React.ReactNode;
    initialValues?: any;
    validationSchema?: any;
    title: string;
    nextStep?: any;
}

export const WizardStep: React.FC<WizardStepProps> = (props: WizardStepProps) => {

    const {
        initialValues,
        onSubmit,
        form,
        nextStep,
        validationSchema
    } = props;

    function handleSubmit(values: any, formikHelpers: FormikHelpers<any>) {
        formikHelpers.setSubmitting(true);
        onSubmit(values)
            .then(() => {
                formikHelpers.setSubmitting(false);
                nextStep();
            })
            .catch(() => formikHelpers.setSubmitting(false));
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({ values, isSubmitting }: FormikProps<any>) =>
                <>
                    {form}
                    <UnsavedChangesPrompt
                        when={!_.isEqual(values, initialValues) && !isSubmitting}
                        message={'You have unsaved changes, are you sure you want to leave this page?'}
                    />
                </>
            }
        </Formik>
    );
};

import { AuthenticationActions, AuthenticationActionTypes, AuthenticationState } from './types';

const initialState: AuthenticationState = {
    isLoggedIn: false,
    role: undefined,
    firstName: undefined,
    lastName: undefined,
    selectedCompany: undefined,
    companyRoleMap: undefined
};

export function authenticationReducer(state = initialState, action: AuthenticationActionTypes): AuthenticationState {
    switch (action.type) {
        case AuthenticationActions.LOGIN:
            return action.payload;

        case AuthenticationActions.LOGOUT:
            return {
                isLoggedIn: false,
                role: undefined,
                firstName: undefined,
                lastName: undefined,
                selectedCompany: undefined,
                companyRoleMap: undefined
            };

        case AuthenticationActions.UPDATE_SELECTED_COMPANY:
            return {
                ...state,
                ...action.payload
            };

        case AuthenticationActions.UPDATE_COMPANIES:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
}

import React from 'react';
import { Formik, FormikHelpers } from 'formik';

import { Form } from '../../../../components/inputs/common/form';
import { FormItem } from '../../../../components/inputs/common/form-item';
import { SubmitButton } from '../../../../components/button/submit-button';
import { ResetPasswordFormModel } from './reset-password-form-model';
import { PasswordInput } from '../../../../components/inputs/text-input/password-input';
import { RESET_PASSWORD_FORM_FIELDS, ResetPasswordSchema } from './reset-password-schema';
import { Col, Row } from 'antd';

import './reset-password-form.less';

interface OwnProps {
    initialValues: ResetPasswordFormModel;
    handleSubmit: (
        values: ResetPasswordFormModel,
        actions: FormikHelpers<ResetPasswordFormModel>
    ) => void;
}

type Props = OwnProps;

const ResetPasswordForm: React.FC<Props> = (props: Props) => {
    const {
        handleSubmit,
        initialValues
    } = props;

    const renderForm = (): React.ReactNode => {
        return (
            <Form>
                <FormItem label="New password" name={RESET_PASSWORD_FORM_FIELDS.password}>
                    <PasswordInput
                        name={RESET_PASSWORD_FORM_FIELDS.password}
                    />
                </FormItem>
                <FormItem label="Repeat password" name={RESET_PASSWORD_FORM_FIELDS.repeatPassword}>
                    <PasswordInput
                        name={RESET_PASSWORD_FORM_FIELDS.repeatPassword}
                    />
                </FormItem>
                <Row justify="center">
                    <Col>
                        <SubmitButton>
                            Reset password
                        </SubmitButton>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={ResetPasswordSchema}
        >
            {renderForm}
        </Formik>
    );
};

export { ResetPasswordForm };

import React, { useEffect, useState } from 'react';
import { Spin, Typography } from 'antd';
import { authenticationService } from '../../api/authentication-service';
import { setAccessToken } from '../../api/access-token';
import { navigationService } from '../../service/navigation-service';
import { RouteProps, Route } from 'react-router-dom';
import { LoginResponse } from '../../api/model/login-response';
import { LoadingOutlined } from '@ant-design/icons';
import { AuthenticationState } from '../../redux/authentication/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { userLogin, userLogout } from '../../redux/authentication/actions';
import { AccessRight } from '../../config/constants';

import './private-route.less';

export enum Role {
    SYSTEM_ADMIN = 'SYSTEM_ADMIN',
    SYSTEM_USER = 'SYSTEM_USER'
}

export interface PrivateRouteProps extends RouteProps {
    hasRole: Role[];
    hasAccess?: AccessRight[];
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ hasRole, hasAccess, ...rest }: PrivateRouteProps) => {

    const [loading, setLoading] = useState<boolean>(true);

    const dispatch = useDispatch();
    const authentication: AuthenticationState = useSelector((state: RootState) => state.authentication);

    useEffect(() => {
        if (!authentication) {
            authenticationService.refreshToken()
                .then((response: LoginResponse) => {
                    const savedSelectedCompany = localStorage.getItem('selectedCompanyId');

                    setLoading(false);
                    dispatch(userLogin({
                        isLoggedIn: true,
                        role: response.isAdmin ? Role.SYSTEM_ADMIN : Role.SYSTEM_USER,
                        firstName: response.firstName,
                        lastName: response.lastName,
                        companyRoleMap: response.companyRoleMap,
                        selectedCompany: response.companyRoleMap ?
                            savedSelectedCompany !== null && savedSelectedCompany !== ''
                                ? response.companyRoleMap[response.companyRoleMap
                                    .findIndex(
                                        c => c.id.toString() === localStorage.getItem('selectedCompanyId')
                                    )
                                ]
                                : response.companyRoleMap[0]
                            : undefined
                    }));
                    setAccessToken(response.accessToken);
                })
                .catch(() => {
                    dispatch(userLogout());
                    localStorage.setItem('selectedCompanyId', '');
                    navigationService.goToLoginPage();
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [dispatch, authentication]);

    function checkRoles(): boolean {
        if(!authentication.role) {
            return false;
        }

        return hasRole.includes(authentication.role);
    }

    function checkAccessRights(): boolean {
        return !hasAccess || (
            authentication.selectedCompany !== undefined
            && hasAccess.includes(authentication.selectedCompany.role)
        );
    }

    return <Spin spinning={loading} className="app-loader" indicator={<LoadingOutlined/>} size="large">
        {checkRoles() && checkAccessRights()
            ? <Route {...rest}/>
            : !loading && <Typography.Title>Error 403: You are not authorized to view this page</Typography.Title>
        }
    </Spin>;
};

import React, { ChangeEvent } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { InputNumber, Select, Row, Col } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';

import { FormikFieldProps } from '../common/field-props';

interface OwnProps {
    durationSelectFieldName: string;
}

export type NumberInputProps = FormikFieldProps & InputNumberProps & OwnProps;

type Props = NumberInputProps;

export enum DurationType {
    DAYS = 'DAYS',
    WEEKS = 'WEEKS',
    MONTHS = 'MONTHS'
}

export function getDurationType(d: DurationType) {
    switch (d) {
        case DurationType.DAYS:
            return 'days';
        case DurationType.MONTHS:
            return 'months';
        case DurationType.WEEKS:
            return 'weeks';
        default:
            return 'weeks';
    }
}

const DurationInput: React.FC<Props> = props => {
    const {
        name,
        disabled,
        disableOnSubmit,
        className,
        durationSelectFieldName,
        ...restProps
    } = props;

    const formik = useFormikContext();

    const handleChange = (value: number | string | undefined) => {
        formik.setFieldValue(name, value);
    };

    const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
        formik.handleBlur(event);
    };

    const handleChangeDurationType = (value: number | string | undefined) => {
        formik.setFieldValue(durationSelectFieldName, value);
    };

    const handleBlurDurationType = (event: ChangeEvent<HTMLElement>) => {
        formik.handleBlur(event);
    };

    return (
        <>
            <Row gutter={12}>
                <Col xs={10} sm={10} md={5} lg={4}>
                    <Field name={name}>
                        {({ field: { value }, form: { isSubmitting } }: FieldProps) => (
                            <InputNumber
                                {...restProps}
                                name={name}
                                value={value}
                                className={className}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={disabled || (disableOnSubmit && isSubmitting)}
                            />
                        )}
                    </Field>
                </Col>
                <Col xs={14} sm={14} md={8} lg={8}>
                    <Field name={durationSelectFieldName}>
                        {({ field: { value }, form: { isSubmitting } }: FieldProps) => (
                            <Select
                                value={value}
                                disabled={disabled || (disableOnSubmit && isSubmitting)}
                                defaultValue={DurationType.MONTHS}
                                onSelect={handleChangeDurationType}
                                onBlur={handleBlurDurationType}
                            >
                                <Select.Option value={DurationType.DAYS}>
                                    Days
                                </Select.Option>
                                <Select.Option value={DurationType.WEEKS}>
                                    Weeks
                                </Select.Option>
                                <Select.Option value={DurationType.MONTHS}>
                                    Months
                                </Select.Option>
                            </Select>
                        )}
                    </Field>
                </Col>
            </Row>
        </>
    );
};

export { DurationInput };

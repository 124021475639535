import React from 'react';
import { Typography } from 'antd';

const PageNotFound: React.FC = () => {

    return (
        <div>
            <Typography.Title>Error 404 - The page does not exist.</Typography.Title>
        </div>
    );
};
export { PageNotFound };



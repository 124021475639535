import React from 'react';
import { Col, Row, Skeleton, Tag, Typography } from 'antd';
import moment from 'moment';

import { LabeledText } from '../../../../components/text/labeled-text';
import { ProposalType } from '../../trade-proposals/new-trade-proposal/form/new-trade-proposal-schema';
import { TradingResultReportDetails } from '../../../../api/model/trading-result-report-details';
import { capacityUnit, dateTimeFormat, priceUnit } from '../../../../config/constants';

export interface TradeProposalReportInformationProps {
    loading: boolean;
    tradingResult?: TradingResultReportDetails;
}

export const TradingResultReportInformation = (props: TradeProposalReportInformationProps) => {
    const {
        tradingResult,
        loading,
    } = props;

    return <Skeleton
        loading={loading}
        active={loading}
        paragraph={{ rows: 10 }}
    >
        <Row className={'proposal-info-wrapper'}>
            <Tag
                className={`proposal-type-tag ${tradingResult?.proposalType === ProposalType.BUY
                    ? 'request'
                    : 'offer'}`
                }
            >
                {tradingResult?.proposalType === ProposalType.BUY ? 'Request' : 'Offer'}
            </Tag>
            <Typography.Text className={'network-point'}>
                {tradingResult?.networkPointName}
            </Typography.Text>
        </Row>
        <Row className={'proposal-details'}>
            <Typography.Title level={4} className={'details-header'}>
                Trading result details
            </Typography.Title>
            <Col xs={24}>
                <LabeledText
                    value={tradingResult?.id.toString()}
                    label={'Trading result ID'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={tradingResult?.buyer}
                    label={'Buyer company name'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={tradingResult?.seller}
                    label={'Seller company name'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={`${tradingResult?.totalPrice} ${priceUnit}`}
                    label={'Price'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={`${tradingResult?.capacity} ${capacityUnit}`}
                    label={'Capacity amount'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={`${moment(tradingResult?.dateFrom).format(dateTimeFormat)}
                                - ${moment(tradingResult?.dateTo).format(dateTimeFormat)}`}
                    label={'Period'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={moment(tradingResult?.createdAt).format(dateTimeFormat)}
                    label={'Creation time'}
                    labelAlign={'right'}
                />
            </Col>
        </Row>
        <Row className={'proposal-details'}>
            <Typography.Title level={4} className={'details-header'}>
                Trading conditions
            </Typography.Title>
            <Col xs={24}>
                <LabeledText
                    value={tradingResult?.proposalType === ProposalType.SELL
                        ? 'Proposal to sell' : 'Proposal to buy'}
                    label={'Proposal type'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={tradingResult?.procedure ?? 'FCFS'}
                    label={'Trading procedure'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={tradingResult?.capacityCategory}
                    label={'Capacity category'}
                    labelAlign={'right'}
                />
            </Col>
        </Row>
    </Skeleton>;
};

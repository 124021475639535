import React, { useEffect, useState } from 'react';
import { Col, Row, Typography, Button } from 'antd';
import { useFormikContext } from 'formik';

import { Form } from '../../../../../components/inputs/common/form';
import { FormItem } from '../../../../../components/inputs/common/form-item';
import {
    CONTACT_PERSON_FIELDS,
    ContactPersonModel
} from './contact-person-schema';
import { SubmitButton } from '../../../../../components/button/submit-button';
import { ContactPersonCard } from '../../../../../components/card/contact-person-card';
import { TextInput } from '../../../../../components/inputs/text-input/text-input';
import { TextAreaInput } from '../../../../../components/inputs/text-input/text-area-input';

import './../company-registration-form.less';

export const ContactPersonForm: React.FC = () => {

    const [filledCount, setFilledCount] = useState<number>(1);
    const [filledCards, setFilledCards] = useState<any>([]);

    const formik = useFormikContext<ContactPersonModel[]>();

    useEffect(() => {
        setFilledCards(formik.initialValues);
        setFilledCount(formik.initialValues.length);
    },[formik.initialValues]);

    function handleDelete(formNumber: number) {
        setFilledCount(filledCount - 1);
        formik.setValues(formik.values.filter(value => formik.values[formNumber] !== value));
        setFilledCards(filledCards.filter((card: any) => filledCards[formNumber] !== card));
    }

    function isWhitespaceOnly(str: string) {
        return /\S/.test(str);
    }

    function handleAdditionalClick() {
        if(formik.values[filledCount] !== undefined &&
            Object.keys(formik.values[filledCount]).length > 0 &&
            Object.values(formik.values[filledCount]).filter(str => {
                return /\S/.test(str);
            }).length > 0
        ) {
            setFilledCount(filledCount + 1);
            setFilledCards(formik.values.map(value =>
                Object.fromEntries(Object.entries(value).filter(([k, v]) => isWhitespaceOnly(v)))
            ));
        }
    }

    function handleEdit(filledCardToEdit: any) {
        formik.setValues([...filledCards.filter((value: any) => value !== filledCardToEdit), filledCardToEdit]);
        setFilledCards(filledCards.filter((card: any) => filledCardToEdit !== card));
        setFilledCount(filledCount - 1);
    }

    function renderFormFields(): React.ReactNode {
        return <div key={filledCount}>
            <FormItem name={`${filledCount}.${CONTACT_PERSON_FIELDS.firstName}`} label={'First name'} labelType="horizontal">
                <TextInput name={`${filledCount}.${CONTACT_PERSON_FIELDS.firstName}`}/>
            </FormItem>
            <FormItem name={`${filledCount}.${CONTACT_PERSON_FIELDS.lastName}`} label={'Last name'} labelType="horizontal">
                <TextInput name={`${filledCount}.${CONTACT_PERSON_FIELDS.lastName}`}/>
            </FormItem>
            <FormItem name={`${filledCount}.${CONTACT_PERSON_FIELDS.position}`} label={'Position'} labelType="horizontal">
                <TextInput name={`${filledCount}.${CONTACT_PERSON_FIELDS.position}`}/>
            </FormItem>
            <FormItem name={`${filledCount}.${CONTACT_PERSON_FIELDS.email}`} label={'Email'} labelType="horizontal">
                <TextInput name={`${filledCount}.${CONTACT_PERSON_FIELDS.email}`}/>
            </FormItem>
            <FormItem name={`${filledCount}.${CONTACT_PERSON_FIELDS.phone}`} label={'Phone'} labelType="horizontal">
                <TextInput name={`${filledCount}.${CONTACT_PERSON_FIELDS.phone}`}/>
            </FormItem>
            <FormItem name={`${filledCount}.${CONTACT_PERSON_FIELDS.comment}`} label={'Comment'} labelType="horizontal">
                <TextAreaInput name={`${filledCount}.${CONTACT_PERSON_FIELDS.comment}`}/>
            </FormItem>
        </div>;
    }

    function renderContactCards() {
        if (!Array.isArray(filledCards)) return;

        return filledCards.map(filledCard =>
            <div key={filledCards.indexOf(filledCard)}>
                <Typography.Title level={4} className="contact-person-title">
                    Contact person {filledCards.indexOf(filledCard) + 1}
                </Typography.Title>
                <ContactPersonCard
                    email={filledCard.email}
                    phone={filledCard.phone}
                    firstName={filledCard.firstName}
                    lastName={filledCard.lastName}
                    position={filledCard.position}
                    comment={filledCard.comment}
                    onDelete={() => handleDelete(filledCards.indexOf(filledCard))}
                    onEdit={() => handleEdit(filledCard)}
                />
            </div>
        );
    }

    return (
        <Form className="company-registration-form">
            <Row justify="center">
                <Col xs={22}>
                    {renderContactCards()}
                    {renderFormFields()}
                    <Row gutter={64} justify="end">
                        <Col xs={14}>
                            <Button
                                onClick={handleAdditionalClick}
                                type="link"
                                className="additional-contact-button"
                            >
                                + Additional contact person
                            </Button>
                        </Col>
                    </Row>
                    <Row justify="end">
                        <Col>
                            <SubmitButton> Save and Continue </SubmitButton>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};

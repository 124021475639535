import * as Yup from 'yup';

export const CONTACT_PERSON_FIELDS = {
    firstName: 'firstName',
    lastName: 'lastName',
    position: 'position',
    email: 'email',
    phone: 'phone',
    comment: 'comment'
};

export interface ContactPersonModel {
    firstName: string;
    lastName: string;
    position: string;
    email: string;
    phone: string;
    comment: string;
}

export const contactPersonSchema = Yup.object().shape({
    [CONTACT_PERSON_FIELDS.firstName]: Yup.string()
        .trim(),
    [CONTACT_PERSON_FIELDS.email]: Yup.string()
        .trim(),
    [CONTACT_PERSON_FIELDS.lastName]: Yup.string()
        .trim(),
    [CONTACT_PERSON_FIELDS.position]: Yup.string()
        .trim(),
    [CONTACT_PERSON_FIELDS.phone]: Yup.string()
        .trim(),
    [CONTACT_PERSON_FIELDS.comment]: Yup.string()
        .trim(),
});
import React from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { Slider, Row, Col, InputNumber, Typography } from 'antd';
import { SliderRangeProps } from 'antd/lib/slider';
import { FormikFieldProps } from '../common/field-props';

import './slider-input.less';

interface OwnProps {
  label?: string;
  unit?: string;
}

export type SliderInputProps = SliderRangeProps & FormikFieldProps & OwnProps;

type Props = SliderInputProps;

const SliderInput: React.FC<Props> = props => {
    const {
        name,
        label,
        disabled,
        disableOnSubmit,
        className,
        min,
        unit,
        max,
        step,
        defaultValue,
        ...restProps
    } = props;

    const formik = useFormikContext();

    const handleChange = (value: [number, number]) => {
        formik.setFieldValue(name, value);
    };

    const handleMinimumChange = (newValue: number | string | undefined, currentValues: [number, number]) => {
        if(currentValues && newValue && newValue >= currentValues[1]) {
            formik.setFieldValue(name, [currentValues[1], currentValues[1]]);
            return;
        }

        if(!currentValues && defaultValue) {
            formik.setFieldValue(name, [newValue, defaultValue[1]]);
        } else if(currentValues[1]) {
            formik.setFieldValue(name, [newValue, currentValues[1]]);
        }
    };

    const handleMaximumChange = (newValue: number | string | undefined, currentValues: [number, number]) => {
        if(currentValues && newValue && newValue <= currentValues[0]) {
            formik.setFieldValue(name, [currentValues[0], currentValues[0]]);
            return;
        }

        if(!currentValues && defaultValue) {
            formik.setFieldValue(name, [defaultValue[0], newValue]);
        } else if(currentValues[0] !== undefined) {
            formik.setFieldValue(name, [currentValues[0], newValue]);
        }
    };

    return (
        <>
            <Field name={name}>
                {({ field: { value }, form: { isSubmitting } }: FieldProps) => (
                    <>
                        <Row justify="space-between">
                            <Col xs={12}>
                                <Typography.Text className={'slider-input-label'}>
                                    {label}
                                </Typography.Text>
                            </Col>
                            <Col>
                                <Typography.Text className={'slider-input-label-unit'}>
                                    {unit}
                                </Typography.Text>
                            </Col>
                        </Row>
                        <Slider
                            {...restProps}
                            defaultValue={defaultValue}
                            value={value}
                            min={min}
                            max={max}
                            step={step}
                            className={className}
                            onChange={handleChange}
                            disabled={disabled || (disableOnSubmit && isSubmitting)}
                        />
                        <Row gutter={64}>
                            <Col xs={12} className={'slider-number-wrapper'}>
                                <span>
                                    Minimum:
                                </span>
                                <InputNumber
                                    min={min}
                                    max={max}
                                    defaultValue={min}
                                    value={value && value[0]}
                                    className={'slider-number-input'}
                                    onChange={v => handleMinimumChange(v, value)}
                                />
                            </Col>
                            <Col xs={12} className={'slider-number-wrapper'}>
                                <span>
                                    Maximum:
                                </span>
                                <InputNumber
                                    min={min}
                                    max={max}
                                    defaultValue={max}
                                    value={value && value[1]}
                                    className={'slider-number-input'}
                                    onChange={v => handleMaximumChange(v, value)}
                                />
                            </Col>
                        </Row>
                    </>
                )}

            </Field>
        </>
    );
};

export { SliderInput };

import React from 'react';
import { Prompt } from 'react-router-dom';

export interface UnsavedChangesPromptProps {
    when: boolean;
    message: string;
}

function useBlockNavigation(blockNavigation: boolean) {
    React.useEffect(() => {
        if (!blockNavigation) return;

        const interceptEvent = (event: any) => {
            event.preventDefault();
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', interceptEvent);
        return () => window.removeEventListener('beforeunload', interceptEvent);
    }, [blockNavigation]);
}

export const UnsavedChangesPrompt: React.FC<UnsavedChangesPromptProps> = (props: UnsavedChangesPromptProps) => {

    useBlockNavigation(props.when);

    return <Prompt
        {...props}
    />;
};
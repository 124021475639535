import React from 'react';
import { CheckboxGroupProps as $CheckboxGroupProps, CheckboxValueType } from 'antd/lib/checkbox/Group';
import { Checkbox } from 'antd';
import { Field, FieldProps, connect, FormikContextType } from 'formik';
import { FormikFieldProps } from '../common/field-props';

import './checkbox-group-input.less';

interface FormikContextProps {
    formik: FormikContextType<{}>;
}

export type CheckboxInputProps = FormikFieldProps & $CheckboxGroupProps;

type Props = CheckboxInputProps & FormikContextProps;

class CheckboxGroupInputComponent extends React.PureComponent<Props> {

    public render() {
        const {
            name,
            options,
            ...restProps
        } = this.props;

        return (
            <Field name={name}>
                {({ field: { value }, form: { isSubmitting } }: FieldProps) => (
                    <Checkbox.Group
                        name={name}
                        value={value}
                        onChange={this.handleChange}
                        options={options}
                        disabled={isSubmitting}
                        className="checkbox-group-input"
                        {...restProps}
                    />
                )}
            </Field>
        );
    }

    private handleChange = (checkedValues: Array<CheckboxValueType>): void => {
        const {
            name,
            formik: {
                setFieldValue,
                setFieldTouched
            }
        } = this.props;

        setFieldValue(name, checkedValues);
        setFieldTouched(name, true, false);
    };
}

const CheckboxGroupInput = connect<CheckboxInputProps>(CheckboxGroupInputComponent);

export { CheckboxGroupInput };

import { CancelSource } from './cancel-source';
import { RestService } from './rest-service';
import { UserRegistrationFormModel } from '../pages/private/user-registration/form/user-registration-form-model';
import { UserResponse } from './model/user-response';
import { UserListItemResponse } from './model/user-list-item-response';
import { UserDetailsFormModel } from '../pages/private/user-settings/user-details/user-details-form-model';
import { ChangePasswordFormModel } from '../pages/private/user-settings/change-password/change-password-form-model';
import { LoginResponse } from './model/login-response';

class UserService {
    private readonly restService: RestService;

    constructor(cancelSource: CancelSource = new CancelSource()) {
        this.restService = cancelSource.service;
    }

    public readonly registerUser = (userRegistrationRequest: UserRegistrationFormModel): Promise<void> => {
        return this.restService.post<void>('/user/registerUser', userRegistrationRequest)
            .then(response => response.data);
    };

    public readonly getUsersByCompany = (companyId: number): Promise<UserResponse[]> => {
        return this.restService.get<UserResponse[]>(`/user/getUsers/${companyId}`)
            .then(response => response.data);
    };

    public readonly getUser = (userId: number): Promise<UserRegistrationFormModel> => {
        return this.restService.get<UserRegistrationFormModel>(`/user/getUser/${userId}`)
            .then(response => response.data);
    };

    public readonly getUserDetails = (): Promise<UserRegistrationFormModel> => {
        return this.restService.get<UserRegistrationFormModel>('/user/getUserDetails')
            .then(response => response.data);
    };

    public readonly getAllUsers = (): Promise<UserListItemResponse[]> => {
        return this.restService.get<UserListItemResponse[]>('/user/getAll')
            .then(response => response.data);
    };

    public readonly updateUser = (user: UserRegistrationFormModel, userId: number): Promise<void> => {
        return this.restService.put<void>(`/user/updateUser/${userId}`, user)
            .then(response => response.data);
    };

    public readonly updateUserDetails = (user: UserDetailsFormModel): Promise<LoginResponse> => {
        return this.restService.put<LoginResponse>('/user/updateUserDetails', user)
            .then(response => response.data);
    };

    public readonly changePassword = (changePasswordModel: ChangePasswordFormModel): Promise<void> => {
        return this.restService.put<void>('/authentication/changePassword', changePasswordModel)
            .then(response => response.data);
    };
}

const userService = new UserService();

export { userService, UserService };

import * as Yup from 'yup';
import { Validations } from '../../../../components/util/validation-utils';

const MAX_LENGTH = 255;
const MIN_COMPANIES = 3;

export const TRADERS_LIST_FORM_FIELDS = {
    name: 'name',
    companyIds: 'companyIds'
};

export const tradersListFormSchema = Yup.object().shape({
    [TRADERS_LIST_FORM_FIELDS.name]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .required(Validations.required),
    [TRADERS_LIST_FORM_FIELDS.companyIds]: Yup.array()
        .min(MIN_COMPANIES, 'Traders list must contain at least ' + MIN_COMPANIES + ' companies')
        .required(Validations.required)
});

import React from 'react';
import { TradeProposalStatus } from '../../config/constants';
import { EditFilled, CloseCircleFilled, CheckCircleFilled, ClockCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import './trade-proposal-status-text.less';

export interface TradeProposalStatusTextProps {
    status: TradeProposalStatus;
}

export const TradeProposalStatusText: React.FC<TradeProposalStatusTextProps> = (props: TradeProposalStatusTextProps) => {
    const {
        status
    } = props;

    let element: React.ReactNode;

    switch(status) {
        case TradeProposalStatus.ACTIVE:
            element = <div className='status-wrapper'>
                <div className='circle active'/>
                Active
            </div>;
            break;
        case TradeProposalStatus.CANCELLED:
            element = <div className='status-wrapper'>
                <CloseCircleFilled className='cancelled'/> Cancelled
            </div>;
            break;
        case TradeProposalStatus.DRAFT:
            element = <div className='status-wrapper'>
                <EditFilled className='draft'/> Draft
            </div>;
            break;
        case TradeProposalStatus.EXPIRED:
            element = <div className='status-wrapper expired-wrapper'>
                <div className='circle expired'/> Expired
            </div>;
            break;
        case TradeProposalStatus.REJECTED_BY_TSO:
            element = <div className='status-wrapper'>
                <CloseCircleFilled className='cancelled'/> Cancelled: Rejected by TSO
            </div>;
            break;
        case TradeProposalStatus.RESPONSE_RECEIVED:
            element = <Button className='status-button pending-response' type='link'>
                <ClockCircleOutlined className='circle clock'/> Pending response
            </Button>;
            break;
        case TradeProposalStatus.TRADE:
            element = <div className='status-wrapper'>
                <CheckCircleFilled className='success'/> Trade
            </div>;
            break;
    }

    return <>{ element }</>;
};

import * as Yup from 'yup';
import { Validations } from '../../../../components/util/validation-utils';

export const TWO_FACTOR_LOGIN_FIELDS = {
    code: 'code',
};

export const TwoFactorLoginSchema = Yup.object().shape({
    [TWO_FACTOR_LOGIN_FIELDS.code]: Yup.string()
        .required(Validations.required),
});

import * as Yup from 'yup';
import { Validations } from '../../../../../components/util/validation-utils';

export const COMPANY_INFORMATION_FORM_FIELDS = {
    eicCode: 'eicCode',
    acerCode: 'acerCode',
    companyName: 'companyName',
    companyCode: 'companyCode',
    companyVatCode: 'companyVatCode',
    streetNumber: 'streetNumber',
    postalCode: 'postalCode',
    city: 'city',
    country: 'country',
    bankName: 'bankName',
    iban: 'iban',
    bankCode: 'bankCode',
    invoicingEmail: 'invoicingEmail'
};

const MAX_LENGTH = 255;

export const companyInformationSchema = Yup.object().shape({
    [COMPANY_INFORMATION_FORM_FIELDS.eicCode]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .required(Validations.required),
    [COMPANY_INFORMATION_FORM_FIELDS.acerCode]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .required(Validations.required),
    [COMPANY_INFORMATION_FORM_FIELDS.companyName]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .required(Validations.required),
    [COMPANY_INFORMATION_FORM_FIELDS.companyCode]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .required(Validations.required),
    [COMPANY_INFORMATION_FORM_FIELDS.companyVatCode]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .required(Validations.required),
    [COMPANY_INFORMATION_FORM_FIELDS.streetNumber]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .nullable(),
    [COMPANY_INFORMATION_FORM_FIELDS.postalCode]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .nullable(),
    [COMPANY_INFORMATION_FORM_FIELDS.city]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .nullable(),
    [COMPANY_INFORMATION_FORM_FIELDS.country]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .nullable(),
    [COMPANY_INFORMATION_FORM_FIELDS.bankName]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .nullable(),
    [COMPANY_INFORMATION_FORM_FIELDS.iban]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .nullable(),
    [COMPANY_INFORMATION_FORM_FIELDS.bankCode]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .nullable(),
    [COMPANY_INFORMATION_FORM_FIELDS.invoicingEmail]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .nullable()
});

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { navigationService } from './service/navigation-service';
import { LoginPage } from './pages/public/login-page/login-page';
import { CompanyRegistrationPage } from './pages/private/company-registration/company-registration-page';
import { UserRegistrationPage } from './pages/private/user-registration/user-registration-page';
import { ForgotPasswordPage } from './pages/public/forgot-password/forgot-password-page';
import { resetPasswordPage } from './pages/public/reset-password/reset-password-page';
import { UsersManagementPage } from './pages/private/users-management/users-management-page';
import { CapacityCategoryPage } from './pages/private/capacity-category/capacity-category-page';
import { NewTradeProposalPage } from './pages/private/trade-proposals/new-trade-proposal/new-trade-proposal-page';
import { SettingsPage } from './pages/private/settings/settings-page';
import { PrivateRoute, Role } from './components/auth/private-route';
import { AccessRight } from './config/constants';
import { QueryParamProvider } from 'use-query-params';
import { tradeProposalDetailsPage } from './pages/private/trade-proposals/trade-proposal-details/trade-proposal-details-page';
import { MyTradeProposalsPage } from './pages/private/trade-proposals/my-trade-proposals/my-trade-proposals-page';
import { MyResponsesPage } from './pages/private/responses/my-responses-page';
import { TradingResultsPage } from './pages/private/trading-results/public-trading-results/trading-results-page';
import { MyTradingResultsPage } from './pages/private/trading-results/my-trading-results/my-trading-results-page';
import { tradingResultsDetailsPage } from './pages/private/trading-results/public-trading-results/details/trading-result-details-page';
import { TradeProposalsReportsPage } from './pages/private/reports/trade-proposals/trade-proposal-reports-page';
import { ResponsesReportsPage } from './pages/private/reports/responses/response-reports-page';
import { tradeProposalReportDetailsPage } from './pages/private/reports/details/trade-proposal-report-details-page';
import { TradingResultsReportsPage } from './pages/private/reports/trading-results/trading-results-reports-page';
import { tradingResultReportDetailsPage } from './pages/private/reports/details/trading-result-report-details-page';
import { UserSettingsPage } from './pages/private/user-settings/user-settings-page';
import { LogsPage } from './pages/private/logs/logs-page';
import { TradeProposalsPage } from './pages/private/trade-proposals/trade-proposals-page';
import { PageNotFound } from './pages/error/page-not-found';
import { twoFactorLoginPage } from './pages/public/two-factor-login/two-factor-login-page';
import { myTradingResultsDetailsPage } from './pages/private/trading-results/my-trading-results/details/my-trading-results-details-page';

const Routes = () => (
    <QueryParamProvider ReactRouterRoute={Route}>
        <Switch>
            <Route
                path={navigationService.LOGIN_PATH}
                exact={true}
                component={LoginPage}
            />
            <Route
                path={navigationService.FORGOT_PASSWORD_PATH}
                exact={true}
                component={ForgotPasswordPage}
            />
            <Route
                path={navigationService.RESET_PASSWORD_PATH}
                exact={true}
                component={resetPasswordPage}
            />
            <Route
                path={navigationService.TWO_FACTOR_LOGIN_PATH}
                exact={true}
                component={twoFactorLoginPage}
            />
            <Route
                path={navigationService.TRADING_RESULTS_PATH}
                exact={true}
                component={TradingResultsPage}
            />
            <Route
                path={navigationService.TRADING_RESULT_DETAILS_PATH}
                exact={true}
                component={tradingResultsDetailsPage}
            />
            <Route
                path={[navigationService.TRADE_PROPOSAL_DETAILS_PATH, navigationService.RESPONSE_DETAILS_PATH]}
                exact={true}
                component={tradeProposalDetailsPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_USER]}
                path={navigationService.RESPONSE_DETAILS_PATH}
                exact={true}
                component={tradeProposalDetailsPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_ADMIN]}
                path={[navigationService.TRADE_PROPOSAL_REPORT_DETAILS_PATH, navigationService.RESPONSE_REPORT_DETAILS_PATH]}
                exact={true}
                component={tradeProposalReportDetailsPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_ADMIN]}
                path={[navigationService.COMPANY_REGISTRATION_PATH, navigationService.COMPANY_EDIT_PATH]}
                component={CompanyRegistrationPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_ADMIN]}
                path={[navigationService.USER_REGISTRATION_PATH, navigationService.USER_EDIT_PATH]}
                exact={true}
                component={UserRegistrationPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_ADMIN]}
                path={navigationService.USERS_MANAGEMENT_PATH}
                exact={true}
                component={UsersManagementPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_ADMIN]}
                path={navigationService.ADD_CAPACITY_CATEGORY_PATH}
                exact={true}
                component={CapacityCategoryPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_USER]}
                hasAccess={[AccessRight.FULL_CONTROL_USER]}
                path={navigationService.CREATE_TRADE_PROPOSAL_PATH}
                exact={true}
                component={NewTradeProposalPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_USER]}
                hasAccess={[AccessRight.READ_ONLY_USER, AccessRight.FULL_CONTROL_USER]}
                path={navigationService.TRADE_PROPOSAL_EDIT_PATH}
                exact={true}
                component={NewTradeProposalPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_ADMIN]}
                path={navigationService.SETTINGS_PATH}
                exact={true}
                component={SettingsPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_USER, Role.SYSTEM_ADMIN]}
                path={navigationService.USER_SETTINGS_PATH}
                exact={true}
                component={UserSettingsPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_USER]}
                path={navigationService.MY_TRADE_PROPOSALS_PATH}
                exact={true}
                component={MyTradeProposalsPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_ADMIN]}
                path={navigationService.TRADE_PROPOSAL_REPORTS_PATH}
                exact={true}
                component={TradeProposalsReportsPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_ADMIN]}
                path={navigationService.RESPONSE_REPORTS_PATH}
                exact={true}
                component={ResponsesReportsPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_ADMIN]}
                path={navigationService.TRADE_RESULT_REPORTS_PATH}
                exact={true}
                component={TradingResultsReportsPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_ADMIN]}
                path={navigationService.TRADING_RESULT_REPORT_DETAILS_PATH}
                exact={true}
                component={tradingResultReportDetailsPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_USER]}
                path={navigationService.MY_RESPONSES_PATH}
                exact={true}
                component={MyResponsesPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_USER]}
                path={navigationService.MY_TRADING_RESULTS_PATH}
                exact={true}
                component={MyTradingResultsPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_USER]}
                path={navigationService.MY_TRADING_RESULT_DETAILS_PATH}
                exact={true}
                component={myTradingResultsDetailsPage}
            />
            <PrivateRoute
                hasRole={[Role.SYSTEM_ADMIN]}
                path={navigationService.LOGS_PATH}
                exact={true}
                component={LogsPage}
            />
            <Route
                path={navigationService.HOME_PATH}
                component={TradeProposalsPage}
            />
            <Route component={PageNotFound}/>
        </Switch>
    </QueryParamProvider>
);

export { Routes };

import { AuthenticationState } from './authentication/types';
import { combineReducers, createStore, Store } from 'redux';
import { authenticationReducer } from './authentication/reducers';

export interface RootState {
    authentication: AuthenticationState;
}

const combinedReducers = combineReducers({ authentication: authenticationReducer });

function configureStore() {
    const store: Store = createStore(combinedReducers);

    return store;
}

export const appStore = configureStore();

import { CancelSource } from './cancel-source';
import { RestService } from './rest-service';
import { TsoResponse } from './model/tso-response';
import { TsoFormModel } from '../pages/private/settings/form/tso-form-model';
import { TsoModel } from './model/tso-model';

class TsoService {
    private readonly restService: RestService;

    constructor(cancelSource: CancelSource = new CancelSource()) {
        this.restService = cancelSource.service;
    }

    public readonly getAllTso = (): Promise<TsoResponse[]> => {
        return this.restService.get<TsoResponse[]>('/tso')
            .then(response => response.data);
    };

    public readonly getTso = (tsoId: number): Promise<TsoModel> => {
        return this.restService.get<TsoModel>(`/tso/${tsoId}`)
            .then(response => response.data);
    };

    public readonly updateTso = (tsoId: number, model: TsoFormModel): Promise<TsoModel> => {
        return this.restService.put<TsoModel>(`/tso/${tsoId}`, model)
            .then(response => response.data);
    };
}

const tsoService = new TsoService();

export { tsoService, TsoService };

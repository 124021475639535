import React from 'react';
import { Col, Row, Skeleton, Typography } from 'antd';
import { LabeledText } from './labeled-text';
import { TradingResultSettlementInformation } from '../../api/model/trading-result-settlement-information';
import { ContactPersonCard } from '../card/contact-person-card';

import './settlement-information.less';

export interface TradingResultResponseInformationProps {
    loading: boolean;
    settlementInformation?: TradingResultSettlementInformation;
}

export const SettlementInformation = (props: TradingResultResponseInformationProps) => {
    const {
        loading,
        settlementInformation
    } = props;

    return <Skeleton
        loading={loading}
        active={loading}
        paragraph={{ rows: 10 }}
    >
        <Row className={'proposal-details settlement-information'}>
            <Typography.Title level={4} className={'details-header'}>
                    Settlement information
            </Typography.Title>
            <Col xs={24}>
                <LabeledText
                    value={settlementInformation?.companyName}
                    label={'Company name'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={settlementInformation?.companyCode}
                    label={'Company code'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={settlementInformation?.vatCode}
                    label={'Company VAT code'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={settlementInformation?.address}
                    label={'Company address'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={settlementInformation?.bankName}
                    label={'Bank name'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={settlementInformation?.iban}
                    label={'IBAN'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={settlementInformation?.bankCode}
                    label={'Bank code'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={settlementInformation?.invoicingEmail}
                    label={'Invoice to'}
                    labelAlign={'right'}
                />
            </Col>
        </Row>
        {settlementInformation?.contactPersonDetails && settlementInformation?.contactPersonDetails.length > 0 &&
            <Row className={'proposal-details'}>
                <Col xs={24}>
                    <Typography.Title level={4} className={'details-header'}>
                        Contact people
                    </Typography.Title>
                    {settlementInformation?.contactPersonDetails.map((contact, index) => {
                        return <ContactPersonCard
                            key={index}
                            email={contact.email}
                            phone={contact.phone}
                            firstName={contact.firstName}
                            lastName={contact.lastName}
                            comment={contact.comment}
                            position={contact.position}
                            readOnly={true}
                        />;
                    })}
                </Col>
            </Row>
        }
    </Skeleton>;
};

import * as Yup from 'yup';
import { Validations } from '../../../../components/util/validation-utils';

export const TSO_FORM_FIELDS = {
    name: 'name',
    networkPointIds: 'networkPointIds',
    capacityCategoryIds: 'capacityCategoryIds',
    expirationTime: 'expirationTime',
    validationTime: 'validationTime',
    canApplyTradersList: 'canApplyTradersList'
};

const MAX_LENGTH = 255;

const MIN_TIME = 0;
const MAX_TIME = 72;

export const TsoSchema = Yup.object().shape({
    [TSO_FORM_FIELDS.name]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .matches(Validations.regex.letters, Validations.fieldCanOnlyContainLetters)
        .required(Validations.required),
    [TSO_FORM_FIELDS.networkPointIds]: Yup.string()
        .required(Validations.required),
    [TSO_FORM_FIELDS.capacityCategoryIds]: Yup.string()
        .required(Validations.required),
    [TSO_FORM_FIELDS.expirationTime]: Yup.number()
        .typeError(Validations.onlyNumbers)
        .moreThan(MIN_TIME, Validations.moreThan(MIN_TIME))
        .lessThan(MAX_TIME, Validations.lessThan(MAX_TIME))
        .required(Validations.required),
    [TSO_FORM_FIELDS.validationTime]: Yup.number()
        .typeError(Validations.onlyNumbers)
        .moreThan(MIN_TIME, Validations.moreThan(MIN_TIME))
        .lessThan(MAX_TIME, Validations.lessThan(MAX_TIME))
        .nullable(),
    [TSO_FORM_FIELDS.canApplyTradersList]: Yup.string()
        .required(Validations.required)
});

import React from 'react';
import { Formik, FormikHelpers } from 'formik';

import { Form } from '../../../../components/inputs/common/form';
import { FormItem } from '../../../../components/inputs/common/form-item';
import { TextInput } from '../../../../components/inputs/text-input/text-input';
import { SubmitButton } from '../../../../components/button/submit-button';
import { Button, Row, Col } from 'antd';
import { ForgotPasswordFormModel, ForgotPasswordSchema } from './forgot-password-form-model';
import { navigationService } from '../../../../service/navigation-service';

import './forgot-password-form.less';

interface OwnProps {
    initialValues: ForgotPasswordFormModel;
    handleSubmit: (
        values: ForgotPasswordFormModel,
        actions: FormikHelpers<ForgotPasswordFormModel>
    ) => void;
}

type Props = OwnProps;

const ForgotPasswordForm: React.FC<Props> = (props: Props) => {
    const {
        handleSubmit,
        initialValues
    } = props;

    const renderForm = (): React.ReactNode => {
        return (
            <Form>
                <FormItem label="Email" name={'email'}>
                    <TextInput
                        name={'email'}
                    />
                </FormItem>
                <Row justify="space-between">
                    <Col xs={10}>
                        <Button
                            onClick={() => navigationService.goToLoginPage()}
                            className="back-to-login"
                            block={true}
                        >
                            Back to login
                        </Button>
                    </Col>
                    <Col xs={10}>
                        <SubmitButton className="send-email-button" block={true}>
                            Send email
                        </SubmitButton>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={ForgotPasswordSchema}
        >
            {renderForm}
        </Formik>
    );
};

export { ForgotPasswordForm };

import { AxiosResponse } from 'axios';

export function downloadFile(response: AxiosResponse) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    const fileName = response.headers['content-disposition'].split('filename=')[1];

    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
}

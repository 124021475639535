import React from 'react';
import { Col, Row, Typography } from 'antd';

import './form-section-text.less';

export interface FormSectionHeaderProps {
    sectionName: string;
    className?: string;
}

export const FormSectionHeader: React.FC<FormSectionHeaderProps> = (props: FormSectionHeaderProps) => {
    const {
        sectionName,
        className
    } = props;

    return <Row justify="start" gutter={64}>
        <Col xs={10} className={`form-section-header-col ${className}`}>
            <Typography.Text className='form-section-header-text'>
                {sectionName}
            </Typography.Text>
        </Col>
    </Row>;
};

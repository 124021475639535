import { ColumnProps } from 'antd/es/table';
import moment from 'moment';

import { capacityUnit, currency, dateFormat, priceUnit } from '../../../../../config/constants';
import { InvoiceModel } from '../../../../../api/model/invoice-model';
import { formatNumber } from '../../../../../utils/number-utils';

export const invoicesColumns: ColumnProps<InvoiceModel>[] = [
    {
        title: 'Invoice date',
        dataIndex: 'invoiceDate',
        className: 'table-column',
        render: (invoiceDate: string) => moment(invoiceDate).format(dateFormat)

    },
    {
        title: 'Payment due date',
        dataIndex: 'paymentDueDate',
        className: 'table-column',
        render: (paymentDueDate: string) => moment(paymentDueDate).format(dateFormat)
    },
    {
        title: 'Period',
        dataIndex: 'dateFrom',
        className: 'table-column',
        width: 220,
        render: (dateFrom: string, invoiceModel: InvoiceModel) =>
            `${moment(dateFrom).format(dateFormat)} - ${moment(invoiceModel.dateUntil).format(dateFormat)}`
    },
    {
        title: 'Number of days',
        dataIndex: 'daysCount',
        className: 'table-column'
    },
    {
        title: 'Price, ' + priceUnit,
        dataIndex: 'price',
        className: 'table-column'
    },
    {
        title: 'Capacity amount, ' + capacityUnit,
        dataIndex: 'capacityAmount',
        className: 'table-column',
        render: (capacityAmount: number) => formatNumber(capacityAmount)
    },
    {
        title: 'Sum, ' + currency,
        dataIndex: 'sum',
        className: 'table-column',
        render: (sum: number) => formatNumber(sum)
    }
];

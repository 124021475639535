import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Skeleton, Typography } from 'antd';
import moment, { Moment } from 'moment';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { Form } from '../../../../../components/inputs/common/form';
import { FormItem } from '../../../../../components/inputs/common/form-item';
import { RESPONSE_FORM_FIELDS } from './response-form-schema';
import { NumberInput } from '../../../../../components/inputs/number-input/number-input';
import { CalendarInput } from '../../../../../components/inputs/calendar-input/calendar-input';
import { DurationType, getDurationType } from '../../../../../components/inputs/calendar-input/duration-input';
import { FormSectionHeader } from '../../../../../components/text/form-section-header';
import { SubmitButton } from '../../../../../components/button/submit-button';
import { UnsavedChangesPrompt } from '../../../../../components/prompt/unsaved-changes-prompt';
import { SelectInput, SelectOptionModel } from '../../../../../components/inputs/select-input/select-input';
import { tradersListService } from '../../../../../api/traders-list-service';
import { RootState } from '../../../../../redux/store';
import { capacityUnit, dateTimeFormat } from '../../../../../config/constants';
import { ListItem } from '../../../../../api/model/list-item';
import { NotificationType, notify } from '../../../../../service/notification-service';

import './response-form.less';

export interface CreateResponseFormProps {
    maxCapacity: number;
    minCapacity: number;
    minPeriod: string;
    maxPeriod: string;
    minAcceptablePeriod: number;
    durationType: DurationType;
    createdAt?: string;
    isPendingResponse: boolean;
    disabled: boolean;
    disabledCapacity: boolean;
    selectTradersListVisible: boolean;
    showSubmit: boolean;
    handleReject: () => void;
    handleAccept: () => void;
}

export const CreateResponseForm = (props: CreateResponseFormProps) => {

    const {
        maxCapacity,
        maxPeriod,
        minCapacity,
        minPeriod,
        minAcceptablePeriod,
        durationType,
        createdAt,
        isPendingResponse,
        disabled,
        handleAccept,
        showSubmit,
        selectTradersListVisible,
        handleReject
    } = props;

    const formik = useFormikContext();
    const authentication = useSelector((state: RootState) => state.authentication);
    const selectedCompanyId = authentication.selectedCompany?.id;
    const [allTradersLists, setAllTradersLists] = useState<ListItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (selectTradersListVisible) {
            if (!selectedCompanyId) {
                return;
            }

            setLoading(true);

            tradersListService.getAllTradersLists(selectedCompanyId)
                .then((response) => {
                    setAllTradersLists(response);
                    setLoading(false);
                })
                .catch(() => notify({
                    type: NotificationType.ERROR,
                    message: 'Could not load traders lists'
                }));
        }
    }, [selectTradersListVisible]);

    function disabledDatesFrom(current: Moment): boolean {
        const max = moment(maxPeriod).subtract(minAcceptablePeriod, getDurationType(durationType));

        return current && (current < moment(minPeriod) || current > max);
    }

    function disabledDatesTo(current: Moment): boolean {
        const min = moment(minPeriod).add(minAcceptablePeriod, getDurationType(durationType));

        return current && (current < min || current > moment(maxPeriod));
    }

    function mapListItemToOptions(value: {id: number; name: string}): SelectOptionModel {
        return {
            value: value.id,
            label: value.name
        };
    }

    return <Skeleton loading={loading} active={loading} paragraph={{ rows: 10 }}>
        <Form className="response-form">
            {isPendingResponse && <FormSectionHeader sectionName={'Pending response'}/>}
            {!isPendingResponse && <FormSectionHeader sectionName={'Response'} className="response-header"/>}
            {selectTradersListVisible && allTradersLists?.length > 0 &&
                <FormItem name={RESPONSE_FORM_FIELDS.tradersList}>
                    <SelectInput
                        disabled={!showSubmit || formik.isSubmitting}
                        name={RESPONSE_FORM_FIELDS.tradersList}
                        placeholder={'Select traders list'}
                        options={allTradersLists?.map(n => mapListItemToOptions(n))}
                        mapItemToOptions={mapListItemToOptions}
                    />
                </FormItem>
            }
            <Card className="response-card">
                <FormSectionHeader sectionName={'Capacity'}/>
                <FormItem name={RESPONSE_FORM_FIELDS.capacity} className={'response-form-item'} label={'Capacity amount'} labelType="horizontal">
                    <NumberInput
                        name={RESPONSE_FORM_FIELDS.capacity}
                        unit={capacityUnit}
                        disabled={!showSubmit || formik.isSubmitting}
                        min={minCapacity}
                        max={maxCapacity}
                    />
                </FormItem>
                <FormSectionHeader sectionName={'Period'}/>
                <FormItem
                    name={RESPONSE_FORM_FIELDS.periodFrom}
                    className={'response-form-item'}
                    label={'Date and time from'}
                    labelType="horizontal"
                >
                    <CalendarInput
                        name={RESPONSE_FORM_FIELDS.periodFrom}
                        disabled={disabled || formik.isSubmitting}
                        disabledDate={disabledDatesFrom}
                        showToday={false}
                    />
                </FormItem>
                <FormItem
                    name={RESPONSE_FORM_FIELDS.periodTo}
                    className={'response-form-item'}
                    label={'Date and time to'}
                    labelType="horizontal"
                >
                    <CalendarInput
                        name={RESPONSE_FORM_FIELDS.periodTo}
                        disabled={disabled || formik.isSubmitting}
                        disabledDate={disabledDatesTo}
                        showToday={false}
                    />
                </FormItem>
                {createdAt &&
                    <Row className="creation-time" gutter={64}>
                        <Col xs={10}>
                            <Typography.Text className="label">
                                Creation time
                            </Typography.Text>
                        </Col>
                        <Col xs={14} className="time">
                            {moment(createdAt).format(dateTimeFormat)}
                        </Col>
                    </Row>
                }
            </Card>
            {isPendingResponse
                ? <Row justify='space-between' className="respond-button">
                    <Col xs={8}>
                        <Button danger={true} block={true} onClick={() => handleReject()}>
                            Reject
                        </Button>
                    </Col>
                    <Col xs={8}>
                        <Button block={true} type="primary" onClick={() => handleAccept()}>
                            Accept
                        </Button>
                    </Col>
                </Row>
                : <Row justify='center' className="respond-button">
                    <Col>
                        {showSubmit &&
                            <SubmitButton disabled={formik.isSubmitting}>
                                Create response
                            </SubmitButton>
                        }
                    </Col>
                </Row>
            }
            <UnsavedChangesPrompt
                when={formik.values !== formik.initialValues && !formik.isSubmitting}
                message={'You have unsaved changes, are you sure you want to leave this page?'}
            />
        </Form>
    </Skeleton>;
};

import { CancelSource } from './cancel-source';
import { RestService } from './rest-service';
import { PageResponse } from './page-response';
import { stringify } from 'query-string';
import { PublicTradingResultModel } from './model/public-trading-result-model';
import { TradingResultsFilterModel } from './model/trading-results-filter-model';
import { TradingResultListItem } from './model/trading-result-list-item';
import { TradingResultDetails } from './model/trading-result-details';
import { TradingResultSettlementInformation } from './model/trading-result-settlement-information';
import { TradingResultReportFilterModel } from './model/trading-result-report-filter-model';
import { TradingResultReport } from './model/trading-result-report';
import { TradingResultReportDetails } from './model/trading-result-report-details';
import { downloadFile } from '../utils/file-utils';
import { FilterMaxValuesModel } from './model/filter-max-values-model';

class TradingResultService {
    private readonly restService: RestService;

    constructor(cancelSource: CancelSource = new CancelSource()) {
        this.restService = cancelSource.service;
    }

    public readonly getTradingResultDetails = (id: number, selectedCompanyId?: number): Promise<TradingResultDetails> => {
        return this.restService.get<TradingResultDetails>(
            `/tradingResults/getDetails/${id}`,
            { params: { selectedCompanyId } }
        )
            .then(response => response.data);
    };

    public readonly getOwnTradingResultDetails = (id: number, selectedCompanyId?: number): Promise<TradingResultDetails> => {
        return this.restService.get<TradingResultDetails>(
            `/tradingResults/getOwnDetails/${id}`,
            { params: { selectedCompanyId } }
        )
            .then(response => response.data);
    };

    public readonly getTradingResultReportDetails = (tradingResultId: number): Promise<TradingResultReportDetails> => {
        return this.restService.get<TradingResultReportDetails>(`/tradingResults/getReportDetails/${tradingResultId}`)
            .then(response => response.data);
    };

    public readonly getTradingResultSettlementInformation = (companyId: number, tradingResultId: number): Promise<TradingResultSettlementInformation> => {
        return this.restService.get<TradingResultSettlementInformation>(`tradingResults/settlementInformation/${companyId}/${tradingResultId}`)
            .then(response => response.data);
    };

    public readonly getTradingResults = (
        sorter?: string | string[],
        page?: number,
        size?: number,
        filters?: TradingResultsFilterModel
    ): Promise<PageResponse<PublicTradingResultModel>> => {
        const requestParams = {
            page,
            size,
            sort: sorter
        };

        return this.restService.post<PageResponse<PublicTradingResultModel>>('/tradingResults', filters,{
            params: requestParams,
            paramsSerializer: params => stringify(params, { arrayFormat: 'none' })
        }).then(response => response.data);
    };

    public readonly getTradingResultReports = (
        sorter?: string | string[],
        page?: number,
        size?: number,
        filters?: TradingResultReportFilterModel
    ): Promise<PageResponse<TradingResultReport>> => {
        const requestParams = {
            page,
            size,
            sort: sorter
        };

        return this.restService.post<PageResponse<TradingResultReport>>('/tradingResults/getReports', filters,{
            params: requestParams,
            paramsSerializer: params => stringify(params, { arrayFormat: 'none' })
        }).then(response => response.data);
    };

    public readonly getTradingResultsByCompany = (
        companyId: number,
        sorter?: string | string[],
        page?: number,
        size?: number,
        filters?: TradingResultsFilterModel
    ): Promise<PageResponse<TradingResultListItem>> => {
        const requestParams = {
            page,
            size,
            sort: sorter
        };

        return this.restService.post<PageResponse<TradingResultListItem>>(`/tradingResults/${companyId}`, filters,{
            params: requestParams,
            paramsSerializer: params => stringify(params, { arrayFormat: 'none' })
        }).then(response => response.data);
    };

    public readonly getMaximumFilterValues = (): Promise<FilterMaxValuesModel> => {
        return this.restService.get<FilterMaxValuesModel>('/tradingResults/getMaxFilterValues')
            .then(response => response.data);
    };

    public readonly getMaximumFilterValuesByCompany = (companyId: number): Promise<FilterMaxValuesModel> => {
        return this.restService.get<FilterMaxValuesModel>(`/tradingResults/${companyId}/getMaxFilterValues`)
            .then(response => response.data);
    };

    public readonly downloadPublic = (sorts?: string | string[], filters?: TradingResultsFilterModel): Promise<void> => {
        return this.restService.post<void>('/tradingResults/download', filters,{
            params: { sort: sorts },
            paramsSerializer: params => stringify(params, { arrayFormat: 'none' }),
            responseType: 'blob'
        }).then(response => downloadFile(response));
    };

    public readonly download = (companyId: number, sorts?: string | string[], filters?: TradingResultsFilterModel): Promise<void> => {
        return this.restService.post<void>(`/tradingResults/${companyId}/download`, filters,{
            params: { sort: sorts },
            paramsSerializer: params => stringify(params, { arrayFormat: 'none' }),
            responseType: 'blob'
        }).then(response => downloadFile(response));
    };

    public readonly downloadReports = (sorts?: string | string[], filters?: TradingResultReportFilterModel): Promise<void> => {
        return this.restService.post<void>('/tradingResults/getReports/download', filters,{
            params: { sort: sorts },
            paramsSerializer: params => stringify(params, { arrayFormat: 'none' }),
            responseType: 'blob'
        }).then(response => downloadFile(response));
    };
}

const tradingResultService = new TradingResultService();

export { tradingResultService, TradingResultService };


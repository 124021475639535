import { AxiosError } from 'axios';

export enum ErrorType {
    LOGIN_ATTEMPTS_EXCEEDED_ERROR = 'LOGIN_ATTEMPTS_EXCEEDED_ERROR',
    PASSWORD_RECENTLY_USED = 'PASSWORD_RECENTLY_USED',
    USER_ACCOUNT_DISABLED = 'USER_ACCOUNT_DISABLED',
    INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
    PASSWORD_IS_THE_SAME = 'PASSWORD_IS_THE_SAME',
    TWO_FACTOR_REQUIRED = 'TWO_FACTOR_REQUIRED',
    TSO_DEADLINE_PASSED = 'TSO_DEADLINE_PASSED',
    COUNTERPARTY_NOT_IN_TRADERS_LIST = 'COUNTERPARTY_NOT_IN_TRADERS_LIST',
    NOT_ENOUGH_ACTIVE_COMPANIES = 'NOT_ENOUGH_ACTIVE_COMPANIES'
}

export interface ErrorModel {
    message: string;
    httpStatus: string;
    errorType: ErrorType;
    token?: string;
}

export function getErrorMessage(error: AxiosError): ErrorModel {
    return error.response?.data ?? error.message;
}

import React from 'react';
import { Button, Col, Row } from 'antd';
import { useFormikContext } from 'formik';

import { FormItem } from '../../../../components/inputs/common/form-item';
import { CheckboxGroupInput } from '../../../../components/inputs/checkbox-input/checkbox-group-input';
import { SelectInput, SelectOptionModel } from '../../../../components/inputs/select-input/select-input';
import { CalendarRangeInput } from '../../../../components/inputs/calendar-input/calendar-range-input';
import { SliderInput } from '../../../../components/inputs/slider-input/slider-input';
import { SubmitButton } from '../../../../components/button/submit-button';
import { Form } from '../../../../components/inputs/common/form';
import {
    capacityUnit,
    dateFormat,
    priceUnit,
    proposalCheckboxOptions
} from '../../../../config/constants';
import { tsoService } from '../../../../api/tso-service';
import { networkPointService } from '../../../../api/network-point-service';
import { capacityCategoryService } from '../../../../api/capacity-category-service';
import { companyService } from '../../../../api/company-service';
import { tradeProposalService } from '../../../../api/trade-proposal-service';
import {
    CAPACITY_RANGE_MIN,
    TOTAL_PRICE_MIN,
    TRADING_RESULT_FILTER_FIELDS,
    TradingResultsFilterFormModel
} from './trading-results-filter-form-model';
import { myTradingResultsOfferTypes } from '../my-trading-results/my-trading-results-columns';

import './../../trade-proposals/filter/trade-proposal-filter-form.less';

interface TradeProposalFilterFormProps {
    handleReset: () => void;
    maxPrice: number;
    maxCapacity: number;
    isPublic: boolean;
    disableButtons?: boolean;
}

export const TradingResultsFilterForm = (props: TradeProposalFilterFormProps) => {

    const {
        maxPrice,
        maxCapacity,
        isPublic,
        disableButtons
    } = props;

    const formik = useFormikContext<TradingResultsFilterFormModel>();

    function loadTsos() {
        return tsoService.getAllTso();
    }

    function loadNetworkPoints() {
        return networkPointService.getAllNetworkPoints();
    }

    function loadCapacityCategories() {
        return capacityCategoryService.getAllCapacityCategories();
    }

    function loadCompanies() {
        return companyService.getCompanies();
    }

    function loadProcedures() {
        return tradeProposalService.getAllProcedures()
            .then(procedures => {
                return procedures.map(procedure => {
                    return { id: procedure, name: procedure };
                });
            });
    }

    function mapListItemToOptions(value: {id: number | string; name: string}): SelectOptionModel {
        return {
            value: value.id,
            label: value.name
        };
    }

    function handleFilterReset() {
        formik.resetForm();
        props.handleReset();
    }

    function renderSliders(): React.ReactNode {
        return <Col xs={12} className={'form-col'}>
            <FormItem
                className={'filter-form-item'}
                name={TRADING_RESULT_FILTER_FIELDS.capacityRange}
            >
                <SliderInput
                    label={'Capacity amount'}
                    name={TRADING_RESULT_FILTER_FIELDS.capacityRange}
                    range={true}
                    unit={capacityUnit}
                    min={CAPACITY_RANGE_MIN}
                    max={maxCapacity + 1}
                    step={1}
                />
            </FormItem>
            <FormItem
                className={'filter-form-item'}
                name={TRADING_RESULT_FILTER_FIELDS.totalPriceRange}
            >
                <SliderInput
                    label={'Price'}
                    name={TRADING_RESULT_FILTER_FIELDS.totalPriceRange}
                    range={true}
                    unit={priceUnit}
                    min={TOTAL_PRICE_MIN}
                    max={maxPrice}
                    step={0.01}
                />
            </FormItem>
        </Col>;
    }

    function renderFilterFields(): React.ReactNode {
        return <Col xs={12} className={'form-col'}>
            <FormItem name={TRADING_RESULT_FILTER_FIELDS.offerTypes}>
                <CheckboxGroupInput
                    className={'offer-options'}
                    name={TRADING_RESULT_FILTER_FIELDS.offerTypes}
                    options={isPublic ? proposalCheckboxOptions : myTradingResultsOfferTypes}
                />
            </FormItem>
            <FormItem
                className={'filter-form-item'}
                name={TRADING_RESULT_FILTER_FIELDS.networkPoints}
                labelType={'horizontal'}
                label={'Network points'}
            >
                <SelectInput
                    mode="multiple"
                    name={TRADING_RESULT_FILTER_FIELDS.networkPoints}
                    lazyLoad={formik.initialValues.networkPoints.length > 0}
                    service={loadNetworkPoints}
                    mapItemToOptions={mapListItemToOptions}
                    placeholder={'All'}
                />
            </FormItem>
            {!isPublic &&
                <FormItem
                    className={'filter-form-item'}
                    name={TRADING_RESULT_FILTER_FIELDS.counterparties}
                    labelType={'horizontal'}
                    label={'Counterparty'}
                >
                    <SelectInput
                        mode="multiple"
                        name={TRADING_RESULT_FILTER_FIELDS.counterparties}
                        lazyLoad={formik.initialValues.counterparties && formik.initialValues.counterparties.length > 0}
                        service={loadCompanies}
                        mapItemToOptions={mapListItemToOptions}
                        placeholder={'All'}
                    />
                </FormItem>
            }
            {/* TODO: uncomment when filtering by Capacity category and TSO is needed
            <FormItem
                className={'filter-form-item'}
                name={TRADING_RESULT_FILTER_FIELDS.tsos}
                labelType={'horizontal'}
                label={'TSO'}
            >
                <SelectInput
                    mode="multiple"
                    name={TRADING_RESULT_FILTER_FIELDS.tsos}
                    service={loadTsos}
                    lazyLoad={formik.initialValues.tsos.length > 0}
                    mapItemToOptions={mapListItemToOptions}
                    placeholder={'All'}
                />
            </FormItem>
            {isPublic &&
                <FormItem
                    className={'filter-form-item'}
                    name={TRADING_RESULT_FILTER_FIELDS.capacityCategories}
                    labelType={'horizontal'}
                    label={'Capacity categories'}
                >
                    <SelectInput
                        mode="multiple"
                        name={TRADING_RESULT_FILTER_FIELDS.capacityCategories}
                        service={loadCapacityCategories}
                        lazyLoad={formik.initialValues.capacityCategories
                                && formik.initialValues.capacityCategories.length > 0
                        }
                        mapItemToOptions={mapListItemToOptions}
                        placeholder={'All'}
                    />
                </FormItem>
            }
            */}
            <FormItem
                className={'filter-form-item'}
                name={TRADING_RESULT_FILTER_FIELDS.createdAt}
                labelType={'horizontal'}
                label={'Creation time'}
            >
                <CalendarRangeInput
                    name={TRADING_RESULT_FILTER_FIELDS.createdAt}
                    customFormat={dateFormat}
                />
            </FormItem>
            <FormItem
                className={'filter-form-item'}
                name={TRADING_RESULT_FILTER_FIELDS.period}
                labelType={'horizontal'}
                label={'Period'}
            >
                <CalendarRangeInput name={TRADING_RESULT_FILTER_FIELDS.period}/>
            </FormItem>
            {/* TODO: uncomment when filtering by Procedure is needed
            <FormItem
                className={'filter-form-item'}
                name={TRADING_RESULT_FILTER_FIELDS.procedures}
                labelType={'horizontal'}
                label={'Procedure'}
            >
                <SelectInput
                    mode="multiple"
                    name={TRADING_RESULT_FILTER_FIELDS.procedures}
                    lazyLoad={formik.initialValues.procedures.length > 0}
                    service={loadProcedures}
                    mapItemToOptions={mapListItemToOptions}
                    placeholder={'All'}
                />
            </FormItem>
            */}
        </Col>;
    }

    return <Form>
        <Row gutter={64}>
            {renderFilterFields()}
            {renderSliders()}
        </Row>
        <SubmitButton className='filter-button' disabled={disableButtons}>
            Filter
        </SubmitButton>
        <Button type="default" onClick={handleFilterReset} disabled={disableButtons}>
            Reset filters
        </Button>
    </Form>;

};

import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../../redux/store';
import { TradeProposalInformation } from '../../../../../components/text/trade-proposal-information';
import { TradingResultDetails } from '../../../../../api/model/trading-result-details';
import { NotificationType, notify } from '../../../../../service/notification-service';
import { tradingResultService } from '../../../../../api/trading-result-service';
import { invoiceService } from '../../../../../api/invoice-service';
import { TradingResultSettlementInformation } from '../../../../../api/model/trading-result-settlement-information';
import { SettlementInformation } from '../../../../../components/text/settlement-information';
import { TableComponent } from '../../../../../components/table/table';
import { invoicesColumns } from './invoices-columns';
import { InvoiceModel } from '../../../../../api/model/invoice-model';

import './../../../trade-proposals/trade-proposal-details/trade-proposal-details-page.less';

export interface TradeProposalDetailsProps {
    tradingResultId?: string;
}

const MyTradingResultsDetailsPageComponent: React.FC<RouteComponentProps<TradeProposalDetailsProps>> = (
    props: RouteComponentProps<TradeProposalDetailsProps>
) => {

    const {
        match: {
            params: {
                tradingResultId
            }
        }
    } = props;

    const [isLoading, setLoading] = useState<boolean>(true);
    const [tradingResult, setTradingResult] = useState<TradingResultDetails>();
    const [settlementInformation, setSettlementInformation] = useState<TradingResultSettlementInformation>();
    const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
    const [settlementInformationLoading, setSettlementInformationLoading] = useState<boolean>(false);
    const [invoicesLoading, setInvoicesLoading] = useState<boolean>(false);

    const authentication = useSelector((state: RootState) => state.authentication);
    const selectedCompany = authentication.selectedCompany;
    const isOwnProposal = tradingResult?.tradeProposal.ownProposal;
    const isOwnResponse = tradingResult?.response.ownResponse;

    useEffect(() => {
        tradingResultId && tradingResultService.getOwnTradingResultDetails(parseInt(tradingResultId), selectedCompany?.id).then((res) => {
            setTradingResult(res);
            setLoading(false);
            if (res.response.ownResponse || res.tradeProposal.ownProposal) {
                setSettlementInformationLoading(true);
                setInvoicesLoading(true);
                selectedCompany && tradingResultService.getTradingResultSettlementInformation(
                    selectedCompany?.id,
                    parseInt(tradingResultId)
                )
                    .then((data: TradingResultSettlementInformation) => {
                        setSettlementInformation(data);
                        setSettlementInformationLoading(false);
                    })
                    .catch(() => {
                        notify({
                            type: NotificationType.ERROR,
                            message: 'Could not load settlement information'
                        });
                        setSettlementInformationLoading(false);
                    });
                invoiceService.getInvoices(parseInt(tradingResultId))
                    .then((data: InvoiceModel[]) => {
                        setInvoices(data);
                        setInvoicesLoading(false);
                    })
                    .catch(() => {
                        notify({
                            type: NotificationType.ERROR,
                            message: 'Could not load invoice information'
                        });
                        setInvoicesLoading(false);
                    });
            }
        }).catch(() => {
            notify({
                type: NotificationType.ERROR,
                message: 'Could not load trading result details'
            });
        });
    }, [tradingResultId]);

    return <>
        <Row justify="center">
            <Col xs={8} className="trade-proposal-details-column">
                <TradeProposalInformation
                    loading={isLoading || settlementInformationLoading}
                    tradeProposal={tradingResult?.tradeProposal}
                    showCancel={false}
                    response={tradingResult?.response}
                    tradingResultId={tradingResult?.tradingResultId}
                />
            </Col>
            {(isOwnProposal || isOwnResponse) &&
                <Col xs={8}>
                    <SettlementInformation
                        loading={isLoading || settlementInformationLoading}
                        settlementInformation={settlementInformation}
                    />
                </Col>
            }
        </Row>
        {!settlementInformationLoading && !isLoading &&
            <Row justify="center" className="invoices-row">
                <Col xs={17}>
                    <TableComponent
                        pagination={false}
                        data={invoices}
                        columns={invoicesColumns}
                        loading={invoicesLoading}
                    />
                </Col>
            </Row>
        }
    </>;
};

const myTradingResultsDetailsPage = withRouter(MyTradingResultsDetailsPageComponent);

export { myTradingResultsDetailsPage };

import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Row } from 'antd';
import { NotificationType, notify } from '../../../../service/notification-service';
import { tradingResultService } from '../../../../api/trading-result-service';
import { TradingResultReportDetails } from '../../../../api/model/trading-result-report-details';
import { TradingResultReportInformation } from './trading-result-report-information';

import './../../trade-proposals/trade-proposal-details/trade-proposal-details-page.less';

export interface TradeProposalDetailsProps {
    tradingResultId?: string;
}

const TradingResultReportDetailsPageComponent: React.FC<RouteComponentProps<TradeProposalDetailsProps>> = (
    props: RouteComponentProps<TradeProposalDetailsProps>
) => {

    const {
        match: {
            params: {
                tradingResultId
            }
        }
    } = props;

    const [isLoading, setLoading] = useState<boolean>(true);
    const [tradingResult, setTradingResult] = useState<TradingResultReportDetails>();

    useEffect(() => {
        tradingResultId && tradingResultService.getTradingResultReportDetails(parseInt(tradingResultId)).then((res) => {
            setTradingResult(res);
            setLoading(false);
        }).catch(() => {
            notify({
                type: NotificationType.ERROR,
                message: 'Could not load trading result details'
            });
        });
    }, [tradingResultId]);

    return <Row justify="center">
        <Col xs={8} className="trade-proposal-details-column">
            <TradingResultReportInformation
                loading={isLoading}
                tradingResult={tradingResult}
            />
        </Col>
    </Row>;
};

const tradingResultReportDetailsPage = withRouter(TradingResultReportDetailsPageComponent);

export { tradingResultReportDetailsPage };

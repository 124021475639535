import React from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { Moment } from 'moment';
import { DatePicker } from 'antd';
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker';

import { FormikFieldProps } from '../common/field-props';
import { dateTimeFormat, gasDayDateFormat } from '../../../config/constants';

export type CalendarInputProps = FormikFieldProps & PickerDateProps<Moment>;

type Props = CalendarInputProps;

const CalendarInput: React.FC<Props> = props => {
    const {
        name,
        disabled,
        disableOnSubmit,
        disabledDate,
        showTime,
        ...restProps
    } = props;

    const formik = useFormikContext();

    const handleChange = (moment: Moment | null) => {
        formik.setFieldValue(name, moment);
        formik.setFieldTouched(name, true, false);
    };

    return (
        <Field name={name}>
            {({ field: { value }, form: { isSubmitting } }: FieldProps) => (
                <DatePicker
                    {...restProps}
                    name={name}
                    disabledDate={disabledDate}
                    value={value}
                    onChange={handleChange}
                    disabled={disabled || (disableOnSubmit && isSubmitting)}
                    showTime={showTime}
                    format={showTime ? dateTimeFormat : gasDayDateFormat}
                    placeholder={showTime ? dateTimeFormat : gasDayDateFormat}
                />
            )}
        </Field>
    );
};

export { CalendarInput };

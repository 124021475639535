import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Modal, Row } from 'antd';
import { WarningOutlined } from '@ant-design/icons/lib';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { AxiosError } from 'axios';

import { tradeProposalService } from '../../../../api/trade-proposal-service';
import { TradeProposalDetails } from '../../../../api/model/trade-proposal-details';
import { NotificationType, notify } from '../../../../service/notification-service';
import { PeriodType } from '../new-trade-proposal/form/new-trade-proposal-schema';
import { RootState } from '../../../../redux/store';
import { AccessRight, TradeProposalStatus } from '../../../../config/constants';
import { CreateResponseForm } from './form/response-form';
import { getDurationType } from '../../../../components/inputs/calendar-input/duration-input';
import { responseService } from '../../../../api/response-service';
import { RESPONSE_FORM_SCHEMA, ResponseFormModel } from './form/response-form-schema';
import { navigationService } from '../../../../service/navigation-service';
import { ResponseModel } from '../../../../api/model/response-model';
import { TradeProposalInformation } from '../../../../components/text/trade-proposal-information';
import { ErrorType, getErrorMessage } from '../../../../utils/error-utils';

import './trade-proposal-details-page.less';

export interface TradeProposalDetailsProps {
    id?: string;
    responseId?: string;
}

const TradeProposalDetailsPageComponent: React.FC<RouteComponentProps<TradeProposalDetailsProps>> = (
    props: RouteComponentProps<TradeProposalDetailsProps>
) => {

    const {
        match: {
            params: {
                id,
                responseId
            }
        }
    } = props;

    const [isLoading, setLoading] = useState<boolean>(true);
    const [tradeProposal, setTradeProposal] = useState<TradeProposalDetails>();
    const [isDisabled, setDisabled] = useState<boolean>(false);
    const [responseLoading, setResponseLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<ResponseModel | undefined>(undefined);
    const [responseSchema, setResponseSchema] = useState<Yup.ObjectSchema | undefined>(undefined);

    const authentication = useSelector((state: RootState) => state.authentication);
    const selectedCompany = authentication.selectedCompany;
    const isOwnProposal = tradeProposal !== undefined && tradeProposal.ownProposal;
    const selectTradersListVisible = response?.ownResponse
        || tradeProposal?.status === TradeProposalStatus.ACTIVE;
    const isFullControlUser = selectedCompany?.role === AccessRight.FULL_CONTROL_USER;

    const isInTradersList = tradeProposal && selectedCompany && (tradeProposal.tradersList
        ? tradeProposal.tradersList.includes(selectedCompany?.id)
        : true
    );

    const initialValues: ResponseFormModel | {} = tradeProposal ? {
        capacity: tradeProposal.capacityAmount,
        periodFrom: moment(tradeProposal.dateFrom).set({ h: 7, m: 0 }),
        periodTo: tradeProposal.periodType === PeriodType.FULL
            ? moment(tradeProposal.dateUntil).set({ h: 7, m: 0 })
            : moment(tradeProposal.dateFrom).set({ h: 7, m: 0 }).add(
                tradeProposal.minimumPeriod,
                getDurationType(tradeProposal?.durationType)
            ).set({ h: 7, m: 0 })
    } : {};

    useEffect(() => {
        id && tradeProposalService.getTradeProposalDetails(parseInt(id), selectedCompany?.id).then((res) => {
            setTradeProposal(res);
            setResponseSchema(
                RESPONSE_FORM_SCHEMA(res.minimumPeriod, res.durationType, res.minimumCapacity, res.capacityAmount)
            );
            setLoading(false);
            if (res.ownProposal
                && res.status === TradeProposalStatus.RESPONSE_RECEIVED
                && selectedCompany?.id
            ) {
                setResponseLoading(true);
                responseService.getResponse(res.proposalId, selectedCompany.id)
                    .then((data: ResponseModel) => {
                        setResponse(data);
                        setDisabled(true);
                        setResponseLoading(false);
                    })
                    .catch(() => {
                        notify({
                            type: NotificationType.ERROR,
                            message: 'Could not load response details'
                        });
                    });
            } else if (responseId !== undefined
                && selectedCompany?.id !== undefined
            ) {
                setResponseLoading(true);
                res.ownProposal
                    ? setResponseLoading(false)
                    : responseService.getResponseDetails(parseInt(responseId), selectedCompany.id)
                        .then((data: ResponseModel) => {
                            setResponse(data);
                            setDisabled(true);
                            setResponseLoading(false);
                        })
                        .catch(() => {
                            notify({
                                type: NotificationType.ERROR,
                                message: 'Could not load response details'
                            });
                        });
            }
        }).catch(() => {
            notify({
                type: NotificationType.ERROR,
                message: 'Could not load trade proposal details'
            });
        });
    }, [id, responseId]);

    function cancelTrade() {
        selectedCompany && tradeProposal &&
        tradeProposalService.cancelTrade(selectedCompany.id, tradeProposal.proposalId)
            .then(() => {
                notify({
                    type: NotificationType.SUCCESS,
                    message: 'Proposal cancelled successfully'
                });
                navigationService.goToMyTradeProposalsPath();
            }).catch(() => {
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not cancel proposal'
                });
            });
    }

    function handleCancel() {
        Modal.confirm({
            icon: <WarningOutlined/>,
            className: 'cancel-trade-modal',
            title: 'Cancel trade proposal?',
            content: 'Are you sure you want to cancel this trade proposal?',
            centered: true,
            cancelText: 'No',
            okText: 'Yes',
            maskClosable: true,
            onOk: cancelTrade,
            okButtonProps: { danger: true }
        });
    }

    function handleAcceptResponse() {
        tradeProposal && responseService.answerResponse(true, tradeProposal.proposalId)
            .then(() => {
                navigationService.goToMyTradeProposalsPath();
                notify({
                    type: NotificationType.SUCCESS,
                    message: 'Response accepted'
                });
            })
            .catch(() => {
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not accept response'
                });
            });
    }

    function handleRejectResponse() {
        tradeProposal && responseService.answerResponse(false, tradeProposal.proposalId)
            .then(() => {
                navigationService.goToMyTradeProposalsPath();
                notify({
                    type: NotificationType.SUCCESS,
                    message: 'Response rejected'
                });
            })
            .catch(() => {
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not reject response'
                });
            });
    }

    const getErrorDescription = (error: AxiosError) => {
        switch (getErrorMessage(error).errorType) {
            case ErrorType.COUNTERPARTY_NOT_IN_TRADERS_LIST:
                return 'Counterparty is not included in selected traders list.';
            case ErrorType.NOT_ENOUGH_ACTIVE_COMPANIES:
                return 'Traders list cannot be applied. It has less than 3 active companies';
            default:
                return '';
        }
    };

    function handleSubmit(values: ResponseFormModel, formik: FormikHelpers<ResponseFormModel>) {
        id && selectedCompany && responseService.createResponse({
            capacity: values.capacity,
            periodFrom: values.periodFrom ? values.periodFrom.toISOString() : undefined,
            periodTo: values.periodTo ? values.periodTo.toISOString() : undefined,
            tradersList: values.tradersList ? values.tradersList : undefined
        }, parseInt(id), selectedCompany.id)
            .then(() => {
                notify({
                    type: NotificationType.SUCCESS,
                    message: 'Response created successfully'
                });
                navigationService.goToTradeProposalsPath();
                formik.setSubmitting(false);
            })
            .catch((error: AxiosError) => {
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not create response',
                    description: getErrorDescription(error)
                });
                formik.setSubmitting(false);
            });
    }

    return <Row justify="center">
        <Col xs={8} className="trade-proposal-details-column">
            <TradeProposalInformation
                loading={isLoading || responseLoading}
                tradeProposal={tradeProposal}
                showCancel={isOwnProposal && isFullControlUser && tradeProposal?.status === TradeProposalStatus.ACTIVE}
                ownProposal={isOwnProposal}
                handleCancel={handleCancel}
            />
        </Col>
        {isFullControlUser
        && ((!isOwnProposal && isInTradersList)
            || (isOwnProposal && response && tradeProposal?.status === TradeProposalStatus.RESPONSE_RECEIVED))
        && tradeProposal
        && !responseLoading &&
            <Col xs={8}>
                <Formik
                    initialValues={!response ? initialValues : {
                        periodFrom: moment(response.periodFrom),
                        periodTo: moment(response.periodTo),
                        capacity: response.capacity,
                        tradersList: response.tradersList
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={responseSchema}
                    enableReinitialize={true}
                >
                    <CreateResponseForm
                        isPendingResponse={isOwnProposal && response !== undefined}
                        maxCapacity={tradeProposal?.capacityAmount}
                        minCapacity={tradeProposal?.minimumCapacity}
                        maxPeriod={tradeProposal?.dateUntil}
                        minPeriod={tradeProposal?.dateFrom}
                        minAcceptablePeriod={tradeProposal.minimumPeriod}
                        durationType={tradeProposal.durationType}
                        createdAt={response?.createdAt}
                        showSubmit={tradeProposal?.status === TradeProposalStatus.ACTIVE && !isDisabled && !responseId}
                        disabled={tradeProposal?.periodType === PeriodType.FULL || isDisabled}
                        disabledCapacity={!tradeProposal?.minimumCapacity}
                        selectTradersListVisible={selectTradersListVisible}
                        handleAccept={handleAcceptResponse}
                        handleReject={handleRejectResponse}
                    />
                </Formik>
            </Col>
        }
    </Row>;
};

const tradeProposalDetailsPage = withRouter(TradeProposalDetailsPageComponent);

export { tradeProposalDetailsPage };

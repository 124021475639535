import React from 'react';
import { Radio } from 'antd';
import { Field, FieldProps, useFormikContext } from 'formik';

import { FormikFieldProps } from '../common/field-props';
import { RadioChangeEvent } from 'antd/es/radio';

import './radio-buttons.less';

interface OwnProps {
    disabled?: boolean;
    options: ToggleButtonOption[];
    onChange?: ((value: string | number, setFieldValue: any, setFieldTouched: any) => void) | null;
}

export interface ToggleButtonOption {
    label: string;
    value: string | number;
}

export type ToggleButtonProps = FormikFieldProps & OwnProps;

type Props = ToggleButtonProps;

const RadioButtonsInput: React.FC<Props> = props => {
    const {
        name,
        disabled,
        disableOnSubmit,
        onChange
    } = props;

    const formik = useFormikContext();

    const handleChange = (event: RadioChangeEvent) => {
        formik.setFieldValue(name, event.target.value);
        formik.setFieldTouched(name, true);
        if (onChange !== null && onChange) {
            onChange(event.target.value, formik.setFieldValue, formik.setFieldTouched);
        }
    };

    function getOptions(): React.ReactNode[] {
        const {
            options
        } = props;

        return options.map(option => (
            <Radio
                key={option.value}
                value={option.value}
            >
                {option.label}
            </Radio>
        ));
    }

    return (
        <Field name={name}>
            {({ field: { value }, form: { isSubmitting } }: FieldProps) => (
                <React.Fragment>
                    <Radio.Group
                        value={value}
                        disabled={disabled || (disableOnSubmit && isSubmitting)}
                        onChange={handleChange}
                        className={'radio-buttons'}
                        buttonStyle={'solid'}
                    >
                        {getOptions()}
                    </Radio.Group>
                </React.Fragment>
            )}
        </Field>
    );
};

export { RadioButtonsInput };

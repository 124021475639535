import React from 'react';
import { Col, Row, Skeleton, Tag, Typography } from 'antd';
import moment from 'moment';

import { TradeProposalReportDetails } from '../../../../api/model/trade-proposal-report-details';
import { LabeledText } from '../../../../components/text/labeled-text';
import { PeriodType, ProposalType } from '../../trade-proposals/new-trade-proposal/form/new-trade-proposal-schema';
import {
    capacityUnit,
    dateTimeFormat,
    priceUnit,
    displayUpdatedStatusesIn,
    TradeProposalStatusNameMap
} from '../../../../config/constants';

export interface TradeProposalReportInformationProps {
    loading: boolean;
    tradeProposal?: TradeProposalReportDetails;
}

export const TradeProposalReportInformation = (props: TradeProposalReportInformationProps) => {
    const {
        tradeProposal,
        loading,
    } = props;
    const tradersListInformation = tradeProposal?.tradersList
        && tradeProposal.tradersList.name + ' (' + tradeProposal.tradersList.companies.join(', ') + ')';

    return <Skeleton
        loading={loading}
        active={loading}
        paragraph={{ rows: 10 }}
    >
        <Row className={'proposal-info-wrapper'}>
            <Tag
                className={`proposal-type-tag ${tradeProposal?.proposalType === ProposalType.BUY
                    ? 'request'
                    : 'offer'}`
                }
            >
                {tradeProposal?.proposalType === ProposalType.BUY ? 'Request' : 'Offer'}
            </Tag>
            <Typography.Text className={'network-point'}>
                {tradeProposal?.networkPointName}
            </Typography.Text>
        </Row>
        <Row className={'proposal-details'}>
            <Typography.Title level={4} className={'details-header'}>
                Proposal details
            </Typography.Title>
            <Col xs={24}>
                <LabeledText
                    value={tradeProposal?.proposalId.toString()}
                    label={'Trade proposal ID'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={tradeProposal?.companyName}
                    label={'Company name'}
                    labelAlign={'right'}
                />
                {/*<LabeledText  // TODO: uncomment if needed, delete Price */}
                {/*    value={`${tradeProposal?.tariffPrice}    ${priceUnit}`}*/}
                {/*    label={'Tariff price'}*/}
                {/*    labelAlign={'right'}*/}
                {/*/>*/}
                {/*{tradeProposal?.surcharge !== null &&*/}
                {/*    <LabeledText*/}
                {/*        value={`${tradeProposal?.surcharge} ${priceUnit}`}*/}
                {/*        label={'Surcharge'}*/}
                {/*        labelAlign={'right'}*/}
                {/*    />*/}
                {/*}*/}
                {/*{tradeProposal?.surcharge !== null &&*/}
                {/*    <LabeledText*/}
                {/*        value={`${tradeProposal?.totalPrice} ${priceUnit}`}*/}
                {/*        label={'Price'}*/}
                {/*        labelAlign={'right'}*/}
                {/*    />*/}
                {/*}*/}
                <LabeledText
                    value={`${tradeProposal?.totalPrice} ${priceUnit}`}
                    label={'Price'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={`${tradeProposal?.capacityAmount} ${capacityUnit}`}
                    label={'Capacity amount'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={`${moment(tradeProposal?.dateFrom).format(dateTimeFormat)}
                                - ${moment(tradeProposal?.dateUntil).format(dateTimeFormat)}`}
                    label={'Period'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={moment(tradeProposal?.creationDate).format(dateTimeFormat)}
                    label={'Creation time'}
                    labelAlign={'right'}
                />
                {tradeProposal?.tradersList &&
                    <LabeledText
                        value={tradersListInformation}
                        label={'Applied traders list'}
                        labelAlign={'right'}
                    />
                }
                {tradeProposal?.lastUpdatedAt && displayUpdatedStatusesIn.includes(tradeProposal.status) &&
                    <LabeledText
                        value={moment(tradeProposal?.lastUpdatedAt).format(dateTimeFormat)}
                        label={'Last status change'}
                        labelAlign={'right'}
                    />
                }
            </Col>
        </Row>
        <Row className={'proposal-details'}>
            <Typography.Title level={4} className={'details-header'}>
                Trading conditions
            </Typography.Title>
            <Col xs={24}>
                <LabeledText
                    value={tradeProposal && TradeProposalStatusNameMap[tradeProposal.status]}
                    label={'Status'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={tradeProposal?.procedure}
                    label={'Trading procedure'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={tradeProposal?.periodType === PeriodType.PARTIAL
                        ? `${tradeProposal?.minimumPeriod} ${tradeProposal?.durationType.toLowerCase()}`
                        : 'Full period'}
                    label={'Minimum period'}
                    labelAlign={'right'}
                />
                {tradeProposal?.minimumCapacity !== undefined && tradeProposal.minimumCapacity > 0 &&
                    <LabeledText
                        value={`${tradeProposal?.minimumCapacity} ${capacityUnit}`}
                        label={'Minimum capacity'}
                        labelAlign={'right'}
                    />
                }
                {tradeProposal?.periodType === PeriodType.PARTIAL &&
                    <LabeledText
                        value={tradeProposal?.partialAutoAcceptable ? 'Yes' : 'No'}
                        label={'Accept partial period fulfilment automatically'}
                        labelAlign={'right'}
                    />
                }
                <LabeledText
                    value={moment(tradeProposal?.expirationDate).format(dateTimeFormat)}
                    label={'Expiration date'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={tradeProposal?.capacityCategoryName}
                    label={'Capacity category'}
                    labelAlign={'right'}
                />
            </Col>
        </Row>
    </Skeleton>;
};

import React from 'react';
import { Formik, FormikHelpers } from 'formik';

import { LOGIN_FORM_FIELDS, LoginSchema } from './login-schema';
import { Form } from '../../../../components/inputs/common/form';
import { FormItem } from '../../../../components/inputs/common/form-item';
import { TextInput } from '../../../../components/inputs/text-input/text-input';
import { PasswordInput } from '../../../../components/inputs/text-input/password-input';
import { LoginFormModel } from './login-form-model';
import { SubmitButton } from '../../../../components/button/submit-button';
import { Button } from 'antd';
import { navigationService } from '../../../../service/navigation-service';
import ReCAPTCHA from 'react-google-recaptcha';
import { captchaSiteKey } from '../../../../config/constants';

import './login-form.less';

interface OwnProps {
    isLoading?: boolean;
    initialValues: LoginFormModel;
    handleSubmit: (
        values: LoginFormModel,
        actions: FormikHelpers<LoginFormModel>
    ) => void;
}

type Props = OwnProps;

const LoginForm: React.FC<Props> = (props: Props) => {
    const {
        initialValues,
        handleSubmit,
        isLoading
    } = props;

    const captchaRef: React.Ref<any> = React.useRef<ReCAPTCHA>();

    const renderForm = (): React.ReactNode => {
        return (
            <Form>
                <FormItem label="Email" name={LOGIN_FORM_FIELDS.email}>
                    <TextInput
                        name={LOGIN_FORM_FIELDS.email}
                        disabled={isLoading}
                    />
                </FormItem>
                <FormItem label="Password" name={LOGIN_FORM_FIELDS.password} className="password-field">
                    <PasswordInput
                        name={LOGIN_FORM_FIELDS.password}
                        disabled={isLoading}
                    />
                </FormItem>
                <Button
                    type="link"
                    onClick={() => navigationService.goToForgotPasswordPage()}
                    className="forgot-password"
                >
                    Forgot password?
                </Button>
                <div className="recaptcha">
                    <ReCAPTCHA
                        ref={captchaRef}
                        sitekey={captchaSiteKey}
                        size="invisible"
                    />
                </div>
                <SubmitButton className="login-button">
                    Login
                </SubmitButton>
            </Form>
        );
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                actions.setSubmitting(false);
                captchaRef.current.executeAsync().then((token: string) => {
                    actions.setSubmitting(true);
                    handleSubmit({ ...values, recaptcha: token }, actions);
                    captchaRef.current.reset();
                });
            }}
            validationSchema={LoginSchema}
        >
            {renderForm()}
        </Formik>
    );
};

export { LoginForm };

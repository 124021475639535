import React, { useEffect, useState } from 'react';
import { FormWizard } from '../../../components/wizard/form-wizard';
import { getCompanyRegistrationSteps } from './form/company-registration-steps';
import { Button, Col, Modal, Row, Spin, Typography } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { companyService } from '../../../api/company-service';
import { CompanyResponse } from '../../../api/model/company-response';
import { NotificationType, notify } from '../../../service/notification-service';
import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';

import './form/company-registration-form.less';

interface CompanyRegistrationPageRouteProps {
    id?: string;
}

const CompanyRegistrationPageComponent: React.FC<RouteComponentProps<CompanyRegistrationPageRouteProps>> =
    (props: RouteComponentProps<CompanyRegistrationPageRouteProps>) => {

        const {
            match: {
                params: {
                    id
                }
            }
        } = props;

        const [isLoading, setLoading] = useState<boolean>(true);
        const [company, setCompany] = useState<CompanyResponse>();
        const [isDisabled, setDisabled] = useState<boolean>(false);

        useEffect(() => {
            id ? companyService.getCompany(parseInt(id))
                .then(response => {
                    setCompany(response);
                    setDisabled(response.disabled);
                    setLoading(false);
                })
                .catch(() => {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Could not load company'
                    });
                    setLoading(false);
                })
                : setLoading(false);
        }, [id]);

        function handleClickDisable() {
            Modal.confirm({
                title: 'Disable all users?',
                className: 'disable-users-modal',
                icon: <WarningOutlined className="disable-users-warning"/>,
                content: `This will disable all users of ${company?.name}. Are you sure?`,
                centered: true,
                cancelText: 'No',
                okText: 'Yes',
                maskClosable: true,
                onOk: () => company && id && companyService.disableAllCompanyUsers(parseInt(id))
                    .then(() => {
                        setDisabled(true);
                        notify({
                            type: NotificationType.SUCCESS,
                            message: 'Users disabled successfully'
                        });
                    })
                    .catch(() => {
                        notify({
                            type: NotificationType.ERROR,
                            message: 'Could not disable users'
                        });
                    })
            });
        }

        function handleClickEnable() {
            Modal.confirm({
                title: 'Enable company?',
                className: 'disable-users-modal',
                icon: <WarningOutlined className="disable-users-warning"/>,
                content: `This will enable company ${company?.name}. Are you sure?`,
                centered: true,
                cancelText: 'No',
                okText: 'Yes',
                maskClosable: true,
                onOk: () => company && id && companyService.enableCompany(parseInt(id))
                    .then(() => {
                        setDisabled(false);
                        notify({
                            type: NotificationType.SUCCESS,
                            message: 'Company enabled successfully'
                        });
                    })
                    .catch(() => {
                        notify({
                            type: NotificationType.ERROR,
                            message: 'Could not enable company'
                        });
                    })
            });
        }

        return (
            <Spin spinning={isLoading} indicator={<LoadingOutlined/>} size="large">
                <Row justify="center">
                    <Col xs={14}>
                        <Row justify="space-between">
                            <Col>
                                <Typography.Title level={4}>
                                    Company Registration
                                </Typography.Title>
                            </Col>
                            <Col>
                                {company && company.tso.length > 0 && (isDisabled
                                    ? <Button onClick={handleClickEnable} type="link" className="enable-company-button">
                                        Enable company
                                    </Button>
                                    : <Button onClick={handleClickDisable} type="link" className="disable-users-button">
                                        Disable all users
                                    </Button>
                                )}
                            </Col>
                        </Row>
                        <FormWizard
                            steps={getCompanyRegistrationSteps(setCompany, company, parseInt(id as string))}
                            isStepsClickable={company !== undefined}
                        />
                    </Col>
                </Row>
            </Spin>
        );
    };

const CompanyRegistrationPage = withRouter(CompanyRegistrationPageComponent);

export { CompanyRegistrationPage };

import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { TablePaginationConfig } from 'antd/lib/table';
import { FormikHelpers } from 'formik';
import { ArrayParam, DateTimeParam, NumberParam, useQueryParams } from 'use-query-params';

import { navigationService } from '../../../../service/navigation-service';
import { NotificationType, notify } from '../../../../service/notification-service';
import { responseService } from '../../../../api/response-service';
import { ResponseReport } from '../../../../api/model/response-report';
import { PageResponse } from '../../../../api/page-response';
import { ResponseStatus } from '../../../../config/constants';
import { RecordType, TableComponent } from '../../../../components/table/table';
import { TradeProposalsTableHeader } from '../../../../components/table/trade-proposals-table-header';
import { DownloadMenu } from '../../../../components/menu/download-menu';
import {
    getInitialDate,
    mapResponseReportsFormValuesToUrlParams,
    mapResponseReportsUrlParamsToFilterModel,
    parseQueryParamArrayAsNumber
} from '../../../../utils/query-param-utils';
import { ProposalType } from '../../trade-proposals/new-trade-proposal/form/new-trade-proposal-schema';
import {
    CAPACITY_RANGE_MIN,
    TOTAL_PRICE_MIN,
    ResponseReportsFilterFormModel
} from './filter/response-reports-filter-form-model';
import { ResponseReportsFilterForm } from './filter/response-reports-filter-form';
import { responseReportsColumns } from './table/response-reports-columns';

const PAGE_SIZE = 20;

export const responseReportsQueryParams = {
    networkPoints: ArrayParam,
    dateFrom: DateTimeParam,
    companies: ArrayParam,
    dateTo: DateTimeParam,
    offerTypes: ArrayParam,
    capacityFrom: NumberParam,
    capacityTo: NumberParam,
    totalPriceFrom: NumberParam,
    totalPriceTo: NumberParam,
    statuses: ArrayParam,
    submittedFrom: DateTimeParam,
    submittedTo: DateTimeParam
};

export const ResponsesReportsPage: React.FC = () => {
    const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
    const [responses, setResponses] = useState<PageResponse<ResponseReport>>({
        content: [],
        number: 0,
        totalElements: 0
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [sorts, setSorts] = useState<string | string[]>([]);
    const [currentPage, setCurrentPage] = useState<number>( 0 );
    const [maxPrice, setMaxPrice] = useState<number>(0);
    const [maxCapacity, setMaxCapacity] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);

    const [urlParams, setUrlParams] = useQueryParams(responseReportsQueryParams);

    const initialValues: ResponseReportsFilterFormModel = {
        networkPoints: urlParams.networkPoints ? parseQueryParamArrayAsNumber(urlParams.networkPoints) : [],
        companies: urlParams.companies ? parseQueryParamArrayAsNumber(urlParams.companies) : [],
        period: [getInitialDate(urlParams.dateFrom), getInitialDate(urlParams.dateTo)],
        submittedAt: [getInitialDate(urlParams.submittedFrom), getInitialDate(urlParams.submittedTo)],
        offerTypes: urlParams.offerTypes
            ? urlParams.offerTypes.filter((value): value is ProposalType => value !== null)
            : [ProposalType.SELL, ProposalType.BUY],
        capacityRange: [
            urlParams.capacityFrom ? urlParams.capacityFrom : CAPACITY_RANGE_MIN,
            urlParams.capacityTo ? urlParams.capacityTo : maxCapacity
        ],
        totalPriceRange: [
            urlParams.totalPriceFrom ? urlParams.totalPriceFrom : TOTAL_PRICE_MIN,
            urlParams.totalPriceTo ? urlParams.totalPriceTo : maxPrice
        ],
        statuses: urlParams.statuses
            ? urlParams.statuses.filter((value): value is ResponseStatus => value !== null)
            : []
    };

    useEffect(() => {
        setIsLoading(true);
        responseService.getMaximumFilterValues()
            .then(response => {
                setMaxPrice(response.price);
                setMaxCapacity(response.capacityAmount);
                responseService.getResponseReports(
                    sorts ?? undefined,
                    currentPage ?? 0,
                    pageSize,
                    mapResponseReportsUrlParamsToFilterModel(urlParams, response.capacityAmount, response.price)
                ).then(res => {
                    setResponses(res);
                    setIsLoading(false);
                }).catch(() => {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Could not load responses'
                    });
                });
            }).catch(() => {
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not load filter data'
                });
            });
    }, [currentPage, sorts, urlParams, pageSize]);

    function handleReset() {
        const mappedParams = {
            networkPoints: undefined,
            capacityFrom: CAPACITY_RANGE_MIN,
            capacityTo: maxCapacity,
            offerTypes: undefined,
            totalPriceFrom: TOTAL_PRICE_MIN,
            totalPriceTo: maxPrice,
            dateFrom: undefined,
            dateTo: undefined,
            submittedFrom: undefined,
            submittedTo: undefined,
            statuses: undefined,
            companies: undefined
        };

        setUrlParams(mappedParams);
    }

    function handleSubmit(
        values: ResponseReportsFilterFormModel,
        formik: FormikHelpers<ResponseReportsFilterFormModel>
    ) {
        formik.setSubmitting(true);
        const mappedParams = mapResponseReportsFormValuesToUrlParams(values);

        setUrlParams(mappedParams);
        setCurrentPage(0);
        formik.setSubmitting(false);
    }

    function handleTableChange(
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<RecordType<ResponseReport>>
                | SorterResult<RecordType<ResponseReport>>[]
    ) {
        const sort = Array.isArray(sorter)
            ? sorter.map(s => s.order ? `${s.field},${s.order?.replace('end', '')}` : '')
            : sorter.order ? `${sorter.field},${sorter.order?.replace('end', '')}` : '';

        setSorts(sort);
        setCurrentPage(pagination.current ? pagination.current - 1 : 0);
        pagination.pageSize && setPageSize(pagination.pageSize);
    }

    function handleDownloadClick() {
        responseService.downloadReports(
            sorts,
            mapResponseReportsUrlParamsToFilterModel(urlParams, maxCapacity, maxPrice)
        ).catch(() => {
            notify({
                type: NotificationType.ERROR,
                message: 'Could not download responses'
            });
        });
    }

    function renderTable(): React.ReactNode {
        return <TableComponent
            filter={{
                form: <ResponseReportsFilterForm
                    handleReset={handleReset}
                    maxCapacity={maxCapacity}
                    maxPrice={maxPrice}
                    disableButtons={isLoading}
                />,
                initialValues,
                handleSubmit,
                filtersVisible
            }}
            data={responses.content}
            columns={responseReportsColumns}
            loading={isLoading}
            onTableChange={handleTableChange}
            pagination={{
                total: !responses ? 1 : responses.totalElements,
                current: !responses ? 1 : responses.number + 1,
                pageSize: pageSize
            }}
            onRowClick={(record: ResponseReport) => {
                return { onClick: () => navigationService.goToResponseReportDetailsPath(record.id) };
            }}
        />;
    }

    return (
        <Row justify="center">
            <Col xs={20}>
                <Row justify="space-between" gutter={[64, 24]}>
                    <TradeProposalsTableHeader
                        onCheckboxChange={setFiltersVisible}
                        title={'Responses'}
                        showCreateButton={false}
                    />
                </Row>
                <Row gutter={[64, 24]}>
                    <Col xs={24}>
                        {renderTable()}
                    </Col>
                </Row>
                <Row justify="end">
                    <Col xs={4}>
                        <DownloadMenu onDownload={handleDownloadClick}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

import React from 'react';
import { ColumnProps } from 'antd/es/table';
import Avatar from 'antd/lib/avatar/avatar';
import moment from 'moment';

import { ProposalType } from '../../../trade-proposals/new-trade-proposal/form/new-trade-proposal-schema';
import { TradingResultReport } from '../../../../../api/model/trading-result-report';
import { capacityUnit, dateTimeFormat, priceUnit } from '../../../../../config/constants';

export const tradingResultsReportsColumns: ColumnProps<TradingResultReport>[] = [
    {
        title: 'Type',
        dataIndex: 'proposalType',
        className: 'table-column',
        render: (proposalType: ProposalType) => {
            return proposalType === ProposalType.SELL
                ? <Avatar className='offer'> O </Avatar>
                : <Avatar className='request'> R </Avatar>;
        },
        sorter: {
            compare: () => { return 0; },
            multiple: 2
        },
        showSorterTooltip: false
    },
    {
        title: 'Initiating shipper',
        dataIndex: 'initiatingShipperName',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 3
        },
        showSorterTooltip: false
    },
    {
        title: 'Responding shipper',
        dataIndex: 'respondingShipperName',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 4
        },
        showSorterTooltip: false
    },
    {
        title: 'Network point',
        dataIndex: 'networkPointName',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 5
        },
        showSorterTooltip: false
    },
    {
        title: 'Amount',
        dataIndex: 'capacityAmount',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 6
        },
        showSorterTooltip: false,
        render: (amount: number) => `${amount} ${capacityUnit}`
    },
    {
        title: 'Period',
        dataIndex: 'dateFrom',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 7
        },
        showSorterTooltip: false,
        render: (dateFrom: string, tradingResult: TradingResultReport) =>
            `${moment(dateFrom).format(dateTimeFormat)} - ${moment(tradingResult.dateTo).format(dateTimeFormat)}`
    },
    {
        title: 'Price',
        dataIndex: 'totalPrice',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 8
        },
        showSorterTooltip: false,
        render: (totalPrice: number) => `${totalPrice} ${priceUnit}`
    },
    {
        title: 'Creation time',
        dataIndex: 'createdAt',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 9
        },
        showSorterTooltip: false,
        render: (createdAt: string) =>
            `${moment(createdAt).format(dateTimeFormat)}`
    }
];

import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons/lib';
import { useSelector } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import _ from 'lodash';

import { AccessRight } from '../../../../config/constants';
import { RootState } from '../../../../redux/store';
import { NotificationType, notify } from '../../../../service/notification-service';
import { companyService } from '../../../../api/company-service';
import { tradersListService } from '../../../../api/traders-list-service';
import { TradersListDetailsModel } from '../../../../api/model/traders-list-details-model';
import { CompanyListItem } from '../../../../api/model/company-list-item';
import { Form } from '../../../../components/inputs/common/form';
import { FormItem } from '../../../../components/inputs/common/form-item';
import { SubmitButton } from '../../../../components/button/submit-button';
import { SelectInput, SelectOptionModel } from '../../../../components/inputs/select-input/select-input';
import { TextInput } from '../../../../components/inputs/text-input/text-input';
import { UnsavedChangesPrompt } from '../../../../components/prompt/unsaved-changes-prompt';
import { TradersListCard } from './traders-list-card';
import { TradersListRequestModel } from './traders-list-request-model';
import { TRADERS_LIST_FORM_FIELDS, tradersListFormSchema } from './traders-list-form-schema';

import './traders-list-form.less';

export const TradersListForm = () => {
    const authentication = useSelector((state: RootState) => state.authentication);
    const selectedCompanyId = authentication.selectedCompany?.id;
    const isFullControlUser: boolean = authentication.selectedCompany?.role === AccessRight.FULL_CONTROL_USER;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [allCompanies, setAllCompanies] = useState<CompanyListItem[]>([]);
    const [tradersLists, setTradersLists] = useState<TradersListDetailsModel[]>([]);
    const [selectedTradersList, setSelectedTradersList] = useState<TradersListDetailsModel | null>(null);
    const initialValues: TradersListRequestModel = {
        name: selectedTradersList ? selectedTradersList.name : '',
        companyIds: selectedTradersList ? selectedTradersList.companies.map(c => c.id) : []
    };

    const loadTradersLists = () => {
        setIsLoading(true);
        selectedCompanyId && tradersListService.getAllTradersListsDetails(selectedCompanyId)
            .then((res: TradersListDetailsModel[]) => {
                setTradersLists(res);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not load traders lists'
                });
            });
    };

    const loadCompanies = () => {
        companyService.getCompanies()
            .then(companies => {
                setAllCompanies(companies);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not load companies'
                });
            });
    };

    useEffect(loadTradersLists, []);

    const handleEdit = (id: number) => {
        setShowForm(true);
        setIsLoading(true);
        selectedCompanyId && tradersListService.getTradersList(selectedCompanyId, id)
            .then(list => {
                setSelectedTradersList(list);
                loadCompanies();
            })
            .catch(() => {
                setIsLoading(false);
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not load traders list'
                });
            });
    };

    const handleSubmit = (values: TradersListRequestModel, formikHelpers: FormikHelpers<TradersListRequestModel>) => {
        selectedCompanyId && (selectedTradersList
            ? tradersListService.updateTradersList(selectedCompanyId, selectedTradersList.id, values)
                .then(() => {
                    setShowForm(false);
                    setSelectedTradersList(null);
                    loadTradersLists();
                    notify({
                        type: NotificationType.SUCCESS,
                        message: 'Traders list updated successfully'
                    });
                    formikHelpers.setSubmitting(false);
                })
                .catch(() => {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Could not update traders list'
                    });
                })
            : tradersListService.createTradersList(selectedCompanyId, values)
                .then(() => {
                    setShowForm(false);
                    setSelectedTradersList(null);
                    loadTradersLists();
                    notify({
                        type: NotificationType.SUCCESS,
                        message: 'Traders list created successfully'
                    });
                    formikHelpers.setSubmitting(false);
                })
                .catch(() => {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Could not create traders list'
                    });
                }));
    };

    function mapListItemToSelectOption(listItem: CompanyListItem): SelectOptionModel {
        return {
            value: listItem.id,
            label: listItem.name,
            disabled: listItem.disabled
        };
    }

    const renderForm = (values: TradersListRequestModel, isSubmitting: boolean): React.ReactNode => {
        return (
            <Form className="traders-list-form">
                <FormItem name={TRADERS_LIST_FORM_FIELDS.name} label="Traders list name" labelType='horizontal'>
                    <TextInput name={TRADERS_LIST_FORM_FIELDS.name} disableOnSubmit={true}/>
                </FormItem>
                <FormItem name={TRADERS_LIST_FORM_FIELDS.companyIds} label="Companies" labelType="horizontal">
                    <SelectInput
                        name={TRADERS_LIST_FORM_FIELDS.companyIds}
                        mode="multiple"
                        options={allCompanies.filter(c => c.id !== selectedCompanyId).map(mapListItemToSelectOption)}
                    />
                </FormItem>
                {!isLoading &&
                    <Row justify="end">
                        <SubmitButton>
                            {selectedTradersList ? 'Update traders list' : 'Create traders list'}
                        </SubmitButton>
                    </Row>
                }
                <UnsavedChangesPrompt
                    when={!_.isEqual(values, initialValues) && !isSubmitting}
                    message={'You have unsaved changes, are you sure you want to leave this page?'}
                />
            </Form>
        );
    };

    const renderTradersLists = (): React.ReactNode => {
        return (
            <React.Fragment>
                <Row gutter={[16, 16]}>
                    {tradersLists && tradersLists.map(t =>
                        <Col key={t.id} xs={12}>
                            <TradersListCard tradersList={t} readOnly={!isFullControlUser} onEdit={handleEdit}/>
                        </Col>
                    )}
                </Row>
                {!isLoading && isFullControlUser &&
                    <Button
                        type={'primary'}
                        block={true}
                        onClick={() => { setShowForm(true); loadCompanies(); }}
                    >
                        + New traders list
                    </Button>
                }
            </React.Fragment>
        );
    };

    return <Spin spinning={isLoading} indicator={<LoadingOutlined/>} size="large">
        {showForm
            ? <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={tradersListFormSchema}
            >
                {({ values, isSubmitting }) => renderForm(values, isSubmitting)}
            </Formik>
            : renderTradersLists()
        }
    </Spin>;
};

import React, { ChangeEvent } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';

import { FormikFieldProps } from '../common/field-props';

import './text-input.less';

export type TextInputProps = FormikFieldProps & InputProps;

type Props = TextInputProps;

const TextInput: React.FC<Props> = props => {
    const {
        name,
        disabled,
        disableOnSubmit,
        ...restProps
    } = props;

    const formik = useFormikContext();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        formik.handleChange(event);
    };

    const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
        formik.handleBlur(event);
    };

    return (
        <Field name={name}>
            {({ field: { value }, form: { isSubmitting } }: FieldProps) => (
                <Input
                    {...restProps}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disabled || (disableOnSubmit && isSubmitting)}
                    className="text-input"
                />
            )}
        </Field>
    );
};

export { TextInput };

import { AuthenticationActions, AuthenticationState } from './types';
import { CompanyRole } from '../../api/model/login-response';

export function userLogin(newState: AuthenticationState) {
    return {
        type: AuthenticationActions.LOGIN,
        payload: newState
    };
}

export function userLogout() {
    return {
        type: AuthenticationActions.LOGOUT
    };
}

export function updateCompanies(newState: { companyRoleMap: CompanyRole[] | undefined; selectedCompany: CompanyRole | undefined }) {
    return {
        type: AuthenticationActions.UPDATE_COMPANIES,
        payload: newState
    };
}

export function updateSelectedCompany( newState: { selectedCompany: CompanyRole | undefined } ) {
    return {
        type: AuthenticationActions.UPDATE_SELECTED_COMPANY,
        payload: newState
    };
}
import * as Yup from 'yup';
import moment, { Moment } from 'moment';
import { Validations } from '../../../../../components/util/validation-utils';
import { DurationType, getDurationType } from '../../../../../components/inputs/calendar-input/duration-input';

export interface ResponseFormModel {
    capacity?: number;
    periodFrom?: Moment;
    periodTo?: Moment;
    tradersList?: number;
}

export const RESPONSE_FORM_FIELDS = {
    capacity: 'capacity',
    periodFrom: 'periodFrom',
    periodTo: 'periodTo',
    tradersList: 'tradersList'
};

export const RESPONSE_FORM_SCHEMA = (minimumPeriod: number, durationType: DurationType, minimumCapacity: number,
    capacity: number) => Yup.object().shape({
    [RESPONSE_FORM_FIELDS.periodTo]: Yup.object()
        .transform(value => !value ? undefined : value)
        .test(
            'isValidPeriod',
            Validations.periodCannotBeShorterThanMinimum,
            function (value: Moment) {
                const dateFrom = moment(this.parent.periodFrom).add(
                    minimumPeriod,
                    getDurationType(durationType)
                );
                const dateTo = moment(value);

                return dateFrom.isSameOrBefore(dateTo);
            }
        )
        .nullable(false)
        .required(Validations.required),
    [RESPONSE_FORM_FIELDS.periodFrom]: Yup.object()
        .transform(value => !value ? undefined : value)
        .nullable(false)
        .test(
            'isValidPeriod',
            Validations.periodCannotBeShorterThanMinimum,
            function (value: Moment) {
                const dateFrom = moment(value).add(
                    minimumPeriod,
                    getDurationType(durationType)
                );
                const dateTo = moment(this.parent.periodTo);

                return dateFrom.isSameOrBefore(dateTo);
            }
        )
        .required(Validations.required),
    [RESPONSE_FORM_FIELDS.capacity]: Yup.number()
        .transform(value => (!value && value !== 0) ? undefined : value)
        .min(minimumCapacity, 'Capacity amount cannot be lower than minimum capacity')
        .max(capacity, 'Capacity cannot be greater than trade proposal capacity amount')
        .integer(Validations.onlyInteger)
        .nullable(false)
        .required(Validations.required),
    [RESPONSE_FORM_FIELDS.tradersList]: Yup.number()
        .nullable(true)
});

import { ProposalType } from '../../trade-proposals/new-trade-proposal/form/new-trade-proposal-schema';
import { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

export enum TRADING_RESULT_FILTER_FIELDS {
    offerTypes = 'offerTypes',
    capacityCategories = 'capacityCategories',
    networkPoints = 'networkPoints',
    counterparties = 'counterparties',
    tsos = 'tsos',
    procedures = 'procedures',
    period = 'period',
    createdAt = 'createdAt',
    capacityRange = 'capacityRange',
    totalPriceRange = 'totalPriceRange'
}

export interface TradingResultsFilterFormModel {
    offerTypes: ProposalType[];
    capacityCategories?: number[];
    networkPoints: number[];
    counterparties?: number[];
    tsos: number[];
    procedures: string[];
    period: RangeValue<Moment>;
    createdAt: RangeValue<Moment>;
    capacityRange: [number, number];
    totalPriceRange: [number, number];
}

export const CAPACITY_RANGE_MIN = 0;
export const TOTAL_PRICE_MIN = 0;

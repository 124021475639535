import { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { ProposalType } from '../../../trade-proposals/new-trade-proposal/form/new-trade-proposal-schema';
import { TradeProposalStatus } from '../../../../../config/constants';

export enum TRADE_PROPOSAL_REPORT_FILTER_FIELDS {
    offerTypes = 'offerTypes',
    networkPoints = 'networkPoints',
    tsos = 'tsos',
    period = 'period',
    capacityRange = 'capacityRange',
    totalPriceRange = 'totalPriceRange',
    tradeProposalStatuses = 'tradeProposalStatuses',
    submittedAt = 'submittedAt',
    companies = 'companies'
}

export interface TradeProposalReportsFilterFormModel {
    offerTypes: ProposalType[];
    networkPoints: number[];
    tsos: number[];
    period: RangeValue<Moment>;
    capacityRange: [number, number];
    totalPriceRange: [number, number];
    tradeProposalStatuses?: TradeProposalStatus[];
    submittedAt: RangeValue<Moment>;
    companies: number[];
}

export const CAPACITY_RANGE_MIN = 0;
export const TOTAL_PRICE_MIN = 0;

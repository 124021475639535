import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

export interface DownloadMenuProps {
    onDownload: () => void;
}

export const DownloadMenu: React.FC<DownloadMenuProps> = (props: DownloadMenuProps) => {
    const {
        onDownload
    } = props;

    const menu = () => <Menu>
        <Menu.Item onClick={onDownload} key=".xlsx">
            <span>
            .xlsx
            </span>
        </Menu.Item>
    </Menu>;

    return <Dropdown className="download-menu" overlay={menu}>
        <Button type="link">
            Download <DownOutlined/>
        </Button>
    </Dropdown>;
};

import React, { ChangeEvent } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';

import { FormikFieldProps } from '../common/field-props';

import './number-input.less';

interface OwnProps {
    unit?: string;
}

export type NumberInputProps = FormikFieldProps & InputNumberProps & OwnProps;

type Props = NumberInputProps;

const NumberInput: React.FC<Props> = props => {
    const {
        name,
        disabled,
        disableOnSubmit,
        className,
        unit,
        ...restProps
    } = props;

    const formik = useFormikContext();

    const handleChange = (value: number | string | undefined) => {
        formik.setFieldValue(name, value);
    };

    const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
        formik.handleBlur(event);
    };

    return (
        <>
            <Field name={name}>
                {({ field: { value }, form: { isSubmitting } }: FieldProps) => (
                    <InputNumber
                        {...restProps}
                        name={name}
                        value={value}
                        min={0}
                        className={className}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disabled || (disableOnSubmit && isSubmitting)}
                    />
                )}
            </Field>
            <span className="unit">{unit}</span>
        </>
    );
};

export { NumberInput };

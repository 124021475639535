import * as Yup from 'yup';
import { Moment } from 'moment';

export const LOGS_FORM_FIELDS = {
    dateFrom: 'dateFrom',
    dateUntil: 'dateUntil'
};

export const LogsSchema = Yup.object().shape({
    [LOGS_FORM_FIELDS.dateFrom]: Yup.object()
        .nullable(true),
    [LOGS_FORM_FIELDS.dateUntil]: Yup.object()
        .nullable(true)
});

export interface LogsFormModel {
    dateFrom?: Moment | undefined;
    dateUntil?: Moment | undefined;
}

import * as Yup from 'yup';
import { Validations } from '../../../../components/util/validation-utils';

export const CHANGE_PASSWORD_FORM_FIELDS = {
    currentPassword: 'currentPassword',
    newPassword: 'newPassword',
    repeatPassword: 'repeatPassword'
};

const PASSWORD_MIN = 8;
const PASSWORD_MAX = 50;

export const ChangePasswordSchema = Yup.object().shape({
    [CHANGE_PASSWORD_FORM_FIELDS.currentPassword]: Yup.string()
        .max(PASSWORD_MAX, Validations.maxLength(PASSWORD_MAX))
        .min(PASSWORD_MIN, Validations.minLength(PASSWORD_MIN))
        .matches(Validations.regex.passwordRegex, Validations.passwordValidation)
        .required(Validations.required),
    [CHANGE_PASSWORD_FORM_FIELDS.newPassword]: Yup.string()
        .oneOf([Yup.ref(CHANGE_PASSWORD_FORM_FIELDS.repeatPassword)], Validations.passwordsMustMatch)
        .max(PASSWORD_MAX, Validations.maxLength(PASSWORD_MAX))
        .min(PASSWORD_MIN, Validations.minLength(PASSWORD_MIN))
        .notOneOf([Yup.ref(CHANGE_PASSWORD_FORM_FIELDS.currentPassword)], Validations.passwordCannotBeSame)
        .matches(Validations.regex.passwordRegex, Validations.passwordValidation)
        .required(Validations.required),
    [CHANGE_PASSWORD_FORM_FIELDS.repeatPassword]: Yup.string()
        .oneOf([Yup.ref(CHANGE_PASSWORD_FORM_FIELDS.newPassword)], Validations.passwordsMustMatch)
        .max(PASSWORD_MAX, Validations.maxLength(PASSWORD_MAX))
        .min(PASSWORD_MIN, Validations.minLength(PASSWORD_MIN))
        .matches(Validations.regex.passwordRegex, Validations.passwordValidation)
        .required(Validations.required)
});

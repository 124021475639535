import { Button, Checkbox, Col, Typography } from 'antd';
import { navigationService } from '../../service/navigation-service';
import React from 'react';

export interface TradeProposalsTableHeaderProps {
    onCheckboxChange: (checked: boolean) => void;
    title: string;
    showCreateButton: boolean;
}

export const TradeProposalsTableHeader: React.FC<TradeProposalsTableHeaderProps>
    = (props: TradeProposalsTableHeaderProps) => {

        const {
            onCheckboxChange,
            title,
            showCreateButton
        } = props;

        return <>
            <Col xs={12}>
                <Typography.Title level={4}>
                    {title}
                </Typography.Title>
            </Col>
            <Col xs={12} className="additional-buttons">
                <Checkbox
                    className="filters-box"
                    onChange={(checked) => onCheckboxChange(checked.target.checked)}
                >
                    Show filters
                </Checkbox>
                {showCreateButton &&
                    <Button type="primary" onClick={navigationService.goToCreateTradeProposalPath}>
                        Create proposal
                    </Button>
                }
            </Col>
        </>;
    };
import { CancelSource } from './cancel-source';
import { RestService } from './rest-service';
import { NewTradeProposalRequest } from './model/new-trade-proposal-request';
import { TradeProposalResponse } from './model/trade-proposal-response';
import { stringify } from 'query-string';
import { PageResponse } from './page-response';
import { TradeProposal } from '../pages/private/trade-proposals/table/trade-proposals-columns';
import { TradeProposalFilterModel } from './model/trade-proposal-filter-model';
import { TradeProposalDetails } from './model/trade-proposal-details';
import { TradeProposalReport } from './model/trade-proposal-report';
import { TradeProposalReportsFilterModel } from './model/trade-proposal-reports-filter-model';
import { TradeProposalReportDetails } from './model/trade-proposal-report-details';
import { downloadFile } from '../utils/file-utils';
import { FilterMaxValuesModel } from './model/filter-max-values-model';

class TradeProposalService {
    private readonly restService: RestService;

    constructor(cancelSource: CancelSource = new CancelSource()) {
        this.restService = cancelSource.service;
    }

    public readonly getMaximumFilterValues = (): Promise<FilterMaxValuesModel> => {
        return this.restService.get<FilterMaxValuesModel>('/tradeProposals/getMaxFilterValues')
            .then(response => response.data);
    };

    public readonly getMaximumFilterValuesByCompany = (companyId: number): Promise<FilterMaxValuesModel> => {
        return this.restService.get<FilterMaxValuesModel>(`/tradeProposals/${companyId}/getMaxFilterValues`)
            .then(response => response.data);
    };

    public readonly getMaximumFilterValuesReports = (): Promise<FilterMaxValuesModel> => {
        return this.restService.get<FilterMaxValuesModel>('/tradeProposals/reports/getMaxFilterValues')
            .then(response => response.data);
    };

    public readonly getAllProcedures = (): Promise<string[]> => {
        return this.restService.get<string[]>('/tradeProposals/procedures')
            .then(response => response.data);
    };

    public readonly getTradeProposal = (id: number): Promise<TradeProposalResponse> => {
        return this.restService.get<TradeProposalResponse>(`/tradeProposal/${id}`)
            .then(response => response.data);
    };

    public readonly getTradeProposalDetails = (
        id: number,
        selectedCompanyId?: number
    ): Promise<TradeProposalDetails> => {
        return this.restService.get<TradeProposalDetails>
        (`/tradeProposal/details/${id}`,
            { params: { selectedCompanyId } })
            .then(response => response.data);
    };

    public readonly getTradeProposals = (
        sorter?: string | string[],
        page?: number,
        size?: number,
        filters?: TradeProposalFilterModel,
        selectedCompanyId?: number
    ): Promise<PageResponse<TradeProposal>> => {
        const requestParams = {
            selectedCompanyId,
            page,
            size,
            sort: sorter,
        };

        return this.restService.post<PageResponse<TradeProposal>>('/tradeProposals', filters,{
            params: requestParams,
            paramsSerializer: params => stringify(params, { arrayFormat: 'none' })
        }).then(response => response.data);
    };

    public readonly getTradeProposalReports = (
        sorter?: string | string[],
        page?: number,
        size?: number,
        filters?: TradeProposalReportsFilterModel
    ): Promise<PageResponse<TradeProposalReport>> => {
        const requestParams = {
            page,
            size,
            sort: sorter
        };

        return this.restService.post<PageResponse<TradeProposalReport>>('/tradeProposals/getReports', filters,{
            params: requestParams,
            paramsSerializer: params => stringify(params, { arrayFormat: 'none' })
        }).then(response => response.data);
    };

    public readonly getTradeProposalDetailsReport = (tradeProposalId: number): Promise<TradeProposalReportDetails> => {
        return this.restService.get<TradeProposalReportDetails>(`/tradeProposals/getReports/${tradeProposalId}`)
            .then(response => response.data);
    };

    public readonly getTradeProposalsByCompany = (
        companyId: number,
        sorter?: string | string[],
        page?: number,
        size?: number,
        filters?: TradeProposalFilterModel
    ): Promise<PageResponse<TradeProposal>> => {
        const requestParams = {
            page,
            size,
            sort: sorter
        };

        return this.restService.post<PageResponse<TradeProposal>>(`/tradeProposals/${companyId}`, filters,{
            params: requestParams,
            paramsSerializer: params => stringify(params, { arrayFormat: 'none' })
        }).then(response => response.data);
    };

    public readonly createDraft = (companyId: number, model: NewTradeProposalRequest): Promise<void> => {
        return this.restService.post<void>(`/tradeProposal/save/${companyId}`, model)
            .then(response => response.data);
    };

    public readonly createFinal = (companyId: number, model: NewTradeProposalRequest): Promise<void> => {
        return this.restService.post<void>(`/tradeProposal/submit/${companyId}`, model)
            .then(response => response.data);
    };

    public readonly updateDraft = (proposalId: number, companyId: number, model: NewTradeProposalRequest): Promise<TradeProposalResponse> => {
        return this.restService.put<TradeProposalResponse>(`/tradeProposal/${proposalId}/save/${companyId}`, model)
            .then(response => response.data);
    };

    public readonly updateFinal = (proposalId: number, companyId: number, model: NewTradeProposalRequest): Promise<void> => {
        return this.restService.put<void>(`/tradeProposal/${proposalId}/submit/${companyId}`, model)
            .then(response => response.data);
    };

    public readonly downloadPublic = (sorts?: string | string[], filters?: TradeProposalFilterModel): Promise<void> => {
        return this.restService.post<void>('/tradeProposals/download', filters,{
            params: { sort: sorts },
            paramsSerializer: params => stringify(params, { arrayFormat: 'none' }),
            responseType: 'blob'
        }).then(response => downloadFile(response));
    };

    public readonly download = (companyId: number, sorts?: string | string[], filters?: TradeProposalFilterModel): Promise<void> => {
        return this.restService.post<void>(`/tradeProposals/${companyId}/download`, filters,{
            params: { sort: sorts },
            paramsSerializer: params => stringify(params, { arrayFormat: 'none' }),
            responseType: 'blob'
        }).then(response => downloadFile(response));
    };

    public readonly downloadReports = (sorts?: string | string[], filters?: TradeProposalReportsFilterModel): Promise<void> => {
        return this.restService.post<void>('/tradeProposals/getReports/download', filters,{
            params: { sort: sorts },
            paramsSerializer: params => stringify(params, { arrayFormat: 'none' }),
            responseType: 'blob'
        }).then(response => downloadFile(response));
    };

    public readonly cancelTrade = (companyId: number, tradeId: number) => {
        return this.restService.put<void>(`/tradeProposals/${companyId}/${tradeId}`)
            .then(response => response.data);
    };

    public readonly deleteDraft = (draftId: number) => {
        return this.restService.delete(`/tradeProposals/deleteDraft/${draftId}`)
            .then(response => response.data);
    }
}

const tradeProposalService = new TradeProposalService();

export { tradeProposalService, TradeProposalService };

import * as Yup from 'yup';
import { Validations } from '../../../../../components/util/validation-utils';

export const TSO_FORM_FIELDS = {
    TSO: 'TSO'
};

export interface TSOFormModel {
    TSO: number[];
}

export const tsoFormSchema = Yup.object().shape({
    [TSO_FORM_FIELDS.TSO]: Yup.array()
        .required(Validations.required)
        .nullable(false)
});
import * as Yup from 'yup';
import { Validations } from '../../../components/util/validation-utils';

export const CAPACITY_CATEGORY_FORM_FIELDS = {
    name: 'name'
};

const MAX_LENGTH = 255;

export const CapacityCategorySchema = Yup.object().shape({
    [CAPACITY_CATEGORY_FORM_FIELDS.name]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .matches(Validations.regex.letters, Validations.fieldCanOnlyContainLetters)
        .required(Validations.required)
});

import React from 'react';
import { useFormikContext } from 'formik';
import { Button, Col, Row, Select, Typography } from 'antd';

import { navigationService } from '../../../../service/navigation-service';
import { TsoResponse } from '../../../../api/model/tso-response';
import { TsoModel } from '../../../../api/model/tso-model';
import { Form } from '../../../../components/inputs/common/form';
import { SubmitButton } from '../../../../components/button/submit-button';
import { TextInput } from '../../../../components/inputs/text-input/text-input';
import { FormItem } from '../../../../components/inputs/common/form-item';
import { CheckboxInput } from '../../../../components/inputs/checkbox-input/checkbox-input';
import { NumberInput } from '../../../../components/inputs/number-input/number-input';
import { SelectInput, SelectOptionModel } from '../../../../components/inputs/select-input/select-input';
import { TSO_FORM_FIELDS } from './tso-schema';
import { TsoFormModel } from './tso-form-model';
import { UnsavedChangesPrompt } from '../../../../components/prompt/unsaved-changes-prompt';

import './tso-form.less';

interface OwnProps {
    tso?: TsoModel;
    allTsos: TsoResponse[];
    networkPointSelectOptions: SelectOptionModel[];
    capacityCategorySelectOptions: SelectOptionModel[];
    onSelectChange: (tsoId: number) => void;
}

type Props = OwnProps;

export const TsoForm: React.FC<Props> = (props: Props) => {
    const {
        tso,
        allTsos,
        onSelectChange,
        networkPointSelectOptions,
        capacityCategorySelectOptions
    } = props;

    const formik = useFormikContext<TsoFormModel>();

    function renderTsoSelect(): React.ReactNode {
        return <Row className="tso-select" gutter={64}>
            <Col xs={10} className="labeled-input-col">
                <Typography.Text className="labeled-input-text">
                    Select TSO
                </Typography.Text>
            </Col>
            <Col xs={14}>
                {tso?.id &&
                    <Select
                        defaultValue={tso?.id}
                        onChange={value => onSelectChange(value)}
                        disabled={formik.isSubmitting}
                    >
                        {allTsos.map(t => {
                            return <Select.Option value={t.id} key={t.id}>
                                {t.name}
                            </Select.Option>;
                        })}
                    </Select>
                }
            </Col>
        </Row>;
    }

    return <Form className="tso-form">
        {renderTsoSelect()}
        <FormItem label={'TSO name'} name={TSO_FORM_FIELDS.name} labelType="horizontal">
            <TextInput name={TSO_FORM_FIELDS.name} disabled={formik.isSubmitting}/>
        </FormItem>
        <FormItem label={'Network points'} name={TSO_FORM_FIELDS.networkPointIds} labelType="horizontal">
            <SelectInput
                name={TSO_FORM_FIELDS.networkPointIds}
                options={networkPointSelectOptions}
                mode="multiple"
                placeholder="Select from the list"
                disabled={formik.isSubmitting}
            />
        </FormItem>
        <FormItem label={'Capacity category'} name={TSO_FORM_FIELDS.capacityCategoryIds} labelType="horizontal">
            <SelectInput
                name={TSO_FORM_FIELDS.capacityCategoryIds}
                options={capacityCategorySelectOptions}
                mode="multiple"
                placeholder="Select from the list"
                disabled={formik.isSubmitting}
            />
        </FormItem>
        <Col offset={11}>
            <Button
                type="link"
                onClick={navigationService.goToAddCapacityCategoryPath}
                className={'additional-category-button'}>
                + Add new capacity category
            </Button>
        </Col>
        <FormItem label={'Trade proposal submission expiration time (before new gas trade day)'}
            name={TSO_FORM_FIELDS.expirationTime}
            labelType="horizontal">
            <NumberInput
                name={TSO_FORM_FIELDS.expirationTime}
                disabled={formik.isSubmitting}
                unit="hours"/>
        </FormItem>
        <FormItem label={'Duration of TSO trade validation'}
            name={TSO_FORM_FIELDS.validationTime}
            labelType="horizontal"
            infoText="If no value will be added validation time will be unlimited">
            <NumberInput
                name={TSO_FORM_FIELDS.validationTime}
                disabled={formik.isSubmitting}
                unit="hours"/>
        </FormItem>
        <FormItem label={'Traders list'} name={TSO_FORM_FIELDS.canApplyTradersList} labelType="horizontal">
            <CheckboxInput
                name={TSO_FORM_FIELDS.canApplyTradersList}
                disabled={formik.isSubmitting}>
                    Apply
            </CheckboxInput>
        </FormItem>
        <Row justify="end">
            <Col>
                <SubmitButton disabled={formik.isSubmitting}>Save</SubmitButton>
            </Col>
        </Row>
        <UnsavedChangesPrompt
            when={formik.values !== formik.initialValues && !formik.isSubmitting}
            message={'You have unsaved changes, are you sure you want to leave this page?'}
        />
    </Form>;
};

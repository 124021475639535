import { notification } from 'antd';
import React from 'react';

export interface NotificationConfig {
    type: NotificationType;
    message: string;
    description?: string;
    icon?: React.ReactNode;
}

export enum NotificationType {
    SUCCESS,
    ERROR,
    WARNING,
    INFO
}

export function notify(config: NotificationConfig): void {
    const {
        message,
        description
    } = config;

    switch (config.type) {
        case NotificationType.ERROR:
            notification.error({
                message,
                description,
                placement: 'bottomRight'
            });
            break;
        case NotificationType.INFO:
            notification.info({
                message,
                description,
                placement: 'bottomRight'
            });
            break;
        case NotificationType.SUCCESS:
            notification.success({
                message,
                description,
                placement: 'bottomRight'
            });
            break;
        case NotificationType.WARNING:
            notification.warning({
                message,
                description,
                placement: 'bottomRight'
            });
            break;
        default:
            break;
    }
}
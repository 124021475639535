class Validations {
    public static regex = {
        letters: /^[A-Za-z ]+$/,
        passwordRegex: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        priceScale: /^\d{1,9}(\.\d{1,6})?$/
    };

    public static required = 'Mandatory field';
    public static invalidEmail = 'Invalid email';
    public static invalidPrice = 'Invalid price';
    public static invalidPeriod = 'Duration must be at least 1 day';
    public static onlyNumbers = 'Field can only contain numbers';
    public static onlyInteger = 'Field value must be an integer number';
    public static onlyDate = 'Field value must be a date';
    public static fieldCanOnlyContainLetters = 'Field can only contain letters';
    public static passwordsMustMatch = 'Passwords must match';
    public static passwordCannotBeSame = 'Password cannot be the same as current password';
    public static passwordValidation = 'Password must contain: at least one upper case letter, at least one lower case letter and at least one number';
    public static periodCannotBeLongerThanMinimum = 'Minimum period cannot be longer than period';
    public static periodCannotBeShorterThanMinimum = 'Period cannot be shorter than minimum period';

    public static minLength(length: number): string {
        return `${length} or more characters is required`;
    }

    public static maxLength(length: number): string {
        return `Less than ${length} characters is required`;
    }

    public static moreThan(moreThan: number): string {
        return `Value must be greater than ${moreThan}`;
    }

    public static lessThan(lessThan: number): string {
        return `Value must be less than ${lessThan}`;
    }

    public static min(min: number): string {
        return `Value must be greater than or equal to ${min}`;
    }

    public static max(max: number): string {
        return `Value must be less than or equal to ${max}`;
    }
}

export { Validations };

import { CancelSource } from './cancel-source';
import { RestService } from './rest-service';
import { ListItem } from './model/list-item';
import { TradersListDetailsModel } from './model/traders-list-details-model';
import { TradersListRequestModel } from '../pages/private/user-settings/traders-list/traders-list-request-model';

class TradersListService {
    private readonly restService: RestService;

    constructor(cancelSource: CancelSource = new CancelSource()) {
        this.restService = cancelSource.service;
    }

    public readonly getAllTradersLists = (companyId: number): Promise<ListItem[]> => {
        return this.restService.get<ListItem[]>(`/tradersList/${companyId}`)
            .then(response => response.data);
    };

    public readonly getAllTradersListsDetails = (companyId: number): Promise<TradersListDetailsModel[]> => {
        return this.restService.get<TradersListDetailsModel[]>(`/tradersList/details/${companyId}`)
            .then(response => response.data);
    };

    public readonly getTradersList = (companyId: number, listId: number): Promise<TradersListDetailsModel> => {
        return this.restService.get<TradersListDetailsModel>(`/tradersList/${companyId}/${listId}`)
            .then(response => response.data);
    };

    public readonly createTradersList = (companyId: number, model: TradersListRequestModel): Promise<void> => {
        return this.restService.post<void>(`/tradersList/${companyId}`, model)
            .then(response => response.data);
    };

    public readonly updateTradersList = (
        companyId: number,
        listId: number,
        model: TradersListRequestModel
    ): Promise<void> => {
        return this.restService.put<void>(`/tradersList/${companyId}/${listId}`, model)
            .then(response => response.data);
    };
}

const tradersListService = new TradersListService();

export { tradersListService, TradersListService };

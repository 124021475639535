import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Spin, Typography } from 'antd';
import { List, ListOption } from '../../../components/list/list';
import { navigationService } from '../../../service/navigation-service';
import { CompanyListItem } from '../../../api/model/company-list-item';
import { companyService } from '../../../api/company-service';
import { NotificationType, notify } from '../../../service/notification-service';
import { LoadingOutlined, LockFilled } from '@ant-design/icons/lib';
import { TableComponent } from '../../../components/table/table';
import { usersColumns } from './table/users-columns';
import { userService } from '../../../api/user-service';
import { UserResponse } from '../../../api/model/user-response';

import './users-management-page.less';

export const UsersManagementPage: React.FC = () => {

    const [companies, setCompanies] = useState<CompanyListItem[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [usersLoading, setUsersLoading] = useState<boolean>(false);
    const [selectedCompanyId, setSelectedCompanyId] = useState<number>();
    const [users, setUsers] = useState<UserResponse[]>([]);

    function getCompanyUsers(id: number) {
        setUsersLoading(true);
        userService.getUsersByCompany(id)
            .then((response: UserResponse[]) => {
                setUsers(response);
                setUsersLoading(false);
            })
            .catch(() => {
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not load users'
                });
                setUsersLoading(false);
            });
    }

    useEffect(() => {
        companyService.getCompanies().then(res => {
            setCompanies(res);
            if (res.length > 0) {
                setSelectedCompanyId(res[0].id);
                getCompanyUsers(res[0].id);
            }
            setLoading(false);
        }).catch(() => {
            notify({
                type: NotificationType.ERROR,
                message: 'Could not load company'
            });
            setLoading(false);
            setUsersLoading(false);
        });
    }, []);

    function handleMoreInfoClick(id: number) {
        navigationService.goToCompanyEditPath(id);
    }

    function mapCompaniesToListOptions(): ListOption[] {
        return companies.map(c => {
            return {
                action: () => {
                    setSelectedCompanyId(c.id);
                    getCompanyUsers(c.id);
                },
                additionalAction: {
                    label: '+ More info',
                    action: () => handleMoreInfoClick(c.id)
                },
                label: <Typography.Text className={'company-name-label'}>
                    {c.name} {c.disabled && <LockFilled/>}
                </Typography.Text>
            };
        });
    }

    return (
        <Spin spinning={isLoading} indicator={<LoadingOutlined/>} size="large">
            <Row justify="center">
                <Col xs={20}>
                    <Row justify="space-between" gutter={24}>
                        <Col xs={12} sm={12}>
                            <Typography.Title level={4}>
                                Users Management
                            </Typography.Title>
                        </Col>
                        {companies.filter(c => c.id === selectedCompanyId && !c.disabled).length > 0 &&
                            <Col xs={12}>
                                <Button
                                    onClick={() => navigationService.goToUserRegistrationPath(selectedCompanyId)}
                                    type="link"
                                    style={{ float: 'right' }}
                                >
                                    + Add user to this company
                                </Button>
                            </Col>
                        }
                    </Row>
                    <Row justify="space-between" gutter={24}>
                        <Col xs={6}>
                            <List options={mapCompaniesToListOptions()}/>
                            <Button
                                onClick={() => navigationService.goToCompanyRegistrationPath()}
                                block={true}
                                className="add-company-button"
                            >
                                + Add company
                            </Button>
                        </Col>
                        <Col xs={18}>
                            <TableComponent data={users} columns={usersColumns} loading={usersLoading}/>
                            {selectedCompanyId &&
                                <Button
                                    type="link"
                                    onClick={() => companyService.downloadApiToken(selectedCompanyId)}>
                                        Download company API token
                                </Button>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Spin>
    );
};

import React, { useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import Spin from 'antd/es/spin';
import { LoadingOutlined } from '@ant-design/icons';
import { Formik, FormikHelpers } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AccessRight } from '../../../config/constants';
import { NotificationType, notify } from '../../../service/notification-service';
import { userService } from '../../../api/user-service';
import { UserRegistrationForm } from './form/user-registration-form';
import { UserRegistrationFormModel } from './form/user-registration-form-model';
import { UserRegistrationSchema } from './form/user-registration-schema';
import { navigationService } from '../../../service/navigation-service';
import { companyService } from '../../../api/company-service';
import { CompanyListItem } from '../../../api/model/company-list-item';

import './form/user-registration-form.less';

export interface UserRegistrationRouteProps {
    userId?: string;
    companyId?: string;
}

export const UserRegistrationPageComponent: React.FC<RouteComponentProps<UserRegistrationRouteProps>>
    = (props: RouteComponentProps<UserRegistrationRouteProps>) => {

        const {
            match: {
                params: {
                    userId,
                    companyId
                }
            }
        } = props;

        const [loading, setLoading] = useState<boolean>(true);
        const [user, setUser] = useState<UserRegistrationFormModel>();
        const [allCompanies, setAllCompanies] = useState<CompanyListItem[]>([]);
        const [company, setCompany] = useState<CompanyListItem>();
        const [selectedUserId, setSeletedUserId] = useState<number | undefined>();

        function getUser(id: number): Promise<UserRegistrationFormModel> {
            return userService.getUser(id);
        }

        useEffect(() => {
            setLoading(true);
            Promise.all([
                userId
                    ? getUser(parseInt(userId))
                    : Promise.resolve(undefined),
                companyService.getCompanies()
            ])
                .then(([userResponse, companies]) => {
                    setUser(userResponse);
                    setAllCompanies(companies ? companies.filter(c => !c.disabled) : []);
                    setSeletedUserId(userId ? parseInt(userId) : undefined);
                    setCompany(companyId && companies
                        ? companies.find(c => c.id === parseInt(companyId))
                        : undefined
                    );
                    setLoading(false);
                })
                .catch(() => {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Could not load data'
                    });
                    setLoading(false);
                });
        }, [companyId, userId]);

        const initialValues: UserRegistrationFormModel = {
            email: user ? user.email : '',
            firstName: user ? user.firstName : '',
            lastName: user ? user.lastName : '',
            position:user ? user.position : '',
            phone: user ? user.phone : '',
            companies: !user && companyId
                ? company
                    ? [
                        {
                            companyId: company?.id,
                            companyName: company && company.name,
                            role: AccessRight.READ_ONLY_USER
                        }
                    ]
                    : []
                : user ? user?.companies : []
        };

        function handleSubmit(values: UserRegistrationFormModel, formikHelpers: FormikHelpers<UserRegistrationFormModel>) {
            formikHelpers.setSubmitting(true);
            if (user && selectedUserId) {
                userService.updateUser(values, selectedUserId).then(() => {
                    notify({
                        type: NotificationType.SUCCESS,
                        message: 'User update successful'
                    });
                    navigationService.goToUsersManagementPath();
                    formikHelpers.setSubmitting(false);
                }).catch(() => {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'User update unsuccessful'
                    });
                    formikHelpers.setSubmitting(false);
                });
            } else {
                userService.registerUser(values).then(() => {
                    notify({
                        type: NotificationType.SUCCESS,
                        message: 'User registration successful'
                    });
                    navigationService.goToUsersManagementPath();
                    formikHelpers.setSubmitting(false);
                }).catch(() => {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'User registration unsuccessful'
                    });
                    formikHelpers.setSubmitting(false);
                });
            }
        }

        function handleUserSelect(id: number | 'NEW_USER') {
            if (id !== 'NEW_USER') {
                setLoading(true);
                getUser(id)
                    .then((userResponse) => {
                        setLoading(false);
                        setUser(userResponse);
                        setSeletedUserId(id);
                    }).catch(() => {
                        notify({
                            type: NotificationType.ERROR,
                            message: 'Could not load user'
                        });
                        setLoading(false);
                    });
            } else {
                setUser(undefined);
            }
        }

        return (
            <Spin spinning={loading} indicator={<LoadingOutlined/>} size="large">
                <Row justify="center">
                    <Col xs={20} sm={16}>
                        <Row gutter={64}>
                            <Col xs={10}>
                                <Typography.Title level={4} className='page-header'>
                                    User Registration
                                </Typography.Title>
                            </Col>
                            <Col xs={24}>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={initialValues}
                                    onSubmit={handleSubmit}
                                    validationSchema={UserRegistrationSchema}
                                >
                                    {!loading &&
                                        <UserRegistrationForm
                                            allCompanies={allCompanies}
                                            selectedUser={selectedUserId}
                                            onSelectUser={handleUserSelect}
                                        />
                                    }
                                </Formik>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Spin>
        );
    };

const UserRegistrationPage = withRouter(UserRegistrationPageComponent);

export { UserRegistrationPage };

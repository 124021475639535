import React from 'react';
import { Layout, Col, Row, Menu, Select, Button, Divider } from 'antd';
import logo from '../../resources/getbaltic_logo_white.png';
import { AuthenticationState } from '../../redux/authentication/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Link, linkList } from './links/link-list';
import SubMenu from 'antd/es/menu/SubMenu';
import { navigationService } from '../../service/navigation-service';
import { authenticationService } from '../../api/authentication-service';
import { updateSelectedCompany, userLogout } from '../../redux/authentication/actions';
import { Role } from '../auth/private-route';
import { setAccessToken } from '../../api/access-token';
import { useLocation } from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';

import './header.less';

const { Header } = Layout;

export const AppHeader: React.FC = () => {
    const authentication: AuthenticationState = useSelector((state: RootState) => state.authentication);
    const location = useLocation();
    const dispatch = useDispatch();

    function checkRoles(link: Link): boolean {
        return link.roles.length === 0
            || (authentication.role !== undefined && link.roles.includes(authentication.role));
    }

    function handleLogout() {
        dispatch(userLogout());
        localStorage.setItem('selectedCompanyId', '');
        setAccessToken('');
        authenticationService.logout();
    }

    function renderCompanyRoleDropdown(originNode: React.ReactNode) {
        return <>
            {originNode}
            <Divider className="divider"/>
            <Button onClick={() => navigationService.goToUserSettingsPath()} type="link" className="logout-button" block={true}>
                Settings
            </Button>
            <Button onClick={handleLogout} type="link" className="logout-button" block={true}>
                Log out
            </Button>
        </>;
    }

    function handleSelect(value: string | number) {
        const selectedCompanyRole = authentication.companyRoleMap
            ? authentication.companyRoleMap?.find(c => c.id === value)
            : undefined;

        dispatch(updateSelectedCompany({
            selectedCompany: selectedCompanyRole
        }));

        localStorage.setItem('selectedCompanyId', selectedCompanyRole?.id.toString() ?? '');

        navigationService.goToTradeProposalsPath();
    }

    function renderSubmenuItem(link: Link): React.ReactNode {
        return <SubMenu title={link.label} key={'submenu' + link.label} >
            {link.subMenu?.map((subLink) => {
                return checkRoles(subLink) &&
                    <Menu.Item
                        key={subLink.path}
                        onClick={() => subLink.to ? subLink.to() : console.log('no link')}
                    >
                        {subLink.label}
                    </Menu.Item>;
            })}
        </SubMenu>;
    }

    function renderMenuItems(): React.ReactNode {
        return linkList.filter(link => checkRoles(link)).map((link) => {
            if(link.subMenu !== undefined) {
                return renderSubmenuItem(link);
            }

            return checkRoles(link) &&
                <Menu.Item
                    key={link.path}
                    onClick={() => link.to ? link.to() : console.log('no link')}
                >
                    {link.label}
                    {link.path === navigationService.MY_TRADE_PROPOSALS_PATH && authentication.selectedCompany?.hasPendingResponses
                        ? <ExclamationCircleFilled className={'proposals-notification'}/>
                        : ''
                    }
                </Menu.Item>;
        });
    }

    function renderCompanyRoleSelect(): React.ReactNode {
        return <Select
            defaultValue={authentication.selectedCompany?.id}
            className="role-select"
            bordered={false}
            onChange={handleSelect}
            dropdownRender={renderCompanyRoleDropdown}
        >
            {authentication.companyRoleMap?.map(company => {
                return <Select.Option value={company.id} key={company.id}>
                    {company.name}
                </Select.Option>;
            })}
        </Select>;
    }

    function renderAdminDropdown() {
        return <>
            <Button onClick={() => navigationService.goToUserSettingsPath()} type="link" className="logout-button" block={true}>
                User settings
            </Button>
            <Button onClick={handleLogout} type="link" className="logout-button" block={true}>
                Log out
            </Button>
        </>;
    }

    function renderAdminSelect(): React.ReactNode {
        return <Select
            value={authentication.firstName + ' ' + authentication.lastName}
            className="role-select"
            bordered={false}
            dropdownRender={renderAdminDropdown}
        />;
    }

    function renderAuthentication() {
        return authentication.role === undefined
            ? location.pathname !== navigationService.LOGIN_PATH &&
                <Menu mode="horizontal" className="app-menu">
                    <Menu.Item
                        onClick={() => navigationService.goToLoginPage()}
                    >
                            Login
                    </Menu.Item>
                </Menu>
            : authentication.role === Role.SYSTEM_ADMIN
                ? renderAdminSelect()
                : renderCompanyRoleSelect();
    }

    return <Header className="app-header">
        <Row justify="center">
            <Col xs={20}>
                <Row justify="space-between">
                    <Col xs={4} lg={5} xl={4}>
                        <Button type="link" className='logo-btn'>
                            <img
                                src={logo}
                                alt="logo"
                                className="logo"
                                onClick={() => navigationService.goToTradeProposalsPath()}
                            />
                        </Button>
                    </Col>
                    <Col className={'links'} xs={12} lg={11} xl={18}>
                        <Menu mode="horizontal" className="app-menu" selectedKeys={[location.pathname]}>
                            {renderMenuItems()}
                        </Menu>
                    </Col>
                    <Col xs={8} lg={8} xl={2} className="authentication-container">
                        {renderAuthentication()}
                    </Col>
                </Row>
            </Col>
        </Row>
    </Header>;
};

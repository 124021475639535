import { ProposalType } from '../../../trade-proposals/new-trade-proposal/form/new-trade-proposal-schema';
import { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

export enum TRADING_RESULT_REPORT_FILTER_FIELDS {
    offerTypes = 'offerTypes',
    networkPoints = 'networkPoints',
    period = 'period',
    capacityRange = 'capacityRange',
    totalPriceRange = 'totalPriceRange',
    createdAt = 'createdAt',
    buyerIds = 'buyerIds',
    sellerIds = 'sellerIds'
}

export interface TradingResultReportsFilterFormModel {
    offerTypes: ProposalType[];
    networkPoints: number[];
    period: RangeValue<Moment>;
    createdAt: RangeValue<Moment>;
    capacityRange: [number, number];
    buyerIds: number[];
    sellerIds: number[];
    totalPriceRange: [number, number];
}

export const CAPACITY_RANGE_MIN = 0;
export const TOTAL_PRICE_MIN = 0;

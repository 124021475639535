import { CancelSource } from './cancel-source';
import { RestService } from './rest-service';
import { LogsRequestModel } from './model/logs-request-model';
import { downloadFile } from '../utils/file-utils';

class LogsService {
    private readonly restService: RestService;

    constructor(cancelSource: CancelSource = new CancelSource()) {
        this.restService = cancelSource.service;
    }

    public readonly getLogs = (logsRequestModel: LogsRequestModel): Promise<void> => {
        return this.restService.get<void>('/logs', {
            params: {
                dateFrom: logsRequestModel.dateFrom,
                dateUntil: logsRequestModel.dateUntil
            },
            responseType: 'blob'
        })
            .then(response => downloadFile(response));
    };
}

const logsService = new LogsService();

export { logsService, LogsService };

import React from 'react';
import { Col, Row, Typography } from 'antd';
import moment from 'moment';

import { ResponseModel } from '../../api/model/response-model';
import { capacityUnit, dateTimeFormat } from '../../config/constants';

export interface ResponseInformationTextProps {
    response: ResponseModel;
}

export const ResponseInformationText: React.FC<ResponseInformationTextProps> = (props: ResponseInformationTextProps) => {
    const {
        response
    } = props;

    return <>
        <Row justify="space-between" gutter={[12, 12]}>
            <Col xs={8}>
                <Typography.Text className="labeled-text">
                    Proposed capacity:
                </Typography.Text>
            </Col>
            <Col xs={16}>
                <Typography.Text className="labeled-text-value">
                    {`${response.capacity} ${capacityUnit}`}
                </Typography.Text>
            </Col>
        </Row>
        <Row justify="space-between" gutter={[12, 12]}>
            <Col xs={8}>
                <Typography.Text className="labeled-text">
                    Proposed period:
                </Typography.Text>
            </Col>
            <Col xs={16}>
                <Typography.Text className="labeled-text-value">
                    {moment(response.periodFrom).format(dateTimeFormat)} - {moment(response.periodTo).format(dateTimeFormat)}
                </Typography.Text>
            </Col>
        </Row>
    </>;
};

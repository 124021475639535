import { CancelSource } from './cancel-source';
import { RestService } from './rest-service';
import { CompanyCreateRequest } from './model/company-create-request';
import { ContactPersonModel } from './model/contact-person-model';
import { TsoRequest } from './model/tso-request';
import { CompanyListItem } from './model/company-list-item';
import { CompanyResponse } from './model/company-response';
import { downloadFile } from '../utils/file-utils';

class CompanyService {
    private readonly restService: RestService;

    constructor(cancelSource: CancelSource = new CancelSource()) {
        this.restService = cancelSource.service;
    }

    public readonly createCompany = (companyCreateRequest: CompanyCreateRequest): Promise<number> => {
        return this.restService.post<number>('/company', companyCreateRequest)
            .then(response => response.data);
    };

    public readonly getCompanies = (): Promise<CompanyListItem[]> => {

        return this.restService.get<CompanyListItem[]>('/companies')
            .then(response => response.data);
    };

    public readonly updateContactPeople = (contactPersonUpdateRequest: ContactPersonModel[], id: number) => {
        return this.restService.put<CompanyResponse>(
            `/company/contact-people/${id}`,
            contactPersonUpdateRequest
        ).then(response => response.data);
    };

    public readonly assignCompanyToTso = (tsoRequest: TsoRequest, id: number) => {
        return this.restService.put<void>(
            `/company/tso/${id}`,
            tsoRequest.tsoIds
        ).then(response => response.data);
    };

    public readonly getCompany = (id: number): Promise<CompanyResponse> => {
        return this.restService.get<CompanyResponse>(`/company/${id}`)
            .then(response => response.data);
    };

    public readonly updateCompany = (id: number, companyCreateRequest: CompanyCreateRequest): Promise<CompanyResponse> => {
        return this.restService.put<CompanyResponse>(`/company/${id}`, companyCreateRequest)
            .then(response => response.data);
    };

    public readonly disableAllCompanyUsers = (id: number): Promise<void> => {
        return this.restService.put<void>(`/company/disableAll/${id}`)
            .then(response => response.data);
    };

    public readonly enableCompany = (id: number): Promise<void> => {
        return this.restService.put<void>(`/company/enable/${id}`)
            .then(response => response.data);
    };

    public readonly downloadApiToken = (companyId: number): Promise<void> => {
        return this.restService.post<void>(`/company/${companyId}/apiToken`)
            .then(response => downloadFile(response));
    };
}

const companyService = new CompanyService();

export { companyService, CompanyService };

import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { FormikHelpers } from 'formik';
import { authenticationService } from '../../../api/authentication-service';
import { NotificationType, notify } from '../../../service/notification-service';
import { ResetPasswordForm } from './form/reset-password-form';
import { ResetPasswordFormModel } from './form/reset-password-form-model';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import { navigationService } from '../../../service/navigation-service';
import { AxiosError } from 'axios';
import { ErrorModel, ErrorType, getErrorMessage } from '../../../utils/error-utils';
import { LoginResponse } from '../../../api/model/login-response';
import { userLogin } from '../../../redux/authentication/actions';
import { Role } from '../../../components/auth/private-route';
import { useDispatch } from 'react-redux';

import './form/reset-password-form.less';

interface ResetPasswordPageComponentProps {
    token: string;
}

const ResetPasswordPageComponent: React.FC<RouteComponentProps<ResetPasswordPageComponentProps>> =
    (props: RouteComponentProps<ResetPasswordPageComponentProps>) => {

        const initialValues: ResetPasswordFormModel = {
            password: '',
            repeatPassword: ''
        };

        const {
            match: {
                params: {
                    token
                }
            }
        } = props;

        const [expired, setExpired] = useState<boolean>(false);
        const dispatch = useDispatch();

        useEffect(() => {
            if (token) {
                const decodedToken: any = jwt.decode(token, { complete: true });

                if (decodedToken && moment.unix(decodedToken.payload.exp).isBefore(moment())) {
                    setExpired(true);
                }
            }
        },[token]);

        const handleSubmit = (values: ResetPasswordFormModel, formikHelpers: FormikHelpers<ResetPasswordFormModel>) => {
            authenticationService.resetPassword({
                ...values,
                token: token
            }).then((response: LoginResponse | '') => {
                notify({
                    type: NotificationType.SUCCESS,
                    message: 'Password changed',
                    description: 'Your password was successfully changed'
                });

                if (response !== '') {
                    dispatch(userLogin({
                        isLoggedIn: true,
                        role: response.isAdmin ? Role.SYSTEM_ADMIN : Role.SYSTEM_USER,
                        firstName: response.firstName,
                        lastName: response.lastName,
                        companyRoleMap: response.companyRoleMap,
                        selectedCompany: response.companyRoleMap ? response.companyRoleMap[0] : undefined
                    }));

                    formikHelpers.setSubmitting(false);
                    navigationService.goToTradeProposalsPath();
                } else {
                    formikHelpers.setSubmitting(false);
                    navigationService.goToLoginPage();
                }

            }).catch((error: AxiosError) => {

                const errorMessage: ErrorModel = getErrorMessage(error);

                if (errorMessage.errorType === ErrorType.PASSWORD_RECENTLY_USED) {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Password was not changed',
                        description: 'Password can not be the same as your last 3 passwords'
                    });
                }

                notify({
                    type: NotificationType.ERROR,
                    message: 'Password was not changed'
                });
            });
            formikHelpers.setSubmitting(false);
        };

        return (
            <Row justify="center" className="login-page">
                <Col xs={20} sm={8}>
                    {expired
                        ? <>
                            <Typography.Text>
                                {'Reset password link expired or invalid. Please request a new link '}
                                <Button type="link" className="try-again-text" onClick={() => navigationService.goToForgotPasswordPage()}>
                                    here
                                </Button>
                            </Typography.Text>
                        </>
                        : <>
                            <Typography.Title level={4}>Reset password</Typography.Title>
                            <ResetPasswordForm
                                initialValues={initialValues}
                                handleSubmit={handleSubmit}
                            />
                        </>
                    }
                </Col>
            </Row>
        );
    };

const resetPasswordPage = withRouter(ResetPasswordPageComponent);

export { resetPasswordPage };

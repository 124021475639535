import React, { HTMLAttributes } from 'react';
import { Col, Row, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/es/table';
import { Key, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { TableFilter, TableFilterProps } from '../filter/table-filter';

import './table.less';

export interface TablePaginationProps {
    total?: number;
    pageSize?: number;
    current?: number;
}

export interface TableProps<T, U, V> {
    data: T[];
    columns: ColumnProps<T>[];
    loading: boolean;
    onTableChange?: (pagination: TablePaginationConfig,
                     filters: Record<string, Key[] | null>,
                     sorter: SorterResult<RecordType<T>> | SorterResult<RecordType<T>>[]) => void;
    pagination?: TablePaginationProps | false;
    rowClassName?: string | ((record: T) => string);
    onRowClick?: (record: T, index?: number) => HTMLAttributes<HTMLElement>;
    filter?: TableFilterProps<U, V>;
}

interface RecordKey {
    key: string;
}

export type RecordType<T> = RecordKey & T;

export const TableComponent = <T, U, V extends {}>(props: TableProps<T, U, V>) => {
    const {
        data,
        columns,
        loading,
        onTableChange,
        rowClassName,
        pagination,
        onRowClick,
        filter
    } = props;

    function getRecords(): RecordType<T>[] {
        return data
            ? data.map((item: any, index: number) => ({
                ...item,
                key: item['id'] || `key-${index}`
            }))
            : [];
    }

    return <>
        {filter &&
            <Row>
                <Col xs={24}>
                    <TableFilter
                        {...filter}
                    />
                </Col>
            </Row>
        }
        <Row gutter={[64, 24]}>
            <Col xs={24}>
                <Table
                    pagination={pagination}
                    dataSource={getRecords()}
                    loading={{ spinning: loading, indicator: <LoadingOutlined/>, size: 'large' }}
                    className="table-component"
                    onChange={onTableChange}
                    rowClassName={rowClassName}
                    onRow={onRowClick}
                >
                    {columns.map(column => <Table.Column key={column.dataIndex ? column.dataIndex.toString() : ''} {...column} />)}
                </Table>
            </Col>
        </Row>
    </>;
};

import * as Yup from 'yup';
import { Validations } from '../../../../components/util/validation-utils';

export const USER_REGISTRATION_FORM_FIELDS = {
    email: 'email',
    firstName: 'firstName',
    lastName: 'lastName',
    position: 'position',
    phone: 'phone',
    companies: 'companies'
};

const MAX_LENGTH = 255;
const MAX_EMAIL_LENGTH = 100;

export const UserRegistrationSchema = Yup.object().shape({
    [USER_REGISTRATION_FORM_FIELDS.email]: Yup.string()
        .email(Validations.invalidEmail)
        .max(MAX_EMAIL_LENGTH, Validations.maxLength(MAX_EMAIL_LENGTH))
        .required(Validations.required),
    [USER_REGISTRATION_FORM_FIELDS.firstName]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .required(Validations.required),
    [USER_REGISTRATION_FORM_FIELDS.lastName]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .required(Validations.required),
    [USER_REGISTRATION_FORM_FIELDS.position]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .required(Validations.required),
    [USER_REGISTRATION_FORM_FIELDS.phone]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .required(Validations.required),
    [USER_REGISTRATION_FORM_FIELDS.companies]: Yup.array()
        .required(Validations.required)
});

import axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig, CancelTokenSource } from 'axios';
import { authenticationService } from './authentication-service';
import { getAccessToken, setAccessToken } from './access-token';
import { navigationService } from '../service/navigation-service';
import { appStore } from '../redux/store';
import { updateCompanies, userLogout } from '../redux/authentication/actions';
import { NotificationType, notify } from '../service/notification-service';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export interface RestServiceConfig {
    cancelTokenSource: CancelTokenSource;
}

const InterceptorIgnoreUrls = [
    '/login',
    '/authentication/refreshToken',
    '/authentication/requestPasswordReset',
    '/authentication/resetPassword',
    '/authentication/confirmLogin'
];

class RestService {
    public post = this.save;
    public put = this.update;

    private readonly axiosInstance: AxiosInstance;
    private readonly cancelTokenSource: CancelTokenSource;

    constructor(config: RestServiceConfig) {
        this.cancelTokenSource = config.cancelTokenSource;
        this.axiosInstance = axios.create({
            baseURL: '/api',
            cancelToken: this.cancelTokenSource.token
        });
        this.configureResponseInterceptor(this.axiosInstance);
        this.configureRequestInterceptor(this.axiosInstance);
    }

    public get<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
        return this.axiosInstance.get(url, config);
    }

    public save<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
        return this.axiosInstance.post(url, data, config);
    }

    public delete(url: string, config?: AxiosRequestConfig): AxiosPromise {
        return this.axiosInstance.delete(url, config);
    }

    public update<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
        return this.axiosInstance.put(url, data, config);
    }

    public patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
        return this.axiosInstance.patch(url, data, config);
    }

    private configureResponseInterceptor = (axiosInstance: AxiosInstance): void => {

        createAuthRefreshInterceptor(axiosInstance,
            this.handleRefreshToken,
            { onRetry: (requestConfig) => ({ ...requestConfig, headers: { authorization: getAccessToken() } }) }
        );

        axiosInstance.interceptors.response.use((response) => {
            if (response.config.url === '/login') {
                setAccessToken(response.data.accessToken);
            }

            return response;
        });
    };

    private configureRequestInterceptor = (axiosInstance: AxiosInstance): void => {
        axiosInstance.interceptors.request.use((config) => {
            if(config.url !== '/login') {
                config.headers.Authorization = getAccessToken();
            }

            if (config.url === '/authentication/logout') {
                setAccessToken('');
                localStorage.setItem('logout', String(Date.now()));
                navigationService.goToLoginPage();
            }

            return config;
        });
    };

    private handleRefreshToken = (failedRequest: AxiosError): Promise<void> => {

        if (failedRequest.config.url && InterceptorIgnoreUrls.includes(failedRequest.config.url)) {
            return Promise.reject(failedRequest);
        }

        const savedSelectedCompany = localStorage.getItem('selectedCompanyId');

        return authenticationService.refreshToken()
            .then(response => {
                setAccessToken(response.accessToken);
                appStore.dispatch(updateCompanies({
                    companyRoleMap: response.companyRoleMap,
                    selectedCompany: response.companyRoleMap
                        ? savedSelectedCompany !== null && savedSelectedCompany !== ''
                            ? response.companyRoleMap[response.companyRoleMap
                                .findIndex(
                                    c => c.id.toString() === localStorage.getItem('selectedCompanyId')
                                )
                            ]
                            : response.companyRoleMap[0]
                        : undefined
                }));
            })
            .catch((e) => {
                setAccessToken('');
                appStore.dispatch(userLogout());
                localStorage.setItem('selectedCompanyId', '');
                navigationService.goToLoginPage();
                notify({
                    type: NotificationType.ERROR,
                    message: 'Session expired, please login again'
                });
                throw e;
            });
    }
}

export { RestService };

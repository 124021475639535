import React, { useEffect, useState } from 'react';

import { Col, Modal, Row, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { WarningOutlined } from '@ant-design/icons/lib';
import { Formik, FormikContextType } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { AxiosError } from 'axios';

import { RootState } from '../../../../redux/store';
import { AuthenticationState } from '../../../../redux/authentication/types';
import { tradeProposalService } from '../../../../api/trade-proposal-service';
import { networkPointService } from '../../../../api/network-point-service';
import { capacityCategoryService } from '../../../../api/capacity-category-service';
import { tradersListService } from '../../../../api/traders-list-service';
import { NotificationType, notify } from '../../../../service/notification-service';
import { TradeProposalResponse } from '../../../../api/model/trade-proposal-response';
import { ListItem } from '../../../../api/model/list-item';
import { NetworkPointListItem } from '../../../../api/model/network-point-list-item';
import { NewTradeProposalForm } from './form/new-trade-proposal-form';
import {
    NewTradeProposalFormModel,
    NewTradeProposalFormSchema,
    PeriodType,
    ProposalType
} from './form/new-trade-proposal-schema';
import { DurationType } from '../../../../components/inputs/calendar-input/duration-input';
import { NewTradeProposalRequest } from '../../../../api/model/new-trade-proposal-request';
import { navigationService } from '../../../../service/navigation-service';
import { AccessRight } from '../../../../config/constants';
import { ErrorType, getErrorMessage } from '../../../../utils/error-utils';

import './form/new-trade-proposal-form.less';

interface NewTradeProposalPageRouteProps {
    id?: string;
}

export const NewTradeProposalPage: React.FC<RouteComponentProps<NewTradeProposalPageRouteProps>> =
    (props: RouteComponentProps<NewTradeProposalPageRouteProps>) => {
        const {
            match: {
                params: {
                    id
                }
            }
        } = props;

        const [isLoading, setLoading] = useState<boolean>(false);
        const [tradeProposal, setTradeProposal] = useState<TradeProposalResponse>();
        const [networkPoints, setNetworkPoints] = useState<NetworkPointListItem[]>();
        const [capacityCategories, setCapacityCategories] = useState<ListItem[]>();
        const [networkPoint, setNetworkPoint] = useState<NetworkPointListItem>();
        const [tradersLists, setTradersLists] = useState<ListItem[]>();
        const authentication: AuthenticationState = useSelector((state: RootState) => state.authentication);

        const loadData = (companyId: number, networkPointId: number) => {
            setLoading(true);
            Promise.all<NetworkPointListItem[], ListItem[]>([
                networkPointService.getNetworkPointsByCompanyId(companyId),
                tradersListService.getAllTradersLists(companyId)
            ])
                .then(([networkPointList, tradersList]) => {
                    const networkPointListItems = networkPointList.filter(n => n.id === networkPointId);

                    if (networkPointListItems.length > 0) {
                        setNetworkPoint(networkPointListItems[0]);
                        capacityCategoryService.getCapacityCategoriesByTsoId(
                            companyId, networkPointListItems[0].tsoId)
                            .then(categories => setCapacityCategories(categories));
                    }
                    setNetworkPoints(networkPointList);
                    setTradersLists(tradersList);
                    setLoading(false);
                });
        };

        useEffect(() => {
            setLoading(true);
            id ? tradeProposalService.getTradeProposal(parseInt(id))
                .then(proposal => {
                    setTradeProposal(proposal);
                    loadData(proposal.companyId, proposal.networkPointId);
                })
                .catch(() => {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Could not load trade proposal'
                    });
                    setLoading(false);
                })
                : setLoading(false);
        }, [id]);

        const getErrorDescription = (error: AxiosError) => {
            switch (getErrorMessage(error).errorType) {
                case ErrorType.TSO_DEADLINE_PASSED:
                    return 'TSO deadline has passed';
                case ErrorType.NOT_ENOUGH_ACTIVE_COMPANIES:
                    return 'Traders list cannot be applied. It has less than 3 active companies';
                default:
                    return '';
            }
        };

        const handleSubmitCreate = (companyId: number, request: NewTradeProposalRequest,
            setSubmitting: (value: boolean) => void
        ) => {
            tradeProposalService.createFinal(companyId, request)
                .then(() => {
                    notify({
                        type: NotificationType.SUCCESS,
                        message: 'New trade proposal created successfully'
                    });
                    navigationService.goToMyTradeProposalsPath();
                    setSubmitting(false);
                })
                .catch((error: AxiosError) => {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Could not create trade proposal',
                        description: getErrorDescription(error)
                    });
                    setSubmitting(false);
                });
        };

        const handleSubmitUpdate = (proposalId: string, companyId: number, request: NewTradeProposalRequest,
            setSubmitting: (isSubmitting: boolean) => void
        ) => {
            tradeProposalService.updateFinal(parseInt(proposalId), companyId, request)
                .then(() => {
                    notify({
                        type: NotificationType.SUCCESS,
                        message: 'New trade proposal created successfully'
                    });
                    navigationService.goToMyTradeProposalsPath();
                    setSubmitting(false);
                })
                .catch((error: AxiosError) => {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Could not update trade proposal',
                        description: getErrorDescription(error)
                    });
                    setSubmitting(false);
                });
        };

        function handleSubmit(model: NewTradeProposalFormModel, formikHelpers: FormikHelpers<NewTradeProposalFormModel>) {
            if (!model.expirationDate || !model.dateDuration?.[0] || !model.dateDuration?.[1]) {
                return;
            }

            const request: NewTradeProposalRequest = {
                ...model,
                dateFrom: model.dateDuration[0].set({ s: 0, ms: 0 }).toISOString(),
                dateUntil: model.dateDuration[1].set({ s: 0, ms: 0 }).toISOString(),
                expirationDate: model.expirationDate.set({ s: 0, ms: 0 }).toISOString()
            };

            formikHelpers.setSubmitting(true);
            const companyId = authentication.selectedCompany?.id;

            if (!companyId) return;

            id ? handleSubmitUpdate(id, companyId, request, formikHelpers.setSubmitting)
                : handleSubmitCreate(companyId, request, formikHelpers.setSubmitting);
        }

        const handleSaveCreate = (companyId: number, request: NewTradeProposalRequest,
            setSubmitting: (value: boolean) => void
        ) => {
            tradeProposalService.createDraft(companyId, request)
                .then(() => {
                    notify({
                        type: NotificationType.SUCCESS,
                        message: 'New trade proposal draft created successfully'
                    });
                    navigationService.goToMyTradeProposalsPath();
                    setSubmitting(false);
                })
                .catch((error: AxiosError) => {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Could not create trade proposal',
                        description: getErrorDescription(error)
                    });
                    setSubmitting(false);
                });
        };

        const handleSaveUpdate = (proposalId: string, companyId: number, request: NewTradeProposalRequest,
            setSubmitting: (isSubmitting: boolean) => void
        ) => {
            tradeProposalService.updateDraft(parseInt(proposalId), companyId, request)
                .then((updatedProposal) => {
                    notify({
                        type: NotificationType.SUCCESS,
                        message: 'New trade proposal draft updated successfully'
                    });

                    setTradeProposal(updatedProposal);
                    setSubmitting(false);
                })
                .catch((error: AxiosError) => {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Could not update trade proposal',
                        description: getErrorDescription(error)
                    });
                    setSubmitting(false);
                });
        };

        function numberFieldsValid(formik: FormikContextType<NewTradeProposalFormModel>): boolean {
            const notification = (message: string) =>
                notify({
                    type: NotificationType.ERROR,
                    message: message
                });

            if (formik.values.capacityAmount && formik.errors.capacityAmount) {
                notification('Invalid capacity amount format');
                return false;
            }
            if (formik.values.tariffPrice && formik.errors.tariffPrice) {
                notification('Invalid price format');
                return false;
            }
            if (formik.values.minimumPeriod && formik.errors.minimumPeriod) {
                notification('Invalid minimum acceptable period');
                return false;
            }
            if (formik.values.minimumCapacity && formik.errors.minimumCapacity) {
                notification('Invalid minimum acceptable capacity');
                return false;
            }

            return true;
        }

        function handleSave(formik: FormikContextType<NewTradeProposalFormModel>) {
            if (!numberFieldsValid(formik))
                return;

            const request: NewTradeProposalRequest = {
                ...formik.values,
                dateFrom: formik.values.dateDuration?.[0] && formik.values.dateDuration[0].set({ s: 0, ms: 0 }).toISOString(),
                dateUntil: formik.values.dateDuration?.[1] && formik.values.dateDuration[1].set({ s: 0, ms: 0 }).toISOString(),
                expirationDate: formik.values.expirationDate && formik.values.expirationDate
                    .set({ s: 0, ms: 0 }).toISOString()
            };

            formik.setSubmitting(true);
            const companyId = authentication.selectedCompany?.id;

            if (!companyId) return;

            id ? handleSaveUpdate(id, companyId, request, formik.setSubmitting)
                : handleSaveCreate(companyId, request, formik.setSubmitting);
        }

        function handleDelete() {
            id && tradeProposalService.deleteDraft(parseInt(id))
                .then(() => {
                    notify({
                        type: NotificationType.SUCCESS,
                        message: 'Draft deleted'
                    });

                    navigationService.goToMyTradeProposalsPath();
                })
                .catch(() => {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Could not delete draft'
                    });
                });
        }

        function handleDeleteClick() {
            Modal.confirm({
                icon: <WarningOutlined/>,
                className: 'cancel-trade-modal',
                title: 'Delete draft?',
                content: 'Are you sure you want to delete this draft?',
                centered: true,
                cancelText: 'No',
                okText: 'Yes',
                maskClosable: true,
                onOk: handleDelete,
                okButtonProps: { danger: true }
            });
        }

        const initialValues: NewTradeProposalFormModel = {
            periodType: tradeProposal ? tradeProposal.periodType : PeriodType.FULL,
            networkPointId: tradeProposal ? tradeProposal.networkPointId : null,
            dateDuration: tradeProposal
                ? [
                    tradeProposal.dateFrom ? moment(tradeProposal?.dateFrom) : null,
                    tradeProposal.dateUntil ? moment(tradeProposal?.dateUntil) : null]
                : null,
            capacityAmount: tradeProposal ? tradeProposal.capacityAmount : null,
            capacityCategoryId: tradeProposal ? tradeProposal.capacityCategoryId : null,
            durationType: tradeProposal ? tradeProposal.durationType : DurationType.MONTHS,
            expirationDate: tradeProposal && tradeProposal.expirationDate !== null
                ? moment(tradeProposal.expirationDate)
                : null,
            autoAcceptable: tradeProposal ? tradeProposal.autoAcceptable : false,
            minimumCapacity: tradeProposal ? tradeProposal.minimumCapacity : null,
            proposalType: tradeProposal ? tradeProposal.proposalType : ProposalType.SELL,
            minimumPeriod: tradeProposal ? tradeProposal.minimumPeriod : null,
            surcharge: tradeProposal ? tradeProposal.surcharge : null,
            tariffPrice: tradeProposal ? tradeProposal.tariffPrice : null,
            tradersListId: tradeProposal ? tradeProposal.tradersListId : null
        };

        return (
            <Spin spinning={isLoading} indicator={<LoadingOutlined/>} size="large">
                <Row justify="center">
                    <Col xs={16} sm={16} md={16}>
                        <Row justify="start" gutter={64}>
                            <Col xs={10} sm={10}>
                                <Typography.Title level={4} className='page-header'>
                                    New trade proposal
                                </Typography.Title>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col xs={24} sm={24}>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={initialValues}
                                    onSubmit={handleSubmit}
                                    validationSchema={NewTradeProposalFormSchema}
                                >
                                    <NewTradeProposalForm
                                        handleSave={handleSave}
                                        handleDelete={handleDeleteClick}
                                        showDelete={id !== undefined && tradeProposal !== undefined}
                                        disabled={authentication.selectedCompany?.role !== AccessRight.FULL_CONTROL_USER}
                                        allNetworkPoints={networkPoints}
                                        allCapacityCategories={capacityCategories}
                                        allTradersLists={tradersLists}
                                        networkPoint={networkPoint}
                                    />
                                </Formik>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Spin>
        );
    };

import React from 'react';
import { Formik, FormikHelpers } from 'formik';

import { Form } from '../../../components/inputs/common/form';
import { FormItem } from '../../../components/inputs/common/form-item';
import { SubmitButton } from '../../../components/button/submit-button';
import { CAPACITY_CATEGORY_FORM_FIELDS, CapacityCategorySchema } from './capacity-category-schema';
import { Col, Row, Typography } from 'antd';
import { capacityCategoryService } from '../../../api/capacity-category-service';
import { CapacityCategoryRequest } from '../../../api/model/capacity-category-request';
import { NotificationType, notify } from '../../../service/notification-service';
import { navigationService } from '../../../service/navigation-service';
import { TextInput } from '../../../components/inputs/text-input/text-input';
import { UnsavedChangesPrompt } from '../../../components/prompt/unsaved-changes-prompt';

import './capacity-category-form.less';

const CapacityCategoryPage: React.FC = () => {

    function handleSubmit(values: CapacityCategoryRequest, formikHelpers: FormikHelpers<CapacityCategoryRequest>) {
        capacityCategoryService.createCapacityCategory(values)
            .then(() => {
                notify({
                    type: NotificationType.SUCCESS,
                    message: 'Category created successfully'
                });
                navigationService.goToSettingsPage();
                formikHelpers.setSubmitting(false);
            })
            .catch(() => {
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not create category'
                });
                formikHelpers.setSubmitting(false);
            });
    }

    function renderForm(
        initialValues: CapacityCategoryRequest,
        values: CapacityCategoryRequest,
        isSubmitting: boolean
    ): React.ReactNode {
        return (
            <Form className="capacity-category-form">
                <FormItem name={CAPACITY_CATEGORY_FORM_FIELDS.name} label={'Capacity category name'} labelType="horizontal">
                    <TextInput name={CAPACITY_CATEGORY_FORM_FIELDS.name}/>
                </FormItem>
                <Row justify="end" gutter={64}>
                    <Col xs={14}>
                        <SubmitButton>
                            Create
                        </SubmitButton>
                    </Col>
                </Row>
                <UnsavedChangesPrompt
                    when={values !== initialValues && !isSubmitting}
                    message={'You have unsaved changes, are you sure you want to leave this page?'}
                />
            </Form>
        );
    }

    return (
        <Row justify="center">
            <Col xs={24} sm={14}>
                <Row gutter={64}>
                    <Col xs={10} className="page-header">
                        <Typography.Title level={4}>
                            New capacity category
                        </Typography.Title>
                    </Col>
                </Row>
                <Formik
                    enableReinitialize={true}
                    initialValues={{ name: '' }}
                    onSubmit={handleSubmit}
                    validationSchema={CapacityCategorySchema}
                >
                    {({ initialValues, values, isSubmitting }) => renderForm(initialValues, values, isSubmitting)}
                </Formik>
            </Col>
        </Row>
    );
};

export { CapacityCategoryPage };

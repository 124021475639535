import React from 'react';
import { Formik, FormikHelpers } from 'formik';

import { Form } from '../../../../components/inputs/common/form';
import { FormItem } from '../../../../components/inputs/common/form-item';
import { SubmitButton } from '../../../../components/button/submit-button';
import { TwoFactorLoginFormModel } from './two-factor-login-form-model';
import { TWO_FACTOR_LOGIN_FIELDS, TwoFactorLoginSchema } from './two-factor-login-schema';
import { Col, Row } from 'antd';
import { TextInput } from '../../../../components/inputs/text-input/text-input';

import './two-factor-login-form.less';

interface OwnProps {
    initialValues: TwoFactorLoginFormModel;
    handleSubmit: (
        values: TwoFactorLoginFormModel,
        actions: FormikHelpers<TwoFactorLoginFormModel>
    ) => void;
}

type Props = OwnProps;

const TwoFactorLoginForm: React.FC<Props> = (props: Props) => {
    const {
        handleSubmit,
        initialValues
    } = props;

    const renderForm = (): React.ReactNode => {
        return (
            <Form>
                <FormItem label="Enter code" name={TWO_FACTOR_LOGIN_FIELDS.code}>
                    <TextInput
                        name={TWO_FACTOR_LOGIN_FIELDS.code}
                    />
                </FormItem>
                <Row justify="center">
                    <Col>
                        <SubmitButton>
                            Login
                        </SubmitButton>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={TwoFactorLoginSchema}
        >
            {renderForm}
        </Formik>
    );
};

export { TwoFactorLoginForm };

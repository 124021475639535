import { CancelSource } from './cancel-source';
import { RestService } from './rest-service';
import { ResponseModel } from './model/response-model';
import { PageResponse } from './page-response';
import { stringify } from 'query-string';
import { ResponseFilterModel } from './model/response-filter-model';
import { ResponseListItem } from './model/response-list-item';
import { ResponseReport } from './model/response-report';
import { ResponseReportsFilterModel } from './model/response-reports-filter-model';
import { ResponseDetails } from './model/response-details';
import { downloadFile } from '../utils/file-utils';
import { FilterMaxValuesModel } from './model/filter-max-values-model';

class ResponseService {
    private readonly restService: RestService;

    constructor(cancelSource: CancelSource = new CancelSource()) {
        this.restService = cancelSource.service;
    }

    public readonly createResponse = (responseRequest: ResponseModel, tradeProposalId: number, companyId: number): Promise<void> => {
        return this.restService
            .post<void>(`/responses/${companyId}/createResponse/${tradeProposalId}`, responseRequest)
            .then(response => response.data);
    };

    public readonly answerResponse = (accepted: boolean, tradeProposalId: number): Promise<void> => {
        return this.restService.post<void>(`/responses/replyToResponse/${tradeProposalId}`, { accepted: accepted })
            .then(response => response.data);
    };

    public readonly getResponse = (tradeProposalId: number, selectedCompanyId?: number): Promise<ResponseModel> => {
        return this.restService.get<ResponseModel>(
            `/responses/getResponse/${tradeProposalId}`,
            { params: { selectedCompanyId } }
        )
            .then(response => response.data);
    };

    public readonly getResponseDetails = (responseId: number, selectedCompanyId?: number): Promise<ResponseModel> => {
        return this.restService.get<ResponseModel>(
            `/responses/getResponseDetails/${responseId}`,
            { params: { selectedCompanyId } }
        )
            .then(response => response.data);
    };

    public readonly getResponsesByCompany = (
        companyId: number,
        sorter?: string | string[],
        page?: number,
        size?: number,
        filters?: ResponseFilterModel
    ): Promise<PageResponse<ResponseListItem>> => {
        const requestParams = {
            page,
            size,
            sort: sorter
        };

        return this.restService.post<PageResponse<ResponseListItem>>(`/responses/${companyId}`, filters,{
            params: requestParams,
            paramsSerializer: params => stringify(params, { arrayFormat: 'none' })
        }).then(response => response.data);
    };

    public readonly getResponseReports = (
        sorter?: string | string[],
        page?: number,
        size?: number,
        filters?: ResponseReportsFilterModel
    ): Promise<PageResponse<ResponseReport>> => {
        const requestParams = {
            page,
            size,
            sort: sorter
        };

        return this.restService.post<PageResponse<ResponseReport>>('/responses/getReports', filters,{
            params: requestParams,
            paramsSerializer: params => stringify(params, { arrayFormat: 'none' })
        }).then(response => response.data);
    };

    public readonly getResponseDetailsReport = (responseId: number): Promise<ResponseDetails> => {
        return this.restService.get<ResponseDetails>(`/responses/getReports/${responseId}`)
            .then(response => response.data);
    };

    public readonly getMaximumFilterValues = (): Promise<FilterMaxValuesModel> => {
        return this.restService.get<FilterMaxValuesModel>('/responses/getMaxFilterValues')
            .then(response => response.data);
    };

    public readonly getMaximumFilterValuesByCompany = (companyId: number): Promise<FilterMaxValuesModel> => {
        return this.restService.get<FilterMaxValuesModel>(`/responses/${companyId}/getMaxFilterValues`)
            .then(response => response.data);
    };

    public readonly download = (companyId: number, sorts: string | string[], filters: ResponseFilterModel): Promise<void> => {
        return this.restService.post<void>(`/responses/${companyId}/download`, filters,{
            params: { sort: sorts },
            paramsSerializer: params => stringify(params, { arrayFormat: 'none' }),
            responseType: 'blob'
        }).then(response => downloadFile(response));
    };

    public readonly downloadReports = (sorts?: string | string[], filters?: ResponseReportsFilterModel): Promise<void> => {
        return this.restService.post<void>('/responses/getReports/download', filters,{
            params: { sort: sorts },
            paramsSerializer: params => stringify(params, { arrayFormat: 'none' }),
            responseType: 'blob'
        }).then(response => downloadFile(response));
    };
}

const responseService = new ResponseService();

export { responseService, ResponseService };


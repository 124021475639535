import { createBrowserHistory } from 'history';

export interface UrlParams {
    path: string;
    reload?: boolean;
    placeholders?: any;
    state?: any;
}

export const history = createBrowserHistory();

class NavigationService {

    public readonly HOME_PATH: string = '/';

    /* Public paths */
    public readonly LOGIN_PATH: string = `${this.HOME_PATH}login`;

    public readonly FORGOT_PASSWORD_PATH: string = `${this.HOME_PATH}forgotPassword`;

    public readonly RESET_PASSWORD_PATH: string = `${this.HOME_PATH}resetPassword/:token`;

    public readonly TWO_FACTOR_LOGIN_PATH: string = `${this.HOME_PATH}confirmLogin/:token`;

    public readonly TRADING_RESULTS_PATH: string = `${this.HOME_PATH}tradingResults`;

    public readonly TRADING_RESULT_DETAILS_PATH: string = `${this.HOME_PATH}tradingResult/:tradingResultId`;

    /*Private paths */
    public readonly PRIVATE_PATH: string = `${this.HOME_PATH}private`;

    public readonly REGISTRATION_PATH: string = `${this.PRIVATE_PATH}/registration`;

    public readonly EDIT_PATH: string = `${this.PRIVATE_PATH}/edit`;

    public readonly COMPANY_REGISTRATION_PATH: string = `${this.REGISTRATION_PATH}/company`;

    public readonly COMPANY_EDIT_PATH: string = `${this.EDIT_PATH}/company/:id?`;

    public readonly USER_REGISTRATION_PATH: string = `${this.REGISTRATION_PATH}/user/:companyId`;

    public readonly USER_EDIT_PATH: string = `${this.EDIT_PATH}/user/:userId`;

    public readonly USERS_MANAGEMENT_PATH: string = `${this.PRIVATE_PATH}/usersManagement`;

    public readonly CREATE_TRADE_PROPOSAL_PATH: string = `${this.PRIVATE_PATH}/tradeProposals/create`;

    public readonly TRADE_PROPOSAL_EDIT_PATH: string = `${this.PRIVATE_PATH}/tradeProposals/edit/:id`;

    public readonly ADD_CAPACITY_CATEGORY_PATH: string = `${this.PRIVATE_PATH}/admin/capacityCategory`;

    public readonly SETTINGS_PATH: string = `${this.PRIVATE_PATH}/settings`;

    public readonly TRADE_PROPOSAL_DETAILS_PATH: string = `${this.PRIVATE_PATH}/tradeProposal/:id`;

    public readonly MY_TRADE_PROPOSALS_PATH: string = `${this.PRIVATE_PATH}/myTradeProposals`;

    public readonly MY_TRADING_RESULT_DETAILS_PATH: string = `${this.PRIVATE_PATH}/myTradingResult/:tradingResultId`;

    public readonly MY_RESPONSES_PATH: string = `${this.PRIVATE_PATH}/myResponses`;

    public readonly USER_SETTINGS_PATH: string = `${this.PRIVATE_PATH}/userSettings`;

    public readonly MY_TRADING_RESULTS_PATH: string = `${this.PRIVATE_PATH}/myTradingResults`;

    public readonly TRADING_RESULT_REPORT_DETAILS_PATH: string = `${this.PRIVATE_PATH}/tradingResultReport/:tradingResultId`;

    public readonly RESPONSE_DETAILS_PATH: string = `${this.PRIVATE_PATH}/myResponses/:id/:responseId`;

    public readonly REPORTS_PATH: string = `${this.PRIVATE_PATH}/reports`;

    public readonly TRADE_PROPOSAL_REPORTS_PATH: string = `${this.REPORTS_PATH}/tradeProposals`;

    public readonly TRADE_RESULT_REPORTS_PATH: string = `${this.REPORTS_PATH}/tradeResults`;

    public readonly TRADE_PROPOSAL_REPORT_DETAILS_PATH: string = `${this.REPORTS_PATH}/tradeProposals/:tradeProposalId`;

    public readonly RESPONSE_REPORTS_PATH: string = `${this.REPORTS_PATH}/responses`;

    public readonly RESPONSE_REPORT_DETAILS_PATH: string = `${this.REPORTS_PATH}/responses/:responseId`;

    public readonly LOGS_PATH: string = `${this.PRIVATE_PATH}/logs`;

    public constructor() {
        this.getUrl = this.getUrl.bind(this);
        this.goToPath = this.goToPath.bind(this);
    }

    public getUrl(pathParams: UrlParams): string {
        let tempText = pathParams.path;

        if (pathParams.placeholders) {
            Object.keys(pathParams.placeholders).forEach((key: string) => {
                tempText = tempText.replace(/[?]/g, '');
                tempText = tempText.replace(
                    `:${key}`,
                    pathParams.placeholders[key]
                );
            });
        }

        return tempText;
    }

    public goToLoginPage = () =>
        this.goToPath({ path: this.LOGIN_PATH });

    public goToPrivatePage = () =>
        this.goToPath({ path: this.PRIVATE_PATH });

    public goToForgotPasswordPage = () =>
        this.goToPath({ path: this.FORGOT_PASSWORD_PATH });

    public goToUsersManagementPath = () =>
        this.goToPath({ path: this.USERS_MANAGEMENT_PATH });

    public goToUserRegistrationPath = (companyId?: number) => {
        this.goToPath({ path: this.USER_REGISTRATION_PATH, placeholders: { companyId } });
    };

    public goToUserEditPath = (userId: number) => {
        this.goToPath({ path: this.USER_EDIT_PATH, placeholders: { userId } });
    };

    public goToCompanyRegistrationPath = () => {
        this.goToPath({ path: this.COMPANY_REGISTRATION_PATH });
    };

    public goToCompanyEditPath = (id?: number) => {
        this.goToPath({ path: this.COMPANY_EDIT_PATH, placeholders: { id } });
    };

    public goToCreateTradeProposalPath = () => {
        this.goToPath({ path: this.CREATE_TRADE_PROPOSAL_PATH });
    };

    public goToAddCapacityCategoryPath = () => {
        this.goToPath({ path: this.ADD_CAPACITY_CATEGORY_PATH });
    };

    public goToSettingsPage = () =>
        this.goToPath({ path: this.SETTINGS_PATH });

    public goToTradeProposalsPath = () =>
        this.goToPath({ path: this.HOME_PATH });

    public goToTradeProposalDetailsPath = (id?: number) => {
        this.goToPath({ path: this.TRADE_PROPOSAL_DETAILS_PATH, placeholders: { id } });
    };

    public goToMyTradeProposalsPath = () => {
        this.goToPath({ path: this.MY_TRADE_PROPOSALS_PATH });
    };

    public goToEditTradeProposalPath = (id?: number) => {
        this.goToPath({ path: this.TRADE_PROPOSAL_EDIT_PATH, placeholders: { id } });
    };

    public goToMyResponsesPath = () => {
        this.goToPath({ path: this.MY_RESPONSES_PATH });
    };

    public goToMyTradingResultsPath = () => {
        this.goToPath({ path: this.MY_TRADING_RESULTS_PATH });
    };

    public goToTradingResultDetailsPath = (tradingResultId: number) => {
        this.goToPath({ path: this.TRADING_RESULT_DETAILS_PATH, placeholders: { tradingResultId } });
    };

    public goToMyTradingResultsDetailsPath = (tradingResultId: number) => {
        this.goToPath({ path: this.MY_TRADING_RESULT_DETAILS_PATH, placeholders: { tradingResultId } });
    };

    public goToTradingResultReportDetailsPath = (tradingResultId: number) => {
        this.goToPath({ path: this.TRADING_RESULT_REPORT_DETAILS_PATH, placeholders: { tradingResultId } });
    };

    public goToTradeProposalReportsPath = () => {
        this.goToPath({ path: this.TRADE_PROPOSAL_REPORTS_PATH });
    };

    public goToTradeProposalReportDetailsPath = (tradeProposalId: number) => {
        this.goToPath({ path: this.TRADE_PROPOSAL_REPORT_DETAILS_PATH, placeholders: { tradeProposalId } });
    };

    public goToResponseReportsPath = () => {
        this.goToPath({ path: this.RESPONSE_REPORTS_PATH });
    };

    public goToResponseReportDetailsPath = (responseId: number) => {
        this.goToPath({ path: this.RESPONSE_REPORT_DETAILS_PATH, placeholders: { responseId } });
    };

    public goToResponseDetailsPath = (id?: number, responseId?: number) => {
        this.goToPath({ path: this.RESPONSE_DETAILS_PATH, placeholders: {
            id: id,
            responseId: responseId
        } });
    };

    public goToTradingResultsPath = () => {
        this.goToPath({ path: this.TRADING_RESULTS_PATH });
    };

    public goToTradingResultReportsPath = () => {
        this.goToPath({ path: this.TRADE_RESULT_REPORTS_PATH });
    };

    public goToUserSettingsPath = () => {
        this.goToPath({ path: this.USER_SETTINGS_PATH });
    };

    public goToLogsPath = () => {
        this.goToPath({ path: this.LOGS_PATH });
    };

    public goToTwoFactorLoginPath = (token?: string) => {
        this.goToPath({ path: this.TWO_FACTOR_LOGIN_PATH, placeholders: { token } });
    };

    public goToPath(pathParams: UrlParams): void {
        const convertedPath: string = this.getUrl(pathParams);

        history.push(convertedPath);
    }
}

const navigationService = new NavigationService();

export { NavigationService, navigationService };

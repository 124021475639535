import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';

import { tradeProposalService } from '../../../../api/trade-proposal-service';
import { responseService } from '../../../../api/response-service';
import { NotificationType, notify } from '../../../../service/notification-service';
import { TradeProposalReportDetails } from '../../../../api/model/trade-proposal-report-details';
import { ResponseDetails } from '../../../../api/model/response-details';
import { RootState } from '../../../../redux/store';
import { TradeProposalReportInformation } from './trade-proposal-report-information';
import { ResponseInformation } from './response-information';

import './trade-proposal-report-details-page.less';

export interface TradeProposalDetailsProps {
    tradeProposalId?: string;
    responseId?: string;
}

const TradeProposalReportDetailsPageComponent: React.FC<RouteComponentProps<TradeProposalDetailsProps>> = (
    props: RouteComponentProps<TradeProposalDetailsProps>
) => {

    const {
        match: {
            params: {
                tradeProposalId,
                responseId
            }
        }
    } = props;

    const [isLoading, setLoading] = useState<boolean>(true);
    const [tradeProposal, setTradeProposal] = useState<TradeProposalReportDetails>();
    const [responseLoading, setResponseLoading] = useState<boolean>(true);
    const [response, setResponse] = useState<ResponseDetails | undefined>(undefined);
    const [activeResponseId, setActiveResponseId] = useState<number | null>(null);

    const authentication = useSelector((state: RootState) => state.authentication);
    const selectedCompany = authentication.selectedCompany;

    const loadDataByTradeProposalId = (tradeProposalId: number) => {
        tradeProposalService.getTradeProposalDetailsReport(tradeProposalId)
            .then(t => {
                setTradeProposal(t);
                setLoading(false);

                if (t.activeResponseId) {
                    setResponseLoading(true);
                    setActiveResponseId(t.activeResponseId);
                    responseService.getResponseDetailsReport(t.activeResponseId)
                        .then(r => {
                            setResponse(r);
                            setResponseLoading(false);
                        })
                        .catch(() => {
                            notify({
                                type: NotificationType.ERROR,
                                message: 'Could not load response details'
                            });
                        });
                } else {
                    setResponseLoading(false);
                }
            }).catch(() => {
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not load trade proposal details'
                });
            });
    };

    const loadDataByResponseId = (responseId: number) => {
        responseService.getResponseDetailsReport(responseId)
            .then(r => {
                setResponse(r);
                setResponseLoading(false);

                if (r.tradeProposalId) {
                    setLoading(true);
                    tradeProposalService.getTradeProposalDetailsReport(r.tradeProposalId)
                        .then(t => {
                            setTradeProposal(t);
                            setLoading(false);
                        })
                        .catch(() => {
                            notify({
                                type: NotificationType.ERROR,
                                message: 'Could not load trade proposal details'
                            });
                        });
                } else {
                    setLoading(false);
                }
            }).catch(() => {
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not load response details'
                });
            });
    };

    useEffect(() => {
        tradeProposalId && loadDataByTradeProposalId(parseInt(tradeProposalId));
        responseId && loadDataByResponseId(parseInt(responseId));
    }, [tradeProposalId, responseId]);

    return <Row justify="center" className="report-details-page">
        <Col>
            <TradeProposalReportInformation
                loading={isLoading || responseLoading}
                tradeProposal={tradeProposal}
            />
        </Col>
        {(activeResponseId || responseId) &&
            <Col>
                <ResponseInformation loading={isLoading || responseLoading} response={response}/>
            </Col>
        }
    </Row>;
};

const tradeProposalReportDetailsPage = withRouter(TradeProposalReportDetailsPageComponent);

export { tradeProposalReportDetailsPage };

import React, { useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { LoginFormModel } from './form/login-form-model';
import { FormikHelpers } from 'formik';
import { LoginForm } from './form/login-form';
import { authenticationService } from '../../../api/authentication-service';
import { NotificationType, notify } from '../../../service/notification-service';
import { AxiosError } from 'axios';
import { ErrorModel, ErrorType, getErrorMessage } from '../../../utils/error-utils';
import { navigationService } from '../../../service/navigation-service';
import { useDispatch } from 'react-redux';
import { LoginResponse } from '../../../api/model/login-response';
import { Role } from '../../../components/auth/private-route';
import { userLogin } from '../../../redux/authentication/actions';

import './login-page.less';

const LoginPage: React.FC = () => {
    const initialValues: LoginFormModel = {
        email: '',
        password: ''
    };

    const [, setIsLoading] = useState(true);

    const dispatch = useDispatch();

    const handleSubmit = (values: LoginFormModel, formikHelpers: FormikHelpers<LoginFormModel>) => {
        setIsLoading(true);
        formikHelpers.setSubmitting(true);

        authenticationService.login(values).then((response: LoginResponse) => {
            dispatch(userLogin({
                isLoggedIn: true,
                role: response.isAdmin ? Role.SYSTEM_ADMIN : Role.SYSTEM_USER,
                firstName: response.firstName,
                lastName: response.lastName,
                companyRoleMap: response.companyRoleMap,
                selectedCompany: response.companyRoleMap ? response.companyRoleMap[0] : undefined
            }));
            formikHelpers.setSubmitting(false);
            setIsLoading(false);
            navigationService.goToTradeProposalsPath();
        }).catch((error: AxiosError) => {
            const errorDetails: ErrorModel = getErrorMessage(error);

            formikHelpers.setSubmitting(false);
            setIsLoading(false);

            switch (getErrorMessage(error).errorType) {
                case ErrorType.LOGIN_ATTEMPTS_EXCEEDED_ERROR:
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Could not login',
                        description: errorDetails.message
                    });
                    navigationService.goToForgotPasswordPage();
                    break;
                case ErrorType.USER_ACCOUNT_DISABLED:
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Could not login',
                        description: 'Your account is locked'
                    });

                    break;
                case ErrorType.TWO_FACTOR_REQUIRED:
                    notify({
                        type: NotificationType.WARNING,
                        message: 'Two factor authentication required',
                        description: 'We have sent an email to your address, please enter the code that you received.'
                    });
                    navigationService.goToTwoFactorLoginPath(getErrorMessage(error).token);
                    break;
                default:
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Could not login',
                        description: 'Please check your credentials and try again'
                    });
                    break;
            }
        });
    };

    return (
        <Row justify="center" className="login-page">
            <Col xs={20} sm={8}>
                <Typography.Title level={4}>Login</Typography.Title>
                <LoginForm
                    isLoading={false}
                    initialValues={initialValues}
                    handleSubmit={handleSubmit}
                />
            </Col>
        </Row>
    );
};

export { LoginPage };

import React from 'react';
import { Field, FieldProps } from 'formik';
import { Input } from 'antd';
import { PasswordProps } from 'antd/lib/input';

import { FormikFieldProps } from '../common/field-props';

import './text-input.less';

export type TextInputProps = FormikFieldProps & PasswordProps;

const PasswordInput: React.FC<TextInputProps> = ({ name, ...restProps }: TextInputProps) => (
    <Field name={name}>
        {({ field: { value, onChange, onBlur }, form: { isSubmitting } }: FieldProps) => (
            <Input.Password
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={isSubmitting}
                className="password-input"
                autoComplete="off"
                {...restProps}
            />
        )}
    </Field>
);

export { PasswordInput };

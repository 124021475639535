import { CancelSource } from './cancel-source';
import { RestService } from './rest-service';
import { ListItem } from './model/list-item';
import { NetworkPointListItem } from './model/network-point-list-item';

class NetworkPointService {
    private readonly restService: RestService;

    constructor(cancelSource: CancelSource = new CancelSource()) {
        this.restService = cancelSource.service;
    }

    public readonly getAllNetworkPoints = (): Promise<ListItem[]> => {
        return this.restService.get<ListItem[]>('/networkPoints')
            .then(response => response.data);
    };

    public readonly getNetworkPointsByTso = (tsoId: number): Promise<ListItem[]> => {
        return this.restService.get<ListItem[]>(`/networkPoint/${tsoId}`)
            .then(response => response.data);
    };

    public readonly getNetworkPointsByCompanyId = (companyId: number): Promise<NetworkPointListItem[]> => {
        return this.restService.get<NetworkPointListItem[]>(`/networkPoint/getByCompany/${companyId}`)
            .then(response => response.data);
    };
}

const networkPointService = new NetworkPointService();

export { networkPointService, NetworkPointService };


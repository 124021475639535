import React from 'react';
import { Formik, FormikHelpers } from 'formik';

import { Form } from '../../../../components/inputs/common/form';
import { FormItem } from '../../../../components/inputs/common/form-item';
import { SubmitButton } from '../../../../components/button/submit-button';
import { ChangePasswordFormModel } from './change-password-form-model';
import { PasswordInput } from '../../../../components/inputs/text-input/password-input';
import { CHANGE_PASSWORD_FORM_FIELDS, ChangePasswordSchema } from './change-password-schema';
import { Col, Row } from 'antd';
import { userService } from '../../../../api/user-service';
import { NotificationType, notify } from '../../../../service/notification-service';
import { AxiosError } from 'axios';
import { ErrorType, getErrorMessage } from '../../../../utils/error-utils';
import { UnsavedChangesPrompt } from '../../../../components/prompt/unsaved-changes-prompt';

import './change-password-form.less';

const ChangePasswordForm: React.FC = () => {

    function handleSubmit(values: ChangePasswordFormModel, formikHelpers: FormikHelpers<ChangePasswordFormModel>) {
        userService.changePassword(values)
            .then(() => {
                notify({
                    message: 'Updated password successfully',
                    type: NotificationType.SUCCESS
                });

                formikHelpers.resetForm();
                formikHelpers.setSubmitting(false);
            })
            .catch((error: AxiosError) => {
                switch (getErrorMessage(error).errorType) {
                    case ErrorType.INCORRECT_PASSWORD:
                        notify({
                            type: NotificationType.ERROR,
                            message: 'Could not change password',
                            description: 'Current password was incorrect'
                        });

                        break;
                    case ErrorType.PASSWORD_RECENTLY_USED:
                        notify({
                            type: NotificationType.ERROR,
                            message: 'Password was not changed',
                            description: 'Password can not be the same as your last 3 passwords'
                        });
                        break;
                    case ErrorType.PASSWORD_IS_THE_SAME:
                        notify({
                            type: NotificationType.ERROR,
                            message: 'Password was not changed',
                            description: 'Password can not be the same as current password'
                        });
                        break;
                    default:
                        notify({
                            type: NotificationType.ERROR,
                            message: 'Could not change password',
                        });
                        break;
                }

                formikHelpers.setSubmitting(false);
            });
    }

    const renderForm = (
        initialValues: ChangePasswordFormModel,
        values: ChangePasswordFormModel,
        isSubmitting: boolean
    ): React.ReactNode => {
        return (
            <Form className="change-password-form">
                <FormItem
                    label="Current password"
                    name={CHANGE_PASSWORD_FORM_FIELDS.currentPassword}
                    labelType='horizontal'
                >
                    <PasswordInput
                        name={CHANGE_PASSWORD_FORM_FIELDS.currentPassword}
                    />
                </FormItem>
                <FormItem
                    label="New password"
                    name={CHANGE_PASSWORD_FORM_FIELDS.newPassword}
                    labelType='horizontal'
                >
                    <PasswordInput
                        name={CHANGE_PASSWORD_FORM_FIELDS.newPassword}
                    />
                </FormItem>
                <FormItem
                    label="Repeat password"
                    name={CHANGE_PASSWORD_FORM_FIELDS.repeatPassword}
                    labelType='horizontal'
                >
                    <PasswordInput
                        name={CHANGE_PASSWORD_FORM_FIELDS.repeatPassword}
                    />
                </FormItem>
                <Row justify="end">
                    <Col>
                        <SubmitButton>
                            Reset password
                        </SubmitButton>
                    </Col>
                </Row>
                <UnsavedChangesPrompt
                    when={values !== initialValues && !isSubmitting}
                    message={'You have unsaved changes, are you sure you want to leave this page?'}
                />
            </Form>
        );
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                newPassword: '',
                currentPassword: '',
                repeatPassword: ''
            }}
            onSubmit={handleSubmit}
            validationSchema={ChangePasswordSchema}
        >
            {({ initialValues, values, isSubmitting }) => renderForm(initialValues, values, isSubmitting)}
        </Formik>
    );

};

export { ChangePasswordForm };

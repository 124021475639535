import React, { useState } from 'react';
import { Steps } from 'antd';
import { WizardStep, WizardStepProps } from './wizard-step';

import './form-wizard.less';

export interface FormWizardProps {
    steps: WizardStepProps[];
    isStepsClickable: boolean;
}

export const FormWizard: React.FC<FormWizardProps> = (props: FormWizardProps) => {
    const {
        steps,
        isStepsClickable
    } = props;

    const [currentStep, _setStep] = useState<number>(0);

    function nextStep() {
        _setStep(currentStep + 1);
    }

    return (
        <>
            <Steps current={currentStep} onChange={isStepsClickable ? _setStep : undefined}>
                {steps.map(step =>
                    <Steps.Step title={step.title} key={step.title} className="wizard-step"/>
                )}
            </Steps>
            <WizardStep
                initialValues={steps[currentStep].initialValues}
                validationSchema={steps[currentStep].validationSchema}
                title={steps[currentStep].title}
                nextStep={nextStep}
                onSubmit={steps[currentStep].onSubmit}
                form={steps[currentStep].form}
            />
        </>
    );
};

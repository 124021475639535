import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Row } from 'antd';
import { NotificationType, notify } from '../../../../../service/notification-service';
import { TradeProposalInformation } from '../../../../../components/text/trade-proposal-information';
import { TradingResultDetails } from '../../../../../api/model/trading-result-details';
import { tradingResultService } from '../../../../../api/trading-result-service';

import '../../../trade-proposals/trade-proposal-details/trade-proposal-details-page.less';

export interface TradeProposalDetailsProps {
    tradingResultId?: string;
}

const TradingResultsDetailsPageComponent: React.FC<RouteComponentProps<TradeProposalDetailsProps>> = (
    props: RouteComponentProps<TradeProposalDetailsProps>
) => {

    const {
        match: {
            params: {
                tradingResultId
            }
        }
    } = props;

    const [isLoading, setLoading] = useState<boolean>(true);
    const [tradingResult, setTradingResult] = useState<TradingResultDetails>();

    useEffect(() => {
        tradingResultId && tradingResultService.getTradingResultDetails(parseInt(tradingResultId)).then((res) => {
            setTradingResult(res);
            setLoading(false);
        }).catch(() => {
            notify({
                type: NotificationType.ERROR,
                message: 'Could not load trading result details'
            });
        });
    }, [tradingResultId]);

    return <Row justify="center">
        <Col xs={8} className="trade-proposal-details-column">
            <TradeProposalInformation
                loading={isLoading}
                tradeProposal={tradingResult?.tradeProposal}
                showCancel={false}
                response={tradingResult?.response}
                tradingResultId={tradingResult?.tradingResultId}
            />
        </Col>
    </Row>;
};

const tradingResultsDetailsPage = withRouter(TradingResultsDetailsPageComponent);

export { tradingResultsDetailsPage };

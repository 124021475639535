import * as Yup from 'yup';
import { Moment } from 'moment';
import moment from 'moment';
import { RangeValue, EventValue } from 'rc-picker/lib/interface';

import { ToggleButtonOption } from '../../../../../components/inputs/radio-input/radio-buttons';
import { Validations } from '../../../../../components/util/validation-utils';
import { DurationType } from '../../../../../components/inputs/calendar-input/duration-input';

export enum ProposalType {
    SELL = 'SELL',
    BUY = 'BUY'
}

export enum PeriodType {
    PARTIAL = 'PARTIAL',
    FULL = 'FULL'
}

export interface NewTradeProposalFormModel {
    proposalType: ProposalType;
    networkPointId: number | null;
    capacityCategoryId: number | null;
    capacityAmount: number | null;
    dateDuration: RangeValue<Moment>;
    tariffPrice: number | null;
    surcharge: number | null;
    periodType: PeriodType;
    minimumPeriod: number | null;
    durationType: DurationType;
    autoAcceptable: boolean;
    minimumCapacity: number | null;
    expirationDate: EventValue<Moment>;
    tradersListId: number | null;
}

export const NEW_TRADE_PROPOSAL_FORM_FIELDS = {
    proposalType: 'proposalType',
    networkPointId: 'networkPointId',
    capacityCategoryId: 'capacityCategoryId',
    capacityAmount: 'capacityAmount',
    dateDuration: 'dateDuration',
    tariffPrice: 'tariffPrice',
    surcharge: 'surcharge',
    periodType: 'periodType',
    minimumPeriod: 'minimumPeriod',
    autoAcceptable: 'autoAcceptable',
    minimumCapacity: 'minimumCapacity',
    durationType: 'durationType',
    expirationDate: 'expirationDate',
    tradersListId: 'tradersListId'
};

const MORE_THAN = 0;
const MIN = 0;
const MAX_NUMBER_VALUE = 9 * (10 ** 15);
const LESS_THAN_PRICE = (10 ** 9);

export const NewTradeProposalFormSchema = Yup.object().shape({
    [NEW_TRADE_PROPOSAL_FORM_FIELDS.proposalType]: Yup.string()
        .required(Validations.required),
    [NEW_TRADE_PROPOSAL_FORM_FIELDS.networkPointId]: Yup.number()
        .transform(value => !value ? undefined : value)
        .nullable(false)
        .required(Validations.required),
    [NEW_TRADE_PROPOSAL_FORM_FIELDS.capacityCategoryId]: Yup.number()
        .transform(value => !value ? undefined : value)
        .nullable(false)
        .required(Validations.required),
    [NEW_TRADE_PROPOSAL_FORM_FIELDS.capacityAmount]: Yup.number()
        .transform(value => (!value && value !== 0) ? undefined : value)
        .min(MIN, Validations.min(MIN))
        .max(MAX_NUMBER_VALUE, Validations.max(MAX_NUMBER_VALUE))
        .integer(Validations.onlyInteger)
        .required(Validations.required),
    [NEW_TRADE_PROPOSAL_FORM_FIELDS.dateDuration]: Yup.array()
        .transform(value => !value || !value[0] || !value[1] ? undefined : value)
        .required(Validations.required)
        .test(
            'is-period-valid',
            Validations.invalidPeriod,
            function (value: string) {
                if (!value)
                    return true;
                return moment(value[0]).isBefore(moment(value[1]), 'day');
            }
        ),
    [NEW_TRADE_PROPOSAL_FORM_FIELDS.tariffPrice]: Yup.number()
        .transform(value => (!value && value !== 0) ? undefined : value)
        .moreThan(MORE_THAN, Validations.moreThan(MORE_THAN))
        .lessThan(LESS_THAN_PRICE, Validations.lessThan(LESS_THAN_PRICE))
        .required(Validations.required)
        .test(
            'is-correct-tariff-price-scale',
            Validations.invalidPrice,
            function (value: string) {
                return (value?.toString().match(Validations.regex.priceScale) || []).length > 0;
            }
        ),
    [NEW_TRADE_PROPOSAL_FORM_FIELDS.surcharge]: Yup.number()
        .transform(value => (!value && value !== 0) ? undefined : value)
        .moreThan(MORE_THAN, Validations.moreThan(MORE_THAN))
        .max(MAX_NUMBER_VALUE, Validations.max(MAX_NUMBER_VALUE))
        .test(
            'is-correct-surcharge-scale',
            Validations.invalidPrice,
            function (value: string) {
                return (value?.toString().match(Validations.regex.priceScale) || []).length > 0 || !value;
            }
        ),
    [NEW_TRADE_PROPOSAL_FORM_FIELDS.periodType]: Yup.string()
        .required(Validations.required),
    [NEW_TRADE_PROPOSAL_FORM_FIELDS.minimumPeriod]: Yup.number()
        .transform(value => !value ? undefined : value)
        .integer(Validations.onlyInteger)
        .moreThan(MORE_THAN, Validations.moreThan(MORE_THAN))
        .max(MAX_NUMBER_VALUE, Validations.max(MAX_NUMBER_VALUE))
        .when(NEW_TRADE_PROPOSAL_FORM_FIELDS.periodType, {
            is: PeriodType.PARTIAL,
            then: Yup.number()
                .transform(value => !value ? undefined : value)
                .test(
                    'isValidPeriod',
                    Validations.periodCannotBeLongerThanMinimum,
                    function (value: number) {
                        return moment(this.parent.dateDuration?.[1])
                            .diff(this.parent.dateDuration?.[0], this.parent.durationType.toLowerCase()) >= value;
                    }
                )
                .required(Validations.required)
                .nullable(false)
        })
        .nullable(true),
    [NEW_TRADE_PROPOSAL_FORM_FIELDS.minimumCapacity]: Yup.number()
        .transform(value => !value ? undefined : value)
        .min(MIN, Validations.min(MIN))
        .max(
            Yup.ref(NEW_TRADE_PROPOSAL_FORM_FIELDS.capacityAmount),
            'Minimum capacity cannot be greater than capacity amount'
        )
        .integer(Validations.onlyInteger)
        .nullable(true),
    [NEW_TRADE_PROPOSAL_FORM_FIELDS.expirationDate]: Yup.object()
        .transform(value => !value ? null : value)
        .typeError(Validations.onlyDate)
        .required(Validations.required),
});

export const proposalOptions: ToggleButtonOption[] = [
    {
        label: 'Offer capacity to sell',
        value: ProposalType.SELL
    },
    {
        label: 'Request capacity to buy',
        value: ProposalType.BUY
    }
];

export const periodOptions: ToggleButtonOption[] = [
    {
        label: 'Full period',
        value: PeriodType.FULL
    },
    {
        label: 'Partial period',
        value: PeriodType.PARTIAL
    }
];

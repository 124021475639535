import * as Yup from 'yup';
import { Validations } from '../../../../components/util/validation-utils';

export const RESET_PASSWORD_FORM_FIELDS = {
    password: 'password',
    repeatPassword: 'repeatPassword'
};

const PASSWORD_MIN = 8;
const PASSWORD_MAX = 50;

export const ResetPasswordSchema = Yup.object().shape({
    [RESET_PASSWORD_FORM_FIELDS.repeatPassword]: Yup.string()
        .max(PASSWORD_MAX, Validations.maxLength(PASSWORD_MAX))
        .min(PASSWORD_MIN, Validations.minLength(PASSWORD_MIN))
        .matches(Validations.regex.passwordRegex, Validations.passwordValidation)
        .test(
            'passwordsMatch',
            Validations.passwordsMustMatch,
            function (value: string) {
                if(value !== '' && value !== undefined && value !== null) {
                    return value === this.parent.password;
                }

                return true;
            }
        )
        .required(Validations.required),
    [RESET_PASSWORD_FORM_FIELDS.password]: Yup.string()
        .max(PASSWORD_MAX, Validations.maxLength(PASSWORD_MAX))
        .min(PASSWORD_MIN, Validations.minLength(PASSWORD_MIN))
        .matches(Validations.regex.passwordRegex, Validations.passwordValidation)
        .test(
            'passwordsMatch',
            Validations.passwordsMustMatch,
            function (value: string) {
                if(value !== '' && value !== undefined && value !== null) {
                    return value === this.parent.repeatPassword;
                }

                return true;
            }
        )
        .required(Validations.required)
});

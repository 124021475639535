import React from 'react';
import { Col, Row, Typography } from 'antd';

import './form-section-text.less';

export interface FormSectionTextProps {
    text: string;
}

export const FormSectionText: React.FC<FormSectionTextProps> = (props: FormSectionTextProps) => {
    const {
        text
    } = props;

    return <Row justify="end" gutter={64}>
        <Col xs={14}>
            <Typography.Title level={4} className="form-section-text">
                {text}
            </Typography.Title>
        </Col>
    </Row>;
};
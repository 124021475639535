import React from 'react';
import { Formik, FormikHelpers } from 'formik';

import { Form } from '../../../components/inputs/common/form';
import { FormItem } from '../../../components/inputs/common/form-item';
import { SubmitButton } from '../../../components/button/submit-button';
import { LOGS_FORM_FIELDS, LogsFormModel, LogsSchema } from './logs-schema';
import { Col, Row, Typography } from 'antd';
import { NotificationType, notify } from '../../../service/notification-service';
import { CalendarInput } from '../../../components/inputs/calendar-input/calendar-input';
import { logsService } from '../../../api/logs-service';
import moment, { Moment } from 'moment';
import { AxiosError } from 'axios';

import './logs-form.less';

function disabledDates(current: Moment): boolean {
    return current && (current.set({ s: 0, ms: 0 }) > moment());
}

const initialValues: LogsFormModel = {
    dateFrom: undefined,
    dateUntil: undefined
};

const LogsPage: React.FC = () => {

    function handleSubmit(values: LogsFormModel, formikHelpers: FormikHelpers<LogsFormModel>) {
        logsService.getLogs({
            dateFrom: values.dateFrom && values.dateFrom.set({ s: 0, ms: 0 }).toISOString(),
            dateUntil: values.dateUntil && values.dateUntil.set({ s: 59, ms: 0 }).toISOString()
        })
            .then(() => {
                notify({
                    type: NotificationType.SUCCESS,
                    message: 'Logs generated successfully'
                });
                formikHelpers.setSubmitting(false);
            })
            .catch((error: AxiosError) => {
                if (error.toString().includes('404')) {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'No logs found in selected time period'
                    });
                } else {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Could not generate logs'
                    });
                }
                formikHelpers.setSubmitting(false);
            });
    }

    function renderForm(): React.ReactNode {
        return (
            <Form className="logs-form">
                <Row gutter={64}>
                    <Col xs={10} className="logs-text-wrapper">
                        <Typography.Text>
                            Select date and time
                        </Typography.Text>
                    </Col>
                    <Col xs={14} className="logs-inputs-wrapper">
                        <FormItem name={LOGS_FORM_FIELDS.dateFrom} labelType="vertical" label="From">
                            <CalendarInput
                                name={LOGS_FORM_FIELDS.dateFrom}
                                showTime={true}
                                disabledDate={disabledDates}
                            />
                        </FormItem>
                        <FormItem name={LOGS_FORM_FIELDS.dateUntil} labelType="vertical" label="Until">
                            <CalendarInput
                                name={LOGS_FORM_FIELDS.dateUntil}
                                showTime={true}
                                disabledDate={disabledDates}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row justify="end" gutter={64}>
                    <Col xs={14}>
                        <SubmitButton>
                            Generate report
                        </SubmitButton>
                    </Col>
                </Row>
            </Form>
        );
    }

    return (
        <Row justify="center">
            <Col xs={24} sm={14}>
                <Row gutter={64}>
                    <Col xs={10} className="page-header">
                        <Typography.Title level={4}>
                            Logs
                        </Typography.Title>
                    </Col>
                </Row>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={LogsSchema}
                >
                    {renderForm()}
                </Formik>
            </Col>
        </Row>
    );
};

export { LogsPage };

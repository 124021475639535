import { Button } from 'antd';
import { Field, FieldProps } from 'formik';
import * as React from 'react';
import { ButtonProps } from 'antd/lib/button';

type Props = ButtonProps

const SubmitButton = ({ children, ...rest }: Props) => {
    return <Field>
        {({ form }: FieldProps) => (
            <Button
                disabled={form.isSubmitting}
                type="primary"
                htmlType="submit"
                {...rest}
            >
                {children || 'Submit'}
            </Button>
        )}
    </Field>;
};

export { SubmitButton };

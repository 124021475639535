import React from 'react';
import { Button, Col, Row } from 'antd';
import { FormItem } from '../../../../../components/inputs/common/form-item';
import { CheckboxGroupInput } from '../../../../../components/inputs/checkbox-input/checkbox-group-input';
import {
    CAPACITY_RANGE_MIN,
    TOTAL_PRICE_MIN,
    TRADING_RESULT_REPORT_FILTER_FIELDS
} from './trading-results-reports-filter-form-model';
import { useFormikContext } from 'formik';
import { SelectInput, SelectOptionModel } from '../../../../../components/inputs/select-input/select-input';
import { networkPointService } from '../../../../../api/network-point-service';
import { CalendarRangeInput } from '../../../../../components/inputs/calendar-input/calendar-range-input';
import { SliderInput } from '../../../../../components/inputs/slider-input/slider-input';
import { SubmitButton } from '../../../../../components/button/submit-button';
import { Form } from '../../../../../components/inputs/common/form';
import { companyService } from '../../../../../api/company-service';
import { TradingResultReportsFilterFormModel } from './trading-results-reports-filter-form-model';
import { capacityUnit, dateFormat, priceUnit, proposalCheckboxOptions } from '../../../../../config/constants';

import './../../../trade-proposals/filter/trade-proposal-filter-form.less';

interface TradeProposalFilterFormProps {
    handleReset: () => void;
    maxPrice: number;
    maxCapacity: number;
    disableButtons?: boolean;
}

export const TradingResultReportsFilterForm = (props: TradeProposalFilterFormProps) => {

    const {
        maxPrice,
        maxCapacity,
        disableButtons
    } = props;

    const formik = useFormikContext<TradingResultReportsFilterFormModel>();

    function loadNetworkPoints() {
        return networkPointService.getAllNetworkPoints();
    }

    function loadCompanies() {
        return companyService.getCompanies();
    }

    function mapListItemToOptions(value: {id: number; name: string}): SelectOptionModel {
        return {
            value: value.id,
            label: value.name
        };
    }

    function handleFilterReset() {
        props.handleReset();
    }

    function renderSliders(): React.ReactNode {
        return <Col xs={12} className={'form-col'}>
            <FormItem
                className={'filter-form-item'}
                name={TRADING_RESULT_REPORT_FILTER_FIELDS.capacityRange}
            >
                <SliderInput
                    label={'Capacity amount'}
                    name={TRADING_RESULT_REPORT_FILTER_FIELDS.capacityRange}
                    range={true}
                    unit={capacityUnit}
                    min={CAPACITY_RANGE_MIN}
                    max={maxCapacity + 1}
                    step={1}
                />
            </FormItem>
            <FormItem
                className={'filter-form-item'}
                name={TRADING_RESULT_REPORT_FILTER_FIELDS.totalPriceRange}
            >
                <SliderInput
                    label={'Price'}
                    name={TRADING_RESULT_REPORT_FILTER_FIELDS.totalPriceRange}
                    range={true}
                    unit={priceUnit}
                    min={TOTAL_PRICE_MIN}
                    max={maxPrice}
                    step={0.01}
                />
            </FormItem>
        </Col>;
    }

    function renderFilterFields(): React.ReactNode {
        return <Col xs={12} className={'form-col'}>
            <FormItem name={TRADING_RESULT_REPORT_FILTER_FIELDS.offerTypes}>
                <CheckboxGroupInput
                    className={'offer-options'}
                    name={TRADING_RESULT_REPORT_FILTER_FIELDS.offerTypes}
                    options={proposalCheckboxOptions}
                />
            </FormItem>
            <FormItem
                className={'filter-form-item'}
                name={TRADING_RESULT_REPORT_FILTER_FIELDS.networkPoints}
                labelType={'horizontal'}
                label={'Network points'}
            >
                <SelectInput
                    mode="multiple"
                    name={TRADING_RESULT_REPORT_FILTER_FIELDS.networkPoints}
                    lazyLoad={formik.initialValues.networkPoints.length > 0}
                    service={loadNetworkPoints}
                    mapItemToOptions={mapListItemToOptions}
                    placeholder={'All'}
                />
            </FormItem>
            <FormItem
                className={'filter-form-item'}
                name={TRADING_RESULT_REPORT_FILTER_FIELDS.sellerIds}
                labelType={'horizontal'}
                label={'Seller'}
            >
                <SelectInput
                    mode="multiple"
                    name={TRADING_RESULT_REPORT_FILTER_FIELDS.sellerIds}
                    lazyLoad={formik.initialValues.sellerIds.length > 0}
                    service={loadCompanies}
                    mapItemToOptions={mapListItemToOptions}
                    placeholder={'All'}
                />
            </FormItem>
            <FormItem
                className={'filter-form-item'}
                name={TRADING_RESULT_REPORT_FILTER_FIELDS.buyerIds}
                labelType={'horizontal'}
                label={'Buyer'}
            >
                <SelectInput
                    mode="multiple"
                    name={TRADING_RESULT_REPORT_FILTER_FIELDS.buyerIds}
                    lazyLoad={formik.initialValues.buyerIds.length > 0}
                    service={loadCompanies}
                    mapItemToOptions={mapListItemToOptions}
                    placeholder={'All'}
                />
            </FormItem>
            <FormItem
                className={'filter-form-item'}
                name={TRADING_RESULT_REPORT_FILTER_FIELDS.createdAt}
                labelType={'horizontal'}
                label={'Creation time'}
            >
                <CalendarRangeInput
                    name={TRADING_RESULT_REPORT_FILTER_FIELDS.createdAt}
                    customFormat={dateFormat}
                />
            </FormItem>
            <FormItem
                className={'filter-form-item'}
                name={TRADING_RESULT_REPORT_FILTER_FIELDS.period}
                labelType={'horizontal'}
                label={'Runtime/period'}
            >
                <CalendarRangeInput name={TRADING_RESULT_REPORT_FILTER_FIELDS.period}/>
            </FormItem>
        </Col>;
    }

    return <Form>
        <Row gutter={64}>
            {renderFilterFields()}
            {renderSliders()}
        </Row>
        <SubmitButton className='filter-button' disabled={disableButtons}>
            Filter
        </SubmitButton>
        <Button type="default" onClick={handleFilterReset} disabled={disableButtons}>
            Reset filters
        </Button>
    </Form>;

};

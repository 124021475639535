import React from 'react';
import { Button, Col, Row, Skeleton, Tag, Typography } from 'antd';
import moment from 'moment';

import {
    PeriodType,
    ProposalType
} from '../../pages/private/trade-proposals/new-trade-proposal/form/new-trade-proposal-schema';
import { LabeledText } from './labeled-text';
import { TradeProposalDetails } from '../../api/model/trade-proposal-details';
import { ResponseModel } from '../../api/model/response-model';
import { capacityUnit, dateTimeFormat, displayUpdatedStatusesIn, priceUnit } from '../../config/constants';

export interface TradeProposalInformationProps {
    loading: boolean;
    tradeProposal?: TradeProposalDetails;
    showCancel: boolean;
    ownProposal?: boolean;
    handleCancel?: () => void;
    response?: ResponseModel;
    tradingResultId?: number;
}

export const TradeProposalInformation = (props: TradeProposalInformationProps) => {
    const {
        tradeProposal,
        loading,
        showCancel,
        handleCancel,
        ownProposal,
        response,
        tradingResultId
    } = props;

    return <Skeleton
        loading={loading}
        active={loading}
        paragraph={{ rows: 10 }}
    >
        <Row className={'proposal-info-wrapper'}>
            <Tag
                className={`proposal-type-tag ${tradeProposal?.proposalType === ProposalType.BUY
                    ? 'request'
                    : 'offer'}`
                }
            >
                {tradeProposal?.proposalType === ProposalType.BUY ? 'Request' : 'Offer'}
            </Tag>
            <Typography.Text className={'network-point'}>
                {tradeProposal?.networkPointName}
            </Typography.Text>
        </Row>
        <Row className={'proposal-details'}>
            <Typography.Title level={4} className={'details-header'}>
                Proposal details
            </Typography.Title>
            <Col xs={24}>
                <LabeledText
                    value={`${tradeProposal?.price} ${priceUnit}`}
                    label={'Price'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={`${tradeProposal?.capacityAmount} ${capacityUnit}`}
                    label={'Capacity amount'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={`${moment(tradeProposal?.dateFrom).format(dateTimeFormat)}
                                - ${moment(tradeProposal?.dateUntil).format(dateTimeFormat)}`}
                    label={'Period'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={moment(tradeProposal?.createdAt).format(dateTimeFormat)}
                    label={'Creation time'}
                    labelAlign={'right'}
                />
                {ownProposal && tradeProposal?.lastUpdatedAt && displayUpdatedStatusesIn.includes(tradeProposal.status) &&
                    <LabeledText
                        value={moment(tradeProposal?.lastUpdatedAt).format(dateTimeFormat)}
                        label={'Last status change'}
                        labelAlign={'right'}
                    />
                }
            </Col>
        </Row>
        <Row className={'proposal-details'}>
            <Typography.Title level={4} className={'details-header'}>
                Trading conditions
            </Typography.Title>
            <Col xs={24}>
                <LabeledText
                    value={tradeProposal?.proposalType === ProposalType.SELL
                        ? 'Proposal to sell' : 'Proposal to buy'}
                    label={'Proposal type'}
                    labelAlign={'right'}
                />
                {tradingResultId &&
                    <LabeledText
                        value={tradingResultId.toString()}
                        label={'Trade ID'}
                        labelAlign={'right'}
                    />
                }
                <LabeledText
                    value={tradeProposal?.procedure ?? 'FCFS'}
                    label={'Trading procedure'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={tradeProposal?.periodType === PeriodType.PARTIAL
                        ? `${tradeProposal?.minimumPeriod} ${tradeProposal?.durationType.toLowerCase()}`
                        : 'Full period'}
                    label={'Minimum period'}
                    labelAlign={'right'}
                />
                {tradeProposal?.minimumCapacity !== undefined && tradeProposal.minimumCapacity > 0 &&
                    <LabeledText
                        value={`${tradeProposal?.minimumCapacity} ${capacityUnit}`}
                        label={'Minimum capacity'}
                        labelAlign={'right'}
                    />
                }
                <LabeledText
                    value={moment(tradeProposal?.expirationDate).format(dateTimeFormat)}
                    label={'Expiration date'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={tradeProposal?.capacityCategoryName}
                    label={'Capacity category'}
                    labelAlign={'right'}
                />
            </Col>
        </Row>
        {response &&
            <Row className={'proposal-details'}>
                <Typography.Title level={4} className={'details-header'}>
                    Trade result
                </Typography.Title>
                <Col xs={24}>
                    <LabeledText
                        value={`${response?.capacity} ${capacityUnit}`}
                        label={'Capacity amount'}
                        labelAlign={'right'}
                    />
                    <LabeledText
                        value={`${moment(response?.periodFrom).format(dateTimeFormat)}
                                - ${moment(response?.periodTo).format(dateTimeFormat)}`}
                        label={'Period'}
                        labelAlign={'right'}
                    />
                </Col>
            </Row>
        }
        {showCancel &&
            <Row justify="end">
                <Button onClick={handleCancel} danger={true}>
                    Cancel
                </Button>
            </Row>
        }
    </Skeleton>;
};

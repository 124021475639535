import React from 'react';
import { Button } from 'antd';
import { ColumnProps } from 'antd/es/table';
import Typography from 'antd/lib/typography';
import { EditFilled } from '@ant-design/icons';

import { AccessRight } from '../../../../config/constants';
import { navigationService } from '../../../../service/navigation-service';
import { UserResponse } from '../../../../api/model/user-response';

export const usersColumns: ColumnProps<UserResponse>[] = [
    {
        title: 'Company',
        dataIndex: 'companyName',
        className: 'table-column',
        render: (companyName: string, user: UserResponse) => {
            return <div>
                <div>
                    {companyName}
                </div>
                {user.companiesCount > 0 &&
                    <Typography.Text className="company-count">
                        + {user.companiesCount} more
                    </Typography.Text>
                }
            </div>;
        }
    },
    {
        title: 'User ID',
        dataIndex: 'id',
        className: 'table-column',
        sorter: (a, b) => a.id - b.id,
        showSorterTooltip: false
    },
    {
        title: 'Full name',
        dataIndex: 'firstName',
        className: 'table-column',
        render: (firstName: string, user: UserResponse) => `${firstName} ${user.lastName}`
    },
    {
        title: 'Position',
        dataIndex: 'position',
        className: 'table-column'
    },
    {
        title: 'Rights',
        dataIndex: 'role',
        className: 'table-column',
        render: (role: AccessRight) => role === AccessRight.READ_ONLY_USER ? 'Read only' : 'Full control'
    },
    {
        className: 'table-column',
        render: (text, user) => <Button
            type='link'
            onClick={() => navigationService.goToUserEditPath(user.id)}
            className="edit-button"
        >
            <EditFilled /> Edit
        </Button>
    }
];

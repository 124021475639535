import React, { useEffect, useState } from 'react';
import { SubmitButton } from '../../../../../components/button/submit-button';
import { Form } from '../../../../../components/inputs/common/form';
import { Col, Row, Spin, Typography } from 'antd';
import { FormItem } from '../../../../../components/inputs/common/form-item';
import { TSO_FORM_FIELDS } from './tso-schema';
import { CheckboxGroupInput } from '../../../../../components/inputs/checkbox-input/checkbox-group-input';
import { TsoResponse } from '../../../../../api/model/tso-response';
import { tsoService } from '../../../../../api/tso-service';
import { CheckboxOptionType } from 'antd/es/checkbox';
import { LoadingOutlined } from '@ant-design/icons';

import './../company-registration-form.less';

export const TSOForm: React.FC = () => {

    const [tso, setTso] = useState<TsoResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    function getTsoOptions(): Promise<void> {
        return tsoService.getAllTso()
            .then(response => setTso(response));
    }

    function mapTsoToOptions(tsoOptions: TsoResponse[]): CheckboxOptionType[] {
        return tsoOptions.map(t => {
            return {
                label: t.name,
                value: t.id
            };
        });
    }

    useEffect(() => {
        getTsoOptions()
            .then(() => setLoading(false));
    }, []);

    function renderFields() {
        return <>
            <FormItem name={TSO_FORM_FIELDS.TSO}>
                <Row gutter={64}>
                    <Col xs={10} className="tso-label-wrapper">
                        <Typography.Text className="tso-label">
                            Assign relevant TSO
                        </Typography.Text>
                    </Col>
                    <Col xs={14}>
                        <CheckboxGroupInput
                            name={TSO_FORM_FIELDS.TSO}
                            options={mapTsoToOptions(tso)}
                        />
                    </Col>
                </Row>
            </FormItem>
        </>;
    }

    return (
        <Spin spinning={loading} indicator={<LoadingOutlined/>} size="large">
            <Form className="company-registration-form">
                <Row justify="center">
                    <Col xs={22}>
                        {renderFields()}

                        <Row justify="end" gutter={64}>
                            <Col>
                                <SubmitButton> Save and Submit </SubmitButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

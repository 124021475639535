import React from 'react';
import { Card, Col, Row, Typography, Button } from 'antd';
import { EditFilled, CloseOutlined } from '@ant-design/icons';

import './contact-person-card.less';

export interface ContactPersonCardProps {
    firstName?: string;
    lastName?: string;
    position?: string;
    phone?: string;
    email?: string;
    comment?: string;
    readOnly?: boolean;
    onDelete?: () => void;
    onEdit?: () => void;
}

export const ContactPersonCard: React.FC<ContactPersonCardProps> = (props: ContactPersonCardProps) => {
    const {
        email,
        firstName,
        lastName,
        phone,
        position,
        comment,
        onDelete,
        onEdit,
        readOnly
    } = props;

    return <Card className="contact-person-card">
        <Row justify="space-between">
            <Col xs={12}>
                <Row>
                    <Col>
                        <Typography.Text className="contact-card-name">
                            {firstName} {lastName}
                        </Typography.Text>
                    </Col>
                </Row>
                {position &&
                <Row>
                    <Col>
                        <Typography.Text className="contact-card-contacts">
                            Position: {position}
                        </Typography.Text>
                    </Col>
                </Row>
                }
                {email &&
                    <Row>
                        <Col>
                            <Typography.Text className="contact-card-contacts">
                                Email: {email}
                            </Typography.Text>
                        </Col>
                    </Row>
                }
                {phone &&
                    <Row>
                        <Col>
                            <Typography.Text className="contact-card-contacts">
                                Phone: {phone}
                            </Typography.Text>
                        </Col>
                    </Row>
                }
                {comment &&
                <Row>
                    <Col>
                        <Typography.Text className="contact-card-contacts">
                            Comment: {comment}
                        </Typography.Text>
                    </Col>
                </Row>
                }
            </Col>
            {!readOnly && onDelete && onEdit &&
                <Col xs={12}>
                    <Row justify="end">
                        <Button className="action-button edit-button" onClick={() => onEdit()}>
                            <EditFilled /> Edit
                        </Button>
                        <Button className="action-button delete-button" onClick={() => onDelete()}>
                            <CloseOutlined /> Delete
                        </Button>
                    </Row>
                </Col>
            }
        </Row>
    </Card>;
};

import * as React from 'react';
import { Field, FieldProps } from 'formik';
import { Form as AntdForm } from 'antd';
import { FormProps } from 'antd/lib/form';

import { FormItem } from './form-item';

type Props = FormProps;

const Form = (props: Props) => {

    return <Field>
        {({ form: { handleReset, handleSubmit } }: FieldProps) =>
            <AntdForm onReset={handleReset} onSubmitCapture={handleSubmit} {...props} />
        }
    </Field>;

};

Form.Item = FormItem;

export { Form };

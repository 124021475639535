import * as Yup from 'yup';
import { Validations } from '../../../../components/util/validation-utils';

export const LOGIN_FORM_FIELDS = {
    email: 'email',
    password: 'password'
};

// TODO: change validation rules
const MIN_LENGTH = 10;
const MAX_LENGTH = 50;
const MAX_EMAIL_LENGTH = 100;

export const LoginSchema = Yup.object().shape({
    [LOGIN_FORM_FIELDS.email]: Yup.string()
        .email(Validations.invalidEmail)
        .min(MIN_LENGTH, Validations.minLength(MIN_LENGTH))
        .max(MAX_EMAIL_LENGTH, Validations.maxLength(MAX_EMAIL_LENGTH))
        .required(Validations.required)
        .nullable(),
    [LOGIN_FORM_FIELDS.password]: Yup.string()
        .max(MAX_LENGTH, Validations.maxLength(MAX_LENGTH))
        .required(Validations.required)
        .nullable()
});

import React from 'react';
import { Button, Card } from 'antd';
import { EditFilled } from '@ant-design/icons';

import { TradersListDetailsModel } from '../../../../api/model/traders-list-details-model';

import './traders-list-card.less';

export interface TradersListCardProps {
    tradersList: TradersListDetailsModel;
    readOnly: boolean;
    onEdit: (id: number) => void;
}

export const TradersListCard: React.FC<TradersListCardProps> = (props: TradersListCardProps) => {
    const {
        tradersList,
        readOnly,
        onEdit
    } = props;

    const title = <>
        <div className={'name'}>{tradersList.name}</div>
        <div className={'companies-count'}>{tradersList.companies.length} companies</div>
    </>;
    const edit = tradersList.editable &&
        <Button type={'link'} className={'edit'} onClick={() => onEdit(tradersList.id)}>
            <EditFilled/> Edit
        </Button>;

    return <Card className={'traders-list-card'} title={title} extra={!readOnly && edit}>
        {tradersList.companies.map(c =>
            <p key={c.id} className={'company'}>{c.name}</p>
        )}
    </Card>;
};

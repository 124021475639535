import { CancelSource } from './cancel-source';
import { RestService } from './rest-service';
import { ListItem } from './model/list-item';
import { CapacityCategoryRequest } from './model/capacity-category-request';

class CapacityCategoryService {
    private readonly restService: RestService;

    constructor(cancelSource: CancelSource = new CancelSource()) {
        this.restService = cancelSource.service;
    }

    public readonly createCapacityCategory = (capacityCategoryRequest: CapacityCategoryRequest): Promise<void> => {
        return this.restService.post<void>('/capacityCategory/create', capacityCategoryRequest)
            .then(response => response.data);
    };

    public readonly getCapacityCategoriesByTsoId = (companyId: number, tsoId: number): Promise<ListItem[]> => {
        return this.restService.get<ListItem[]>(`/capacityCategory/${companyId}/${tsoId}`)
            .then(response => response.data);
    };

    public readonly getAllCapacityCategories = (): Promise<ListItem[]> => {
        return this.restService.get<ListItem[]>('/capacityCategory')
            .then(response => response.data);
    };
}

const capacityCategoryService = new CapacityCategoryService();

export { capacityCategoryService, CapacityCategoryService };

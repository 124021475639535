import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { FormikContextType, useFormikContext } from 'formik';
import moment, { Moment } from 'moment';
import { DisabledTimes, EventValue, RangeValue } from 'rc-picker/lib/interface';

import { FormItem } from '../../../../../components/inputs/common/form-item';
import {
    NEW_TRADE_PROPOSAL_FORM_FIELDS,
    NewTradeProposalFormModel, NewTradeProposalFormSchema,
    periodOptions,
    PeriodType,
    proposalOptions
} from './new-trade-proposal-schema';
import { RadioButtonsInput } from '../../../../../components/inputs/radio-input/radio-buttons';
import { CalendarRangeInput } from '../../../../../components/inputs/calendar-input/calendar-range-input';
import { CalendarInput } from '../../../../../components/inputs/calendar-input/calendar-input';
import { CheckboxInput } from '../../../../../components/inputs/checkbox-input/checkbox-input';
import { NumberInput } from '../../../../../components/inputs/number-input/number-input';
import { SelectInput, SelectOptionModel } from '../../../../../components/inputs/select-input/select-input';
import { FormSectionHeader } from '../../../../../components/text/form-section-header';
import { DurationInput } from '../../../../../components/inputs/calendar-input/duration-input';
import { SubmitButton } from '../../../../../components/button/submit-button';
import { networkPointService } from '../../../../../api/network-point-service';
import { capacityCategoryService } from '../../../../../api/capacity-category-service';
import { tradersListService } from '../../../../../api/traders-list-service';
import { NetworkPointListItem } from '../../../../../api/model/network-point-list-item';
import { ListItem } from '../../../../../api/model/list-item';
import { AuthenticationState } from '../../../../../redux/authentication/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { range } from '../../../../../utils/number-utils';
import { UnsavedChangesPrompt } from '../../../../../components/prompt/unsaved-changes-prompt';
import { capacityUnit, priceUnit } from '../../../../../config/constants';
import { NotificationType, notify } from '../../../../../service/notification-service';

import './new-trade-proposal-form.less';

export interface Props {
    handleSave: (formik: FormikContextType<NewTradeProposalFormModel>) => void;
    showDelete: boolean;
    handleDelete: () => void;
    disabled: boolean;
    allNetworkPoints?: ListItem[];
    allCapacityCategories?: ListItem[];
    allTradersLists?: ListItem[];
    networkPoint?: NetworkPointListItem;
}

export const NewTradeProposalForm: React.FC<Props> = (props: Props) => {
    const {
        handleSave,
        disabled,
        showDelete,
        handleDelete,
        allNetworkPoints,
        allCapacityCategories,
        allTradersLists,
        networkPoint
    } = props;

    const formik = useFormikContext<NewTradeProposalFormModel>();

    const [networkPoints, setNetworkPoints] = useState<NetworkPointListItem[]>();
    const [capacityCategories, setCapacityCategories] = useState<ListItem[]>();
    const [selectedNetworkPoint, setSelectNetworkPoint] = useState<NetworkPointListItem>();

    useEffect(() => {
        setSelectNetworkPoint(networkPoint);
    },[networkPoint]);

    const authentication: AuthenticationState = useSelector((state: RootState) => state.authentication);

    function loadNetworkPoints() {
        if (!authentication.selectedCompany?.id) {
            return Promise.reject('Could not load Network points');
        }

        return networkPointService.getNetworkPointsByCompanyId(authentication.selectedCompany.id);
    }

    function loadCapacityCategories() {
        if (!authentication?.selectedCompany?.id || !selectedNetworkPoint) {
            return Promise.reject('Could not load Capacity categories');
        }

        return capacityCategoryService.getCapacityCategoriesByTsoId(
            authentication.selectedCompany.id,
            selectedNetworkPoint.tsoId
        );
    }

    function loadTradersLists() {
        if (!authentication.selectedCompany?.id) {
            return Promise.reject('Could not load Traders lists');
        }

        return tradersListService.getAllTradersLists(authentication.selectedCompany.id);
    }

    function mapListItemToOptions(value: {id: number; name: string}): SelectOptionModel {
        return {
            value: value.id,
            label: value.name
        };
    }

    function calculateGasDayDateTimeOffset(value: Moment | null | undefined): Moment {
        const newValue = value ? value.set({ h: 7, m: 0 }) : moment();

        return selectedNetworkPoint?.expirationTime
            ? moment(newValue).subtract({ hours: selectedNetworkPoint.expirationTime })
            : newValue;
    }

    function handleDisabledTime(currentDate: EventValue<Moment>): DisabledTimes {
        const disabledHours = range(0, 24).splice(calculateGasDayDateTimeOffset(formik.values?.dateDuration?.[0])
            .add(1, 'h')
            .hour(),
        24);

        const disabledMinutes = calculateGasDayDateTimeOffset(formik.values?.dateDuration?.[0])
            .isSame(currentDate, 'hour')
            ? range(1, 59)
            : [];

        return currentDate && currentDate
            .isAfter(calculateGasDayDateTimeOffset(formik.values?.dateDuration?.[0])
                .startOf('day'))
            ? {
                disabledHours: () => disabledHours,
                disabledMinutes: () => disabledMinutes
            }
            : {};
    }

    function handleExpirationDateLimits(current: Moment): boolean {
        current.set({ s: 0, ms: 0 });

        return (current && current < moment().startOf('day'))
            || (formik.values?.dateDuration?.[0]
                ? current > calculateGasDayDateTimeOffset(formik.values.dateDuration[0])
                : false);
    }

    function handleSubmitClick() {
        NewTradeProposalFormSchema.isValid(formik.values).then((isValid: boolean) => {
            !isValid && notify({
                type: NotificationType.WARNING,
                message: 'Could not submit trade proposal',
                description: 'Please check if all fields are valid'
            });
        });

        formik.handleSubmit();
    }

    useEffect(() => {
        formik.touched.networkPointId && formik.values.dateDuration?.[0] && formik.setFieldValue(
            NEW_TRADE_PROPOSAL_FORM_FIELDS.expirationDate,
            calculateGasDayDateTimeOffset(formik.values.dateDuration[0])
        );

        selectedNetworkPoint && !selectedNetworkPoint.tsoAllowsTradersList &&
            formik.setFieldValue(NEW_TRADE_PROPOSAL_FORM_FIELDS.tradersListId, null);

        selectedNetworkPoint && loadCapacityCategories().then((c) => {
            setCapacityCategories(c);
            !formik.values.capacityCategoryId &&
                formik.setFieldValue(NEW_TRADE_PROPOSAL_FORM_FIELDS.capacityCategoryId, c[0].id);
        });
    }, [selectedNetworkPoint]);

    function renderProposalType(): React.ReactNode {
        return <>
            <FormSectionHeader sectionName={'1. Proposal type'}/>
            <FormItem
                name={NEW_TRADE_PROPOSAL_FORM_FIELDS.proposalType}
                labelType="horizontal"
                label="What do you want to do?"
            >
                <RadioButtonsInput
                    disabled={disabled}
                    name={NEW_TRADE_PROPOSAL_FORM_FIELDS.proposalType}
                    options={proposalOptions}
                />
            </FormItem>
        </>;
    }

    function renderProposalDetails(): React.ReactNode {
        const totalPrice = parseFloat(((formik.values.surcharge || 0) + (formik.values.tariffPrice || 0)).toString())
            .toFixed(2);

        return <>
            <FormSectionHeader sectionName={'2. Proposal details'}/>
            <FormItem
                name={NEW_TRADE_PROPOSAL_FORM_FIELDS.networkPointId}
                labelType="horizontal"
                label="Network point"
            >
                <SelectInput
                    disabled={disabled}
                    name={NEW_TRADE_PROPOSAL_FORM_FIELDS.networkPointId}
                    service={loadNetworkPoints}
                    options={allNetworkPoints?.map(n => mapListItemToOptions(n))}
                    callbackService={setNetworkPoints}
                    mapItemToOptions={mapListItemToOptions}
                    onSelect={(value: number) => {
                        formik.setFieldValue(NEW_TRADE_PROPOSAL_FORM_FIELDS.capacityCategoryId, null);
                        setCapacityCategories([]);
                        setSelectNetworkPoint(networkPoints?.find(n => n.id === value));
                    }}
                    allowClear={false}
                    placeholder="Select from the list"
                />
            </FormItem>
            <FormItem
                name={NEW_TRADE_PROPOSAL_FORM_FIELDS.capacityCategoryId}
                labelType="horizontal"
                label="Capacity category"
            >
                <SelectInput
                    name={NEW_TRADE_PROPOSAL_FORM_FIELDS.capacityCategoryId}
                    options={capacityCategories
                        ? capacityCategories.map(e => mapListItemToOptions(e))
                        : allCapacityCategories?.map(c => mapListItemToOptions(c))
                    }
                    mapItemToOptions={mapListItemToOptions}
                    disabled={!formik.values.networkPointId || disabled}
                    allowClear={false}
                    placeholder="Select from the list"
                />
            </FormItem>
            <FormItem
                name={NEW_TRADE_PROPOSAL_FORM_FIELDS.capacityAmount}
                labelType="horizontal"
                label="Capacity amount"
            >
                <NumberInput
                    disabled={disabled}
                    name={NEW_TRADE_PROPOSAL_FORM_FIELDS.capacityAmount}
                    placeholder={capacityUnit}
                    className='number-input'
                />
            </FormItem>
            <FormItem name={NEW_TRADE_PROPOSAL_FORM_FIELDS.dateDuration} labelType="horizontal" label="Period">
                <CalendarRangeInput
                    disabled={disabled}
                    name={NEW_TRADE_PROPOSAL_FORM_FIELDS.dateDuration}
                    disabledDate={(current) => current && current < moment().endOf('day')}
                    onChange={(value: RangeValue<Moment>) =>
                        value
                        && value[0]
                        && formik.setFieldValue(
                            NEW_TRADE_PROPOSAL_FORM_FIELDS.expirationDate,
                            calculateGasDayDateTimeOffset(value[0])
                        )
                    }
                />
            </FormItem>
            <FormItem
                name={NEW_TRADE_PROPOSAL_FORM_FIELDS.tariffPrice}
                labelType="horizontal"
                label="Price"
            >
                <NumberInput
                    disabled={disabled}
                    name={NEW_TRADE_PROPOSAL_FORM_FIELDS.tariffPrice}
                    placeholder={priceUnit}
                    className='number-input'
                />
            </FormItem>
            {/*{selectedNetworkPoint?.reporting && //TODO: uncomment if needed, change price -> tariff price
                    <>
                        <FormItem
                            name={NEW_TRADE_PROPOSAL_FORM_FIELDS.surcharge}
                            labelType="horizontal"
                            label="Surcharge"
                        >
                            <NumberInput
                                disabled={disabled}
                                name={NEW_TRADE_PROPOSAL_FORM_FIELDS.surcharge}
                                placeholder={priceUnit}
                                className='number-input'
                            />
                        </FormItem>
                        <Row gutter={64} className="total-price">
                            <Col xs={10} className="total-price-label">
                                Price:
                            </Col>
                            <Col xs={14} className="total-price-text">
                                {`${totalPrice} ${priceUnit}`}
                            </Col>
                        </Row>
                    </>
            }*/}
        </>;
    }

    function renderTradingConditions(): React.ReactNode {
        return <>
            <FormSectionHeader sectionName={'3. Trading conditions'}/>
            <FormItem name={NEW_TRADE_PROPOSAL_FORM_FIELDS.periodType} labelType="horizontal" label="Minimum period">
                <RadioButtonsInput
                    disabled={disabled}
                    name={NEW_TRADE_PROPOSAL_FORM_FIELDS.periodType}
                    options={periodOptions}
                    onChange={(value) => {
                        if (value === PeriodType.FULL) {
                            formik.setFieldValue(NEW_TRADE_PROPOSAL_FORM_FIELDS.autoAcceptable, false);
                        }
                    }}
                />
            </FormItem>
            <FormItem
                name={NEW_TRADE_PROPOSAL_FORM_FIELDS.minimumPeriod}
                labelType="horizontal"
                label="Minimum acceptable period"
            >
                <DurationInput
                    name={NEW_TRADE_PROPOSAL_FORM_FIELDS.minimumPeriod}
                    durationSelectFieldName={NEW_TRADE_PROPOSAL_FORM_FIELDS.durationType}
                    disabled={formik.values.periodType !== PeriodType.PARTIAL || disabled}
                    className='duration-input'
                />
            </FormItem>
            <FormItem
                name={NEW_TRADE_PROPOSAL_FORM_FIELDS.autoAcceptable}
                labelType="horizontal"
                label="Accept partial period fulfilment automatically"
            >
                <CheckboxInput
                    name={NEW_TRADE_PROPOSAL_FORM_FIELDS.autoAcceptable}
                    disabled={formik.values.periodType !== PeriodType.PARTIAL || disabled}
                />
            </FormItem>
            <FormItem
                name={NEW_TRADE_PROPOSAL_FORM_FIELDS.minimumCapacity}
                labelType="horizontal"
                label="Minimum acceptable capacity"
            >
                <NumberInput
                    disabled={disabled}
                    name={NEW_TRADE_PROPOSAL_FORM_FIELDS.minimumCapacity}
                    placeholder={capacityUnit}
                    className='number-input'
                />
            </FormItem>
            <FormItem name={NEW_TRADE_PROPOSAL_FORM_FIELDS.expirationDate} labelType="horizontal" label="Expiration date">
                <CalendarInput
                    disabled={disabled}
                    name={NEW_TRADE_PROPOSAL_FORM_FIELDS.expirationDate}
                    showTime={true}
                    showNow={false}
                    disabledTime={handleDisabledTime}
                    disabledDate={handleExpirationDateLimits}
                />
            </FormItem>
            <FormItem
                name={NEW_TRADE_PROPOSAL_FORM_FIELDS.tradersListId}
                labelType="horizontal"
                label="Traders list"
            >
                <SelectInput
                    name={NEW_TRADE_PROPOSAL_FORM_FIELDS.tradersListId}
                    disabled={!selectedNetworkPoint?.tsoAllowsTradersList || disabled}
                    service={loadTradersLists}
                    options={allTradersLists?.map(t => mapListItemToOptions(t))}
                    mapItemToOptions={mapListItemToOptions}
                />
            </FormItem>
        </>;
    }

    return (
        <Form className="new-trade-proposal-form">
            {renderProposalType()}
            {renderProposalDetails()}
            {renderTradingConditions()}
            <Row justify="end" gutter={64}>
                <Col xs={14}>
                    <Row justify="space-between" gutter={[0, 24]}>
                        <Col xs={10}>
                            <Button block={true} onClick={() => handleSave(formik)} disabled={formik.isSubmitting || disabled}>
                                Save as draft
                            </Button>
                        </Col>
                        <Col xs={10}>
                            <SubmitButton block={true} onClick={handleSubmitClick} disabled={disabled}>
                                Create proposal
                            </SubmitButton>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={10}>
                            {showDelete &&
                                <Button block={true} danger={true} onClick={() => handleDelete()}
                                    disabled={formik.isSubmitting || disabled}>
                                    Delete draft
                                </Button>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
            <UnsavedChangesPrompt
                when={formik.values !== formik.initialValues && !formik.isSubmitting}
                message={'You have unsaved changes, are you sure you want to leave this page?'}
            />
        </Form>
    );
};

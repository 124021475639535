import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import {
    CAPACITY_RANGE_MIN,
    TOTAL_PRICE_MIN
} from '../trade-proposals/filter/trade-proposal-filter-form-model';
import { ArrayParam, DateTimeParam, NumberParam, useQueryParams } from 'use-query-params';
import { SorterResult } from 'antd/es/table/interface';
import { TablePaginationConfig } from 'antd/lib/table';
import { RootState } from '../../../redux/store';
import { PageResponse } from '../../../api/page-response';
import {
    getInitialDate, mapResponseFormValuesToUrlParams,
    mapUrlParamsToResponseFilterModel,
    parseQueryParamArrayAsNumber,
    parseQueryParamArrayAsString
} from '../../../utils/query-param-utils';
import { ProposalType } from '../trade-proposals/new-trade-proposal/form/new-trade-proposal-schema';
import { ResponseStatus } from '../../../config/constants';
import { responseService } from '../../../api/response-service';
import { NotificationType, notify } from '../../../service/notification-service';
import { FormikHelpers } from 'formik';
import { RecordType, TableComponent } from '../../../components/table/table';
import { TradeProposalsTableHeader } from '../../../components/table/trade-proposals-table-header';
import { navigationService } from '../../../service/navigation-service';
import { DownloadMenu } from '../../../components/menu/download-menu';
import { ResponseListItem } from '../../../api/model/response-list-item';
import { myResponsesColumns } from './my-responses-columns';
import { ResponseFilterFormModel } from './filter/response-filter-form-model';
import { ResponseFilterForm } from './filter/response-filter-form';

const PAGE_SIZE = 20;

export const myResponsesQueryParams = {
    offerTypes: ArrayParam,
    capacityCategories: ArrayParam,
    networkPoints: ArrayParam,
    tsos: ArrayParam,
    procedures: ArrayParam,
    dateFrom: DateTimeParam,
    dateTo: DateTimeParam,
    capacityFrom: NumberParam,
    capacityTo: NumberParam,
    totalPriceFrom: NumberParam,
    totalPriceTo: NumberParam,
    responseStatuses: ArrayParam
};

export const MyResponsesPage: React.FC = () => {
    const authentication = useSelector((state: RootState) => state.authentication);
    const selectedCompanyId = authentication.selectedCompany?.id;

    const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
    const [responses, setResponses] = useState<PageResponse<ResponseListItem>>({
        content: [],
        number: 0,
        totalElements: 0
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [sorts, setSorts] = useState<string | string[]>([]);
    const [currentPage, setCurrentPage] = useState<number>( 0 );
    const [maxPrice, setMaxPrice] = useState<number>(0);
    const [maxCapacity, setMaxCapacity] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);

    const [urlParams, setUrlParams] = useQueryParams(myResponsesQueryParams);

    const initialValues: ResponseFilterFormModel = {
        offerTypes: urlParams.offerTypes
            ? urlParams.offerTypes.filter((value): value is ProposalType => value !== null)
            : [ProposalType.SELL, ProposalType.BUY],
        capacityCategories: urlParams.capacityCategories ? parseQueryParamArrayAsNumber(urlParams.capacityCategories) : [],
        networkPoints: urlParams.networkPoints ? parseQueryParamArrayAsNumber(urlParams.networkPoints) : [],
        tsos: urlParams.tsos ? parseQueryParamArrayAsNumber(urlParams.tsos) : [],
        procedures: urlParams.procedures ? parseQueryParamArrayAsString(urlParams.procedures) : [],
        period: [getInitialDate(urlParams.dateFrom), getInitialDate(urlParams.dateTo)],
        capacityRange: [
            urlParams.capacityFrom ? urlParams.capacityFrom : CAPACITY_RANGE_MIN,
            urlParams.capacityTo ? urlParams.capacityTo : maxCapacity
        ],
        totalPriceRange: [
            urlParams.totalPriceFrom ? urlParams.totalPriceFrom : TOTAL_PRICE_MIN,
            urlParams.totalPriceTo ? urlParams.totalPriceTo : maxPrice
        ],
        responseStatuses: urlParams.responseStatuses
            ? urlParams.responseStatuses.filter((value): value is ResponseStatus => value !== null)
            : []
    };

    useEffect(() => {
        setIsLoading(true);
        selectedCompanyId &&
            responseService.getMaximumFilterValuesByCompany(selectedCompanyId)
                .then(response => {
                    setMaxPrice(response.price);
                    setMaxCapacity(response.capacityAmount);
                    responseService.getResponsesByCompany(
                        selectedCompanyId,
                        sorts ?? undefined,
                        currentPage ?? 0,
                        pageSize,
                        mapUrlParamsToResponseFilterModel(urlParams, response.capacityAmount, response.price)
                    ).then(res =>{
                        setResponses(res);
                        setIsLoading(false);
                    }).catch(() => {
                        notify({
                            type: NotificationType.ERROR,
                            message: 'Could not load responses'
                        });
                    });
                }).catch(() => {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Could not load filter data'
                    });
                });
    }, [currentPage, sorts, urlParams, pageSize]);

    function handleReset() {
        const mappedParams = {
            offerTypes: undefined,
            capacityCategories: undefined,
            networkPoints: undefined,
            tsos: undefined,
            procedures: undefined,
            dateFrom: undefined,
            dateTo: undefined,
            capacityFrom: CAPACITY_RANGE_MIN,
            capacityTo: maxCapacity,
            totalPriceFrom: TOTAL_PRICE_MIN,
            totalPriceTo: maxPrice,
            responseStatuses: undefined
        };

        setUrlParams(mappedParams);
    }

    function handleSubmit(values: ResponseFilterFormModel, formik: FormikHelpers<ResponseFilterFormModel>) {
        formik.setSubmitting(true);
        const mappedParams = mapResponseFormValuesToUrlParams(values);

        setUrlParams(mappedParams);
        setCurrentPage(0);
        formik.setSubmitting(false);
    }

    function handleTableChange(
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<RecordType<ResponseListItem>>
            | SorterResult<RecordType<ResponseListItem>>[]
    ) {
        const sort = Array.isArray(sorter)
            ? sorter.map(s => s.order ? `${s.field},${s.order?.replace('end', '')}` : '')
            : sorter.order ? `${sorter.field},${sorter.order?.replace('end', '')}` : '';

        setSorts(sort);
        setCurrentPage(pagination.current ? pagination.current - 1 : 0);
        pagination.pageSize && setPageSize(pagination.pageSize);
    }

    function handleDownloadClick() {
        selectedCompanyId && responseService.download(
            selectedCompanyId,
            sorts,
            mapUrlParamsToResponseFilterModel(urlParams, maxCapacity, maxPrice)
        ).catch(() => {
            notify({
                type: NotificationType.ERROR,
                message: 'Could not download responses'
            });
        });
    }

    function renderTable(): React.ReactNode {
        return <TableComponent
            filter={{
                form: <ResponseFilterForm
                    handleReset={handleReset}
                    maxCapacity={maxCapacity}
                    maxPrice={maxPrice}
                    disableButtons={isLoading}
                />,
                initialValues,
                handleSubmit,
                filtersVisible
            }}
            data={responses.content}
            columns={myResponsesColumns}
            loading={isLoading}
            onTableChange={handleTableChange}
            pagination={{
                total: !responses ? 1 : responses.totalElements,
                current: !responses ? 1 : responses.number + 1,
                pageSize: pageSize
            }}
            onRowClick={(record: ResponseListItem) => {
                return { onClick: () => {
                    record.status === ResponseStatus.TRADE && record.tradingResultId
                        ? navigationService.goToMyTradingResultsDetailsPath(record.tradingResultId)
                        : navigationService.goToResponseDetailsPath(record.tradeProposalId, record.responseId);
                } };
            }}
        />;
    }

    return (
        <Row justify="center">
            <Col xs={20}>
                <Row justify="space-between" gutter={[64, 24]}>
                    <TradeProposalsTableHeader
                        title={'My responses'}
                        onCheckboxChange={setFiltersVisible}
                        showCreateButton={false}
                    />
                </Row>
                <Row gutter={[64, 24]}>
                    <Col xs={24}>
                        {renderTable()}
                    </Col>
                </Row>
                <Row justify="end">
                    <Col xs={4}>
                        <DownloadMenu onDownload={handleDownloadClick}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

import { ProposalType } from '../new-trade-proposal/form/new-trade-proposal-schema';
import { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { TradeProposalStatus } from '../../../../config/constants';

export enum TRADE_PROPOSAL_FILTER_FIELDS {
    offerTypes = 'offerTypes',
    networkPoints = 'networkPoints',
    tsos = 'tsos',
    procedures = 'procedures',
    period = 'period',
    capacityRange = 'capacityRange',
    totalPriceRange = 'totalPriceRange',
    tradeProposalStatuses = 'tradeProposalStatuses'
}

export interface TradeProposalFilterFormModel {
    offerTypes: ProposalType[];
    networkPoints: number[];
    tsos: number[];
    procedures?: string[];
    period: RangeValue<Moment>;
    capacityRange: [number, number];
    totalPriceRange: [number, number];
    tradeProposalStatuses?: TradeProposalStatus[];
}

export const CAPACITY_RANGE_MIN = 0;
export const TOTAL_PRICE_MIN = 0;

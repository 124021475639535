import React from 'react';
import { Col, Row, Typography } from 'antd';

import './labeled-text.less';

export interface LabeledTextProps {
    value?: string;
    label: string;
    labelAlign: 'right' | 'left';
}

export const LabeledText: React.FC<LabeledTextProps> = (props: LabeledTextProps) => {
    const {
        value,
        label,
        labelAlign
    } = props;

    return <Row justify="space-between" gutter={24}>
        <Col xs={12} className={`labeled-text-wrapper label-align-${labelAlign}`}>
            <Typography.Text className="labeled-text">
                {label}
            </Typography.Text>
        </Col>
        <Col xs={12}>
            <Typography.Text className="labeled-text-value">
                {value}
            </Typography.Text>
        </Col>
    </Row>;
};

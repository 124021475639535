import React from 'react';
import { CheckboxProps as $CheckboxProps } from 'antd/lib/checkbox/Checkbox';
import { Checkbox } from 'antd';
import { Field, FieldProps, useFormikContext, } from 'formik';

import { FormikFieldProps } from '../common/field-props';

import './checkbox-input.less';

interface OwnProps {
    label?: string;
}

export type CheckboxInputProps = FormikFieldProps & $CheckboxProps & OwnProps;

type Props = CheckboxInputProps;

const CheckboxInput: React.FC<Props> = props => {

    const {
        name,
        disabled,
        ...restProps
    } = props;

    const formik = useFormikContext();

    const handleChange = (event: { target: { checked: any } }) => {
        formik.setFieldValue(name, event.target.checked);
        formik.setFieldTouched(name, true);
    };

    return (
        <Field name={name}>
            {({ field: { value }, form: { isSubmitting } }: FieldProps) => (
                <Checkbox
                    name={name}
                    checked={value}
                    onChange={handleChange}
                    disabled={disabled || isSubmitting}
                    {...restProps}
                />
            )}
        </Field>

    );
};

export { CheckboxInput };

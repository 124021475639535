import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { Routes } from './routes';
import { history } from './service/navigation-service';
import { Layout, Spin } from 'antd';
import { AppHeader } from './components/header/header';
import { useDispatch } from 'react-redux';
import { authenticationService } from './api/authentication-service';
import { LoginResponse } from './api/model/login-response';
import { userLogin, userLogout } from './redux/authentication/actions';
import { setAccessToken } from './api/access-token';
import { Role } from './components/auth/private-route';
import { LoadingOutlined } from '@ant-design/icons';

import './app.less';

const { Footer } = Layout;
const moment = require('moment-timezone');

const App: React.FC = () => {

    moment.tz.setDefault('Europe/Vilnius');

    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        authenticationService.refreshToken()
            .then((response: LoginResponse) => {
                const savedSelectedCompany = localStorage.getItem('selectedCompanyId');

                setLoading(false);
                dispatch(userLogin({
                    isLoggedIn: true,
                    role: response.isAdmin ? Role.SYSTEM_ADMIN : Role.SYSTEM_USER,
                    firstName: response.firstName,
                    lastName: response.lastName,
                    companyRoleMap: response.companyRoleMap,
                    selectedCompany: response.companyRoleMap
                        ? savedSelectedCompany !== null && savedSelectedCompany !== ''
                            ? response.companyRoleMap[response.companyRoleMap
                                .findIndex(
                                    c => c.id.toString() === localStorage.getItem('selectedCompanyId')
                                )
                            ]
                            : response.companyRoleMap[0]
                        : undefined
                }));
                setAccessToken(response.accessToken);
            })
            .catch(() => {
                dispatch(userLogout());
                localStorage.setItem('selectedCompanyId', '');
                setLoading(false);
            });
    }, [dispatch]);

    return (
        !isLoading
            ? <Router history={history}>
                <AppHeader/>
                <Routes/>
                <Footer className="app-footer"/>
            </Router>
            : <Spin spinning={isLoading} className="app-loader" indicator={<LoadingOutlined/>} size="large"/>

    );
};

export default hot(App);

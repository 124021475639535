import * as Yup from 'yup';
import { Validations } from '../../../../components/util/validation-utils';

export interface ForgotPasswordFormModel {
    email: string;
}

export const ForgotPasswordFormFields = {
    email: 'email'
};

const MAX_EMAIL_LENGTH = 100;

export const ForgotPasswordSchema = Yup.object().shape({
    [ForgotPasswordFormFields.email]: Yup.string()
        .email(Validations.invalidEmail)
        .max(MAX_EMAIL_LENGTH, Validations.maxLength(MAX_EMAIL_LENGTH))
        .required(Validations.required),
});

import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { AccessRight, TradeProposalStatus } from '../../../../config/constants';
import { RecordType, TableComponent } from '../../../../components/table/table';
import { TradeProposal } from '../table/trade-proposals-columns';
import { ProposalType } from '../new-trade-proposal/form/new-trade-proposal-schema';
import { FormikHelpers } from 'formik';
import { TradeProposalFilterForm } from '../filter/trade-proposal-filter-form';
import {
    CAPACITY_RANGE_MIN,
    TOTAL_PRICE_MIN,
    TradeProposalFilterFormModel
} from '../filter/trade-proposal-filter-form-model';
import { ArrayParam, DateTimeParam, NumberParam, useQueryParams } from 'use-query-params';
import { tradeProposalService } from '../../../../api/trade-proposal-service';
import { SorterResult } from 'antd/es/table/interface';
import { TablePaginationConfig } from 'antd/lib/table';
import { PageResponse } from '../../../../api/page-response';
import {
    getInitialDate,
    mapFormValuesToUrlParams,
    mapUrlParamsToFilterModel,
    parseQueryParamArrayAsNumber
} from '../../../../utils/query-param-utils';
import { NotificationType, notify } from '../../../../service/notification-service';
import { DownloadMenu } from '../../../../components/menu/download-menu';
import { myTradeProposalsColumns } from '../table/my-trade-proposals-columns';
import { TradeProposalsTableHeader } from '../../../../components/table/trade-proposals-table-header';
import { navigationService } from '../../../../service/navigation-service';
import { responseService } from '../../../../api/response-service';
import { authenticationService } from '../../../../api/authentication-service';
import { LoginResponse } from '../../../../api/model/login-response';
import { userLogin, userLogout } from '../../../../redux/authentication/actions';
import { Role } from '../../../../components/auth/private-route';
import { setAccessToken } from '../../../../api/access-token';

import './../trade-proposals-page.less';

const PAGE_SIZE = 20;

export const myTradeProposalsQueryParams = {
    tsos: ArrayParam,
    networkPoints: ArrayParam,
    dateFrom: DateTimeParam,
    dateTo: DateTimeParam,
    offerTypes: ArrayParam,
    capacityFrom: NumberParam,
    capacityTo: NumberParam,
    totalPriceFrom: NumberParam,
    totalPriceTo: NumberParam,
    tradeProposalStatuses: ArrayParam
};

export const MyTradeProposalsPage: React.FC = () => {
    const authentication = useSelector((state: RootState) => state.authentication);
    const isFullControlUser = authentication.selectedCompany?.role === AccessRight.FULL_CONTROL_USER ;
    const selectedCompanyId = authentication.selectedCompany?.id;
    const dispatch = useDispatch();

    const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
    const [tradeProposals, setTradeProposals] = useState<PageResponse<TradeProposal>>({
        content: [],
        number: 0,
        totalElements: 0
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [sorts, setSorts] = useState<string | string[]>([]);
    const [currentPage, setCurrentPage] = useState<number>( 0 );
    const [maxPrice, setMaxPrice] = useState<number>(0);
    const [maxCapacity, setMaxCapacity] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);

    const [urlParams, setUrlParams] = useQueryParams(myTradeProposalsQueryParams);

    const initialValues: TradeProposalFilterFormModel = {
        networkPoints: urlParams.networkPoints ? parseQueryParamArrayAsNumber(urlParams.networkPoints) : [],
        tsos: urlParams.tsos ? parseQueryParamArrayAsNumber(urlParams.tsos) : [],
        period: [getInitialDate(urlParams.dateFrom), getInitialDate(urlParams.dateTo)],
        offerTypes: urlParams.offerTypes
            ? urlParams.offerTypes.filter((value): value is ProposalType => value !== null)
            : [ProposalType.SELL, ProposalType.BUY],
        capacityRange: [
            urlParams.capacityFrom ? urlParams.capacityFrom : CAPACITY_RANGE_MIN,
            urlParams.capacityTo ? urlParams.capacityTo : maxCapacity
        ],
        totalPriceRange: [
            urlParams.totalPriceFrom ? urlParams.totalPriceFrom : TOTAL_PRICE_MIN,
            urlParams.totalPriceTo ? urlParams.totalPriceTo : maxPrice
        ],
        tradeProposalStatuses: urlParams.tradeProposalStatuses
            ? urlParams.tradeProposalStatuses.filter((value): value is TradeProposalStatus => value !== null)
            : []
    };

    useEffect(() => {
        setIsLoading(true);
        selectedCompanyId &&
        authenticationService.refreshToken()
            .then((r: LoginResponse) => {
                const savedSelectedCompany = localStorage.getItem('selectedCompanyId');

                dispatch(userLogin({
                    isLoggedIn: true,
                    role: r.isAdmin ? Role.SYSTEM_ADMIN : Role.SYSTEM_USER,
                    firstName: r.firstName,
                    lastName: r.lastName,
                    companyRoleMap: r.companyRoleMap,
                    selectedCompany: r.companyRoleMap
                        ? savedSelectedCompany !== null && savedSelectedCompany !== ''
                            ? r.companyRoleMap[r.companyRoleMap
                                .findIndex(
                                    c => c.id.toString() === localStorage.getItem('selectedCompanyId')
                                )
                            ]
                            : r.companyRoleMap[0]
                        : undefined
                }));

                tradeProposalService.getMaximumFilterValuesByCompany(selectedCompanyId)
                    .then(response => {
                        setMaxPrice(response.price);
                        setMaxCapacity(response.capacityAmount);
                        tradeProposalService.getTradeProposalsByCompany(
                            selectedCompanyId,
                            sorts ?? undefined,
                            currentPage ?? 0,
                            pageSize,
                            mapUrlParamsToFilterModel(urlParams, response.capacityAmount, response.price)
                        ).then(res =>{
                            setTradeProposals(res);
                            setIsLoading(false);
                        }).catch(() => {
                            notify({
                                type: NotificationType.ERROR,
                                message: 'Could not load trade proposals'
                            });
                        });
                    }).catch(() => {
                        notify({
                            type: NotificationType.ERROR,
                            message: 'Could not load filter data'
                        });
                    });
                setAccessToken(r.accessToken);
            })
            .catch(() => {
                dispatch(userLogout());
                localStorage.setItem('selectedCompanyId', '');
                setIsLoading(false);
            });
    }, [currentPage, sorts, urlParams, pageSize]);

    function handleReset() {
        const mappedParams = {
            tsos: undefined,
            networkPoints: undefined,
            capacityFrom: CAPACITY_RANGE_MIN,
            capacityTo: maxCapacity,
            offerTypes: undefined,
            totalPriceFrom: TOTAL_PRICE_MIN,
            totalPriceTo: maxPrice,
            dateFrom: undefined,
            dateTo: undefined,
            tradeProposalStatuses: undefined
        };

        setUrlParams(mappedParams);
    }

    function handleSubmit(values: TradeProposalFilterFormModel, formik: FormikHelpers<TradeProposalFilterFormModel>) {
        formik.setSubmitting(true);
        const mappedParams = mapFormValuesToUrlParams(values);

        setUrlParams(mappedParams);
        setCurrentPage(0);
        formik.setSubmitting(false);
    }

    function handleTableChange(
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<RecordType<TradeProposal>>
            | SorterResult<RecordType<TradeProposal>>[]
    ) {
        const sort = Array.isArray(sorter)
            ? sorter.map(s => s.order ? `${s.field},${s.order?.replace('end', '')}` : '')
            : sorter.order ? `${sorter.field},${sorter.order?.replace('end', '')}` : '';

        setSorts(sort);
        setCurrentPage(pagination.current ? pagination.current - 1 : 0);
        pagination.pageSize && setPageSize(pagination.pageSize);
    }

    function handleDownloadClick() {
        selectedCompanyId && tradeProposalService.download(
            selectedCompanyId,
            sorts,
            mapUrlParamsToFilterModel(urlParams, maxCapacity, maxPrice)
        ).catch(() => {
            notify({
                type: NotificationType.ERROR,
                message: 'Could not download trade proposals'
            });
        });
    }

    function handleAcceptResponse(record: TradeProposal) {
        responseService.answerResponse(true, record.id)
            .then(() => {
                setCurrentPage(0);
                handleReset();
                notify({
                    type: NotificationType.SUCCESS,
                    message: 'Response accepted'
                });
            })
            .catch(() => {
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not accept response'
                });
            });
    }

    function handleRejectResponse(record: TradeProposal) {
        Modal.destroyAll();
        responseService.answerResponse(false, record.id)
            .then(() => {
                setCurrentPage(0);
                handleReset();
                notify({
                    type: NotificationType.SUCCESS,
                    message: 'Response rejected'
                });
            })
            .catch(() => {
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not reject response'
                });
            });
    }

    function renderTable(): React.ReactNode {
        return <TableComponent
            filter={{
                form: <TradeProposalFilterForm
                    handleReset={handleReset}
                    isPublic={false}
                    maxCapacity={maxCapacity}
                    maxPrice={maxPrice}
                    disableButtons={isLoading}
                />,
                initialValues,
                handleSubmit,
                filtersVisible
            }}
            data={tradeProposals.content}
            columns={myTradeProposalsColumns(handleAcceptResponse, handleRejectResponse, isFullControlUser)}
            loading={isLoading}
            onTableChange={handleTableChange}
            pagination={{
                total: !tradeProposals ? 1 : tradeProposals.totalElements,
                current: !tradeProposals ? 1 : tradeProposals.number + 1,
                pageSize: pageSize
            }}
            onRowClick={(record: TradeProposal) => {
                return { onClick: () => {
                    switch (record.status) {
                        case TradeProposalStatus.DRAFT:
                            navigationService.goToEditTradeProposalPath(record.id);
                            break;
                        case TradeProposalStatus.TRADE:
                            record.tradingResultId &&
                                navigationService.goToMyTradingResultsDetailsPath(record.tradingResultId);
                            break;
                        default:
                            navigationService.goToTradeProposalDetailsPath(record.id);
                    }
                } };
            }}
        />;
    }

    return (
        <Row justify="center">
            <Col xs={20}>
                <Row justify="space-between" gutter={[64, 24]}>
                    <TradeProposalsTableHeader
                        title={'My proposals'}
                        onCheckboxChange={setFiltersVisible}
                        showCreateButton={isFullControlUser}
                    />
                </Row>
                <Row gutter={[64, 24]}>
                    <Col xs={24}>
                        {renderTable()}
                    </Col>
                </Row>
                <Row justify="end">
                    <Col xs={4}>
                        <DownloadMenu onDownload={handleDownloadClick}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

import moment, { Moment } from 'moment';
import { EventValue } from 'rc-picker/lib/interface';
import { TradeProposalFilterModel } from '../api/model/trade-proposal-filter-model';
import {
    CAPACITY_RANGE_MIN,
    TOTAL_PRICE_MIN, TradeProposalFilterFormModel
} from '../pages/private/trade-proposals/filter/trade-proposal-filter-form-model';
import { ProposalType } from '../pages/private/trade-proposals/new-trade-proposal/form/new-trade-proposal-schema';
import { ResponseStatus, TradeProposalStatus } from '../config/constants';
import { ResponseFilterModel } from '../api/model/response-filter-model';
import { ResponseFilterFormModel } from '../pages/private/responses/filter/response-filter-form-model';
import { TradingResultsFilterModel } from '../api/model/trading-results-filter-model';
import { TradingResultsFilterFormModel } from '../pages/private/trading-results/filter/trading-results-filter-form-model';
import { TradeProposalReportsFilterModel } from '../api/model/trade-proposal-reports-filter-model';
import { TradeProposalReportsFilterFormModel } from '../pages/private/reports/trade-proposals/filter/trade-proposal-reports-filter-form-model';
import { ResponseReportsFilterModel } from '../api/model/response-reports-filter-model';
import { ResponseReportsFilterFormModel } from '../pages/private/reports/responses/filter/response-reports-filter-form-model';
import { TradingResultReportFilterModel } from '../api/model/trading-result-report-filter-model';
import { TradingResultReportsFilterFormModel } from '../pages/private/reports/trading-results/filter/trading-results-reports-filter-form-model';
import { DecodedValueMap } from 'use-query-params';
import { tradeProposalsQueryParams } from '../pages/private/trade-proposals/trade-proposals-page';
import { myTradeProposalsQueryParams } from '../pages/private/trade-proposals/my-trade-proposals/my-trade-proposals-page';
import { myResponsesQueryParams } from '../pages/private/responses/my-responses-page';
import { myTradingResultsQueryParams } from '../pages/private/trading-results/my-trading-results/my-trading-results-page';
import { publicTradingResultsQueryParams } from '../pages/private/trading-results/public-trading-results/trading-results-page';
import { tradeProposalReportsQueryParams } from '../pages/private/reports/trade-proposals/trade-proposal-reports-page';
import { tradingResultsReportsQueryParams } from '../pages/private/reports/trading-results/trading-results-reports-page';
import { responseReportsQueryParams } from '../pages/private/reports/responses/response-reports-page';

export function parseQueryParamAsNumber(value: string): number {
    return parseInt(value);
}

export function parseQueryParamArrayAsNumber(values: (string | null)[]): number[] {
    return values.filter((value): value is string => value !== null).map(parseQueryParamAsNumber);
}

export function parseQueryParamArrayAsString(values: (string | null)[]): string[] {
    return values.filter((value): value is string => value !== null);
}

export function getInitialDate(date: Date | null | undefined): EventValue<Moment> {
    return date ? moment(date) : null;
}

export function mapUrlParamsToFilterModel(
    params: DecodedValueMap<typeof tradeProposalsQueryParams | typeof myTradeProposalsQueryParams>,
    capacity: number,
    price: number
): TradeProposalFilterModel {
    return {
        offerTypes: params.offerTypes
            ? params.offerTypes.filter((value: string | null): value is ProposalType => value !== null)
            : [ProposalType.SELL, ProposalType.BUY],
        networkPoints: params.networkPoints ? parseQueryParamArrayAsNumber(params.networkPoints) : [],
        tsos: params.tsos ? parseQueryParamArrayAsNumber(params.tsos) : [],
        procedures: 'procedures' in params ?
            params.procedures
                ? parseQueryParamArrayAsString(params.procedures)
                : []
            : [],
        periodFrom: getInitialDate(params.dateFrom)?.toISOString(),
        periodTo: getInitialDate(params.dateTo)?.toISOString(),
        capacityFrom: params.capacityFrom ?? CAPACITY_RANGE_MIN,
        capacityTo: params.capacityTo ?? capacity,
        totalPriceFrom: params.totalPriceFrom ?? TOTAL_PRICE_MIN,
        totalPriceTo: params.totalPriceTo ?? price,
        tradeProposalStatuses: 'tradeProposalStatuses' in params
            ? params?.tradeProposalStatuses
                ? params?.tradeProposalStatuses.filter((value: string | null): value is TradeProposalStatus => value !== null)
                : []
            : []
    };
}

export function mapFormValuesToUrlParams(
    values: TradeProposalFilterFormModel
): DecodedValueMap<typeof tradeProposalsQueryParams | typeof myTradeProposalsQueryParams> {
    return {
        offerTypes: (values.offerTypes),
        networkPoints: (values.networkPoints.map(t => t.toString())),
        tsos: (values.tsos.map(t => t.toString())),
        procedures: (values.procedures?.map(t => t.toString())),
        dateFrom: values.period
            && values.period[0]
            && moment(values.period[0]).set({ h: 7, m: 0, s: 0, ms: 0 }).toDate(),
        dateTo: values.period
            && values.period[1]
            && moment(values.period[1]).set({ h: 7, m: 0, s: 0, ms: 0 }).toDate(),
        capacityFrom: (values.capacityRange[0]),
        capacityTo: (values.capacityRange[1]),
        totalPriceFrom: (values.totalPriceRange[0]),
        totalPriceTo: (values.totalPriceRange[1]),
        tradeProposalStatuses: values.tradeProposalStatuses
    };
}

export function mapUrlParamsToResponseFilterModel(params: DecodedValueMap<typeof myResponsesQueryParams>, capacity: number, price: number): ResponseFilterModel {
    return {
        offerTypes: params.offerTypes
            ? params.offerTypes.filter((value: string | null): value is ProposalType => value !== null)
            : [ProposalType.SELL, ProposalType.BUY],
        capacityCategories: params.capacityCategories ? parseQueryParamArrayAsNumber(params.capacityCategories) : [],
        networkPoints: params.networkPoints ? parseQueryParamArrayAsNumber(params.networkPoints) : [],
        tsos: params.tsos ? parseQueryParamArrayAsNumber(params.tsos) : [],
        procedures: params.procedures ? parseQueryParamArrayAsString(params.procedures) : [],
        periodFrom: getInitialDate(params.dateFrom)?.toISOString(),
        periodTo: getInitialDate(params.dateTo)?.toISOString(),
        capacityFrom: params.capacityFrom ?? CAPACITY_RANGE_MIN,
        capacityTo: params.capacityTo ?? capacity,
        totalPriceFrom: params.totalPriceFrom ?? TOTAL_PRICE_MIN,
        totalPriceTo: params.totalPriceTo ?? price,
        responseStatuses: params.responseStatuses
            ? params.responseStatuses.filter((value: string | null): value is ResponseStatus => value !== null)
            : []
    };
}

export function mapResponseFormValuesToUrlParams(
    values: ResponseFilterFormModel
): DecodedValueMap<typeof myResponsesQueryParams> {

    return {
        offerTypes: (values.offerTypes),
        capacityCategories: values.capacityCategories.map(t => t.toString()),
        networkPoints: (values.networkPoints.map(t => t.toString())),
        tsos: (values.tsos.map(t => t.toString())),
        procedures: (values.procedures.map(t => t.toString())),
        dateFrom: values.period
            && values.period[0]
            && moment(values.period[0]).set({ h: 7, m: 0, s: 0, ms: 0 }).toDate(),
        dateTo: values.period
            && values.period[1]
            && moment(values.period[1]).set({ h: 7, m: 0, s: 0, ms: 0 }).toDate(),
        capacityFrom: (values.capacityRange[0]),
        capacityTo: (values.capacityRange[1]),
        totalPriceFrom: (values.totalPriceRange[0]),
        totalPriceTo: (values.totalPriceRange[1]),
        responseStatuses: values.responseStatuses
    };
}

export function mapUrlParamsToTradingResultsFilterModel(
    params: DecodedValueMap<typeof myTradingResultsQueryParams> | DecodedValueMap<typeof publicTradingResultsQueryParams>,
    capacity: number,
    price: number
): TradingResultsFilterModel {
    return {
        offerTypes: params.offerTypes
            ? params.offerTypes.filter((value: string | null): value is ProposalType => value !== null)
            : [ProposalType.SELL, ProposalType.BUY],
        capacityCategories: 'capacityCategories' in params
            ? params.capacityCategories
                ? parseQueryParamArrayAsNumber(params.capacityCategories)
                : []
            : undefined,
        networkPoints: params.networkPoints ? parseQueryParamArrayAsNumber(params.networkPoints) : [],
        counterparties: 'counterparties' in params
            ? params.counterparties
                ? parseQueryParamArrayAsNumber(params.counterparties)
                : []
            : [],
        tsos: params.tsos ? parseQueryParamArrayAsNumber(params.tsos) : [],
        procedures: params.procedures ? parseQueryParamArrayAsString(params.procedures) : [],
        periodFrom: getInitialDate(params.dateFrom)?.toISOString(),
        periodTo: getInitialDate(params.dateTo)?.toISOString(),
        createdAtFrom: getInitialDate(params.createdAtFrom)?.toISOString(),
        createdAtTo: getInitialDate(params.createdAtTo)?.toISOString(),
        capacityFrom: params.capacityFrom ?? CAPACITY_RANGE_MIN,
        capacityTo: params.capacityTo ?? capacity,
        totalPriceFrom: params.totalPriceFrom ?? TOTAL_PRICE_MIN,
        totalPriceTo: params.totalPriceTo ?? price
    };
}

export function mapTradingResultFormValuesToUrlParams(
    values: TradingResultsFilterFormModel
): DecodedValueMap<typeof myTradingResultsQueryParams | typeof publicTradingResultsQueryParams> {
    return {
        offerTypes: (values.offerTypes),
        capacityCategories: values.capacityCategories ? values.capacityCategories.map(c => c.toString()) : undefined,
        networkPoints: (values.networkPoints.map(t => t.toString())),
        counterparties: values.counterparties ? values.counterparties.map(c => c.toString()) : undefined,
        tsos: (values.tsos.map(t => t.toString())),
        procedures: (values.procedures.map(t => t.toString())),
        dateFrom: values.period
            && values.period[0]
            && moment(values.period[0]).set({ h: 7, m: 0, s: 0, ms: 0 }).toDate(),
        dateTo: values.period
            && values.period[1]
            && moment(values.period[1]).set({ h: 7, m: 0, s: 0, ms: 0 }).toDate(),
        createdAtFrom: values.createdAt
            && values.createdAt[0]
            && moment(values.createdAt[0]).startOf('day').toDate(),
        createdAtTo: values.createdAt
            && values.createdAt[1]
            && moment(values.createdAt[1]).endOf('day').toDate(),
        capacityFrom: (values.capacityRange[0]),
        capacityTo: (values.capacityRange[1]),
        totalPriceFrom: (values.totalPriceRange[0]),
        totalPriceTo: (values.totalPriceRange[1])
    };
}

export function mapTradeProposalReportsUrlParamsToFilterModel(
    params: DecodedValueMap<typeof tradeProposalReportsQueryParams>,
    capacity: number,
    price: number
): TradeProposalReportsFilterModel {
    return {
        capacityFrom: params.capacityFrom ?? CAPACITY_RANGE_MIN,
        capacityTo: params.capacityTo ?? capacity,
        companyIds: params.companies ? parseQueryParamArrayAsNumber(params.companies) : [],
        networkPoints: params.networkPoints ? parseQueryParamArrayAsNumber(params.networkPoints) : [],
        offerTypes: params.offerTypes
            ? params.offerTypes.filter((value: string | null): value is ProposalType => value !== null)
            : [ProposalType.SELL, ProposalType.BUY],
        submittedAtFrom: getInitialDate(params.submittedAtFrom)?.toISOString(),
        submittedAtTo: getInitialDate(params.submittedAtTo)?.toISOString(),
        periodFrom: getInitialDate(params.dateFrom)?.toISOString(),
        periodTo: getInitialDate(params.dateTo)?.toISOString(),
        totalPriceFrom: params.totalPriceFrom ?? TOTAL_PRICE_MIN,
        totalPriceTo: params.totalPriceTo ?? price,
        tsos: params.tsos ? parseQueryParamArrayAsNumber(params.tsos) : [],
        tradeProposalStatuses: params.tradeProposalStatuses
            ? params.tradeProposalStatuses.filter((value: string | null): value is TradeProposalStatus => value !== null)
            : []
    };
}

export function mapTradeProposalReportsFormValuesToUrlParams(
    values: TradeProposalReportsFilterFormModel
): DecodedValueMap<typeof tradeProposalReportsQueryParams> {
    return {
        tsos: (values.tsos.map(t => t.toString())),
        companies: values.companies.map(c => c.toString()),
        submittedAtFrom: values.submittedAt
            && values.submittedAt[0]
            && moment(values.submittedAt[0]).startOf('day').toDate(),
        submittedAtTo: values.submittedAt
            && values.submittedAt[1]
            && moment(values.submittedAt[1]).endOf('day').toDate(),
        networkPoints: (values.networkPoints.map(t => t.toString())),
        capacityFrom: (values.capacityRange[0]),
        capacityTo: (values.capacityRange[1]),
        offerTypes: (values.offerTypes),
        totalPriceFrom: (values.totalPriceRange[0]),
        totalPriceTo: (values.totalPriceRange[1]),
        dateFrom: values.period
            && values.period[0]
            && moment(values.period[0]).set({ h: 7, m: 0, s: 0, ms: 0 }).toDate(),
        dateTo: values.period
            && values.period[1]
            && moment(values.period[1]).set({ h: 7, m: 0, s: 0, ms: 0 }).toDate(),
        tradeProposalStatuses: values.tradeProposalStatuses
    };
}

export function mapUrlParamsToTradingResultReportsFilterModel(
    params: DecodedValueMap<typeof tradingResultsReportsQueryParams>,
    capacity: number,
    price: number
): TradingResultReportFilterModel {
    return {
        capacityFrom: params.capacityFrom ?? CAPACITY_RANGE_MIN,
        capacityTo: params.capacityTo ?? capacity,
        networkPoints: params.networkPoints ? parseQueryParamArrayAsNumber(params.networkPoints) : [],
        buyerIds: params.buyerIds ? parseQueryParamArrayAsNumber(params.buyerIds) : [],
        sellerIds: params.sellerIds ? parseQueryParamArrayAsNumber(params.sellerIds) : [],
        offerTypes: params.offerTypes
            ? params.offerTypes.filter((value: string | null): value is ProposalType => value !== null)
            : [ProposalType.SELL, ProposalType.BUY],
        periodFrom: getInitialDate(params.dateFrom)?.toISOString(),
        periodTo: getInitialDate(params.dateTo)?.toISOString(),
        totalPriceFrom: params.totalPriceFrom ?? TOTAL_PRICE_MIN,
        totalPriceTo: params.totalPriceTo ?? price,
        createdAtFrom: getInitialDate(params.createdAtFrom)?.toISOString(),
        createdAtTo: getInitialDate(params.createdAtTo)?.toISOString()
    };
}

export function mapTradingResultReportsFormValuesToUrlParams(
    values: TradingResultReportsFilterFormModel
): DecodedValueMap<typeof tradingResultsReportsQueryParams> {
    return {
        networkPoints: (values.networkPoints.map(t => t.toString())),
        buyerIds: (values.buyerIds.map(t => t.toString())),
        sellerIds: (values.sellerIds.map(t => t.toString())),
        capacityFrom: (values.capacityRange[0]),
        capacityTo: (values.capacityRange[1]),
        offerTypes: (values.offerTypes),
        totalPriceFrom: (values.totalPriceRange[0]),
        totalPriceTo: (values.totalPriceRange[1]),
        dateFrom: values.period
            && values.period[0]
            && moment(values.period[0]).set({ h: 7, m: 0, s: 0, ms: 0 }).toDate(),
        dateTo: values.period
            && values.period[1]
            && moment(values.period[1]).set({ h: 7, m: 0, s: 0, ms: 0 }).toDate(),
        createdAtFrom: values.createdAt
            && values.createdAt[0]
            && moment(values.createdAt[0]).startOf('day').toDate(),
        createdAtTo: values.createdAt
            && values.createdAt[1]
            && moment(values.createdAt[1]).endOf('day').toDate(),
    };
}

export function mapResponseReportsUrlParamsToFilterModel(
    params: DecodedValueMap<typeof responseReportsQueryParams>,
    capacity: number,
    price: number
): ResponseReportsFilterModel {
    return {
        capacityFrom: params.capacityFrom ?? CAPACITY_RANGE_MIN,
        capacityTo: params.capacityTo ?? capacity,
        companyIds: params.companies ? parseQueryParamArrayAsNumber(params.companies) : [],
        networkPoints: params.networkPoints ? parseQueryParamArrayAsNumber(params.networkPoints) : [],
        offerTypes: params.offerTypes
            ? params.offerTypes.filter((value: string | null): value is ProposalType => value !== null)
            : [ProposalType.SELL, ProposalType.BUY],
        submittedFrom: getInitialDate(params.submittedFrom)?.toISOString(),
        submittedTo: getInitialDate(params.submittedTo)?.toISOString(),
        periodFrom: getInitialDate(params.dateFrom)?.toISOString(),
        periodTo: getInitialDate(params.dateTo)?.toISOString(),
        totalPriceFrom: params.totalPriceFrom ?? TOTAL_PRICE_MIN,
        totalPriceTo: params.totalPriceTo ?? price,
        statuses: params.statuses
            ? params.statuses.filter((value: string | null): value is ResponseStatus => value !== null)
            : []
    };
}

export function mapResponseReportsFormValuesToUrlParams(
    values: ResponseReportsFilterFormModel
): DecodedValueMap<typeof responseReportsQueryParams> {
    return {
        companies: values.companies.map(c => c.toString()),
        submittedFrom: values.submittedAt && values.submittedAt[0]
            && moment(values.submittedAt[0]).startOf('day').toDate(),
        submittedTo: values.submittedAt && values.submittedAt[1]
            && moment(values.submittedAt[1]).endOf('day').toDate(),
        networkPoints: values.networkPoints.map(t => t.toString()),
        capacityFrom: values.capacityRange[0],
        capacityTo: values.capacityRange[1],
        offerTypes: values.offerTypes,
        totalPriceFrom: values.totalPriceRange[0],
        totalPriceTo: values.totalPriceRange[1],
        dateFrom: values.period && values.period[0]
            && moment(values.period[0]).set({ h: 7, m: 0, s: 0, ms: 0 }).toDate(),
        dateTo: values.period && values.period[1]
            && moment(values.period[1]).set({ h: 7, m: 0, s: 0, ms: 0 }).toDate(),
        statuses: values.statuses
    };
}

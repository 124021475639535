import React from 'react';
import { Modal } from 'antd';
import { ColumnProps } from 'antd/es/table';
import Avatar from 'antd/lib/avatar/avatar';
import moment from 'moment';

import { ProposalType } from '../new-trade-proposal/form/new-trade-proposal-schema';
import { TradeProposal } from './trade-proposals-columns';
import { capacityUnit, dateTimeFormat, priceUnit, TradeProposalStatus } from '../../../../config/constants';
import { TradeProposalStatusText } from '../../../../components/text/trade-proposal-status-text';
import { responseService } from '../../../../api/response-service';
import { NotificationType, notify } from '../../../../service/notification-service';
import { ResponseInformationText } from '../../../../components/text/response-information-text';

import './my-trade-proposals-columns.less';

export const myTradeProposalsColumns = (
    handleAccept: (record: TradeProposal) => void,
    handleReject: (record: TradeProposal) => void,
    isFullControlUser: boolean
): ColumnProps<TradeProposal>[] => {
    return [{
        title: 'Type',
        dataIndex: 'proposalType',
        className: 'table-column',
        render: (proposalType: ProposalType) => {
            return proposalType === ProposalType.SELL
                ? <Avatar className='offer'> O </Avatar>
                : <Avatar className='request'> R </Avatar>;
        },
        sorter: {
            compare: () => { return 0; },
            multiple: 1
        },
        showSorterTooltip: false
    },
    {
        title: 'Network point',
        dataIndex: 'networkPointName',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 2
        },
        showSorterTooltip: false,
        render: (networkPointName: string) => networkPointName ? networkPointName : '-'
    },
    {
        title: 'TSO',
        dataIndex: 'tsoName',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 3
        },
        showSorterTooltip: false,
        render: (tsoName: string) => tsoName ? tsoName : '-'
    },
    {
        title: 'Category',
        dataIndex: 'categoryName',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 4
        },
        showSorterTooltip: false,
        render: (categoryName: string) => categoryName ? categoryName : '-'
    },
    {
        title: 'Amount',
        dataIndex: 'capacityAmount',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 5
        },
        showSorterTooltip: false,
        render: (amount: number) => amount ? `${amount} ${capacityUnit}` : '-'
    },
    {
        title: 'Period',
        dataIndex: 'dateFrom',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 6
        },
        showSorterTooltip: false,
        render: (dateFrom: string, tradeProposal: TradeProposal) => {
            if (!dateFrom && !tradeProposal.dateTo)
                return '-';

            const from = dateFrom ? moment(dateFrom).format(dateTimeFormat) : '{not selected}';
            const to = tradeProposal.dateTo ? moment(tradeProposal.dateTo).format(dateTimeFormat) : '{not selected}';

            return `${from} - ${to}`;
        }
    },
    {
        title: 'Price',
        dataIndex: 'totalPrice',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 7
        },
        showSorterTooltip: false,
        render: (totalPrice: number) => totalPrice ? `${totalPrice} ${priceUnit}` : '-'
    },
    {
        title: 'Procedure',
        dataIndex: 'tradingProcedure',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 8
        },
        showSorterTooltip: false
    },
    {
        title: 'Status',
        dataIndex: 'status',
        sorter: {
            compare: () => { return 0; },
            multiple: 9
        },
        className: 'table-column',
        showSorterTooltip: false,
        render: (status: TradeProposalStatus) => <TradeProposalStatusText
            status={status}
        />,
        onCell: (record: TradeProposal) => {
            return {
                onClick: (event: React.MouseEvent<HTMLElement>) => {
                    if (record.status === TradeProposalStatus.RESPONSE_RECEIVED && isFullControlUser) {
                        event.stopPropagation();
                        responseService.getResponse(record.id)
                            .then((data) => {
                                Modal.confirm({
                                    className: 'response-confirm-modal',
                                    content: <ResponseInformationText response={data}/>,
                                    title: 'Confirm pending response?',
                                    width: 650,
                                    maskClosable: true,
                                    okText: 'Accept',
                                    cancelText: 'Reject',
                                    onOk: () => handleAccept(record),
                                    cancelButtonProps: {
                                        danger: true,
                                        onClick: () => handleReject(record)
                                    }
                                });
                            })
                            .catch(() => {
                                notify({
                                    type: NotificationType.ERROR,
                                    message: 'Could not load response'
                                });
                            });
                    }
                }
            };
        }
    },
    ];
};

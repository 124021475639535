import { Role } from '../../components/auth/private-route';
import { CompanyRole } from '../../api/model/login-response';

export interface AuthenticationState {
    isLoggedIn: boolean;
    role: Role | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    companyRoleMap: CompanyRole[] | undefined;
    selectedCompany: CompanyRole | undefined;
}

export enum AuthenticationActions {
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    UPDATE_SELECTED_COMPANY = 'UPDATE_SELECTED_COMPANY',
    UPDATE_COMPANIES = 'UPDATE_COMPANIES'
}

export interface LoginAction {
    type: AuthenticationActions.LOGIN;
    payload: AuthenticationState;
}

export interface LogoutAction {
    type: AuthenticationActions.LOGOUT;
    payload: {};
}

export interface UpdateSelectedCompanyAction {
    type: AuthenticationActions.UPDATE_SELECTED_COMPANY;
    payload: {};
}

export interface UpdateCompaniesAction {
    type: AuthenticationActions.UPDATE_COMPANIES;
    payload: {};
}

export type AuthenticationActionTypes = LoginAction | LogoutAction | UpdateSelectedCompanyAction | UpdateCompaniesAction;

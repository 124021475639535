import React from 'react';
import { ResponseStatus } from '../../config/constants';
import { CheckCircleFilled, ClockCircleOutlined, CloseCircleFilled } from '@ant-design/icons';

import './trade-proposal-status-text.less';

export interface ResponseStatusTextProps {
    status: ResponseStatus;
    isRespondingShipper: boolean;
}

export const ResponseStatusText: React.FC<ResponseStatusTextProps> = (props: ResponseStatusTextProps) => {
    const {
        status,
        isRespondingShipper
    } = props;

    let element: React.ReactNode;

    switch(status) {
        case ResponseStatus.RESPONSE_REJECTED:
            element = <div className='status-wrapper'>
                <CloseCircleFilled className='cancelled'/> Rejected partial response
            </div>;
            break;
        case ResponseStatus.REJECTED_BY_TSO:
            element = <div className='status-wrapper'>
                <CloseCircleFilled className='cancelled'/> Rejected by TSO
            </div>;
            break;
        case ResponseStatus.RESPONSE_SUBMITTED:
            element = <div className='status-wrapper'>
                <ClockCircleOutlined className='circle clock'/>
                {isRespondingShipper ? 'Response submitted' : 'Response received'}
            </div>;
            break;
        case ResponseStatus.EXPIRED:
            element = <div className='status-wrapper expired-wrapper'>
                <div className='circle expired'/> Expired
            </div>;
            break;
        case ResponseStatus.TRADE:
            element = <div className='status-wrapper'>
                <CheckCircleFilled className='success'/> Trade
            </div>;
            break;
    }

    return <>{ element }</>;
};

import React from 'react';
import _ from 'lodash';
import { Field, FieldProps, ErrorMessage } from 'formik';
import { Form, Row, Col, Typography, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormItemProps as $FormItemProps } from 'antd/lib/form/FormItem';

import './form-item.less';

export interface FormItemProps extends $FormItemProps {
    showValidateSuccess?: boolean;
    children: React.ReactNode;
    name: string;
    labelType?: 'horizontal' | 'vertical';
    infoText?: string;
    validate?: (values: any) => string | void;
}

type Props = FormItemProps;

const FormItem: React.FC<Props> = props => {
    const {
        showValidateSuccess,
        children,
        name,
        label,
        labelType,
        infoText,
        validate,
        ...restProps
    } = props;

    const renderLabel = (): React.ReactNode => {
        return (
            <React.Fragment>
                {labelType !== 'horizontal'
                    ? label
                    : <Row className="labeled-input" gutter={64}>
                        <Col xs={10} className="labeled-input-text-wrapper">
                            <Typography.Text className="labeled-input-text">
                                {label}
                            </Typography.Text>
                            {infoText &&
                                <Tooltip title={infoText}>
                                    <InfoCircleOutlined/>
                                </Tooltip>
                            }
                        </Col>
                        <Col xs={14} className="input-container">
                            {children}
                        </Col>
                    </Row>
                }
            </React.Fragment>
        );
    };

    return (
        <Field name={name} validate={validate}>
            {({ form: { errors = {}, touched = {} } }: FieldProps) => {
                const error = _.get(errors, name, undefined);
                const isTouched = !!_.get(touched, name, false);
                const hasError = error !== undefined && isTouched;
                const isValid = !error && isTouched;

                return (
                    <Form.Item
                        {...restProps}
                        validateStatus={hasError ? 'error' : isValid && showValidateSuccess ? 'success' : undefined}
                        hasFeedback={isValid}
                        colon={false}
                        label={label && labelType !== 'horizontal' && renderLabel()}
                        labelCol={{ span: labelType === 'horizontal' ? 6 : 24 }}
                        labelAlign={labelType !== 'horizontal' ? 'left' : 'right'}
                        className={props.className + ' form-item'}
                    >
                        {labelType === 'horizontal' ? renderLabel() : children}
                        <div className="error-message">
                            <ErrorMessage name={name} />
                        </div>
                    </Form.Item>
                );
            }}
        </Field>
    );
};

export { FormItem };

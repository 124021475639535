import { CancelSource } from './cancel-source';
import { RestService } from './rest-service';
import { InvoiceModel } from './model/invoice-model';

class InvoiceService {
    private readonly restService: RestService;

    constructor(cancelSource: CancelSource = new CancelSource()) {
        this.restService = cancelSource.service;
    }

    public readonly getInvoices = (tradingResultId: number): Promise<InvoiceModel[]> => {
        return this.restService.get<InvoiceModel[]>(`/invoices/${tradingResultId}`)
            .then(response => response.data);
    };
}

const invoiceService = new InvoiceService();

export { invoiceService, InvoiceService };


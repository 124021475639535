import { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

import { ProposalType } from '../../../trade-proposals/new-trade-proposal/form/new-trade-proposal-schema';
import { ResponseStatus } from '../../../../../config/constants';

export enum RESPONSE_REPORT_FILTER_FIELDS {
    offerTypes = 'offerTypes',
    networkPoints = 'networkPoints',
    companies = 'companies',
    period = 'period',
    submittedAt = 'submittedAt',
    capacityRange = 'capacityRange',
    totalPriceRange = 'totalPriceRange',
    statuses = 'statuses'
}

export interface ResponseReportsFilterFormModel {
    offerTypes: ProposalType[];
    networkPoints: number[];
    companies: number[];
    period: RangeValue<Moment>;
    submittedAt: RangeValue<Moment>;
    capacityRange: [number, number];
    totalPriceRange: [number, number];
    statuses?: ResponseStatus[];
}

export const CAPACITY_RANGE_MIN = 0;
export const TOTAL_PRICE_MIN = 0;

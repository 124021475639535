import { CancelSource } from './cancel-source';
import { RestService } from './rest-service';
import { LoginResponse } from './model/login-response';
import { LoginFormModel } from '../pages/public/login-page/form/login-form-model';
import { ForgotPasswordFormModel } from '../pages/public/forgot-password/form/forgot-password-form-model';
import { ResetPasswordRequest } from './model/reset-password-request';
import { ConfirmLoginRequest } from './model/confirm-login-request';

class AuthenticationService {
    private readonly restService: RestService;

    constructor(cancelSource: CancelSource = new CancelSource()) {
        this.restService = cancelSource.service;
    }

    public readonly login = (loginRequest: LoginFormModel): Promise<LoginResponse> => {
        return this.restService.post<LoginResponse>('/login', loginRequest)
            .then(response => response.data);
    };

    public readonly refreshToken = (): Promise<LoginResponse> => {
        return this.restService.get<LoginResponse>('/authentication/refreshToken')
            .then(response => response.data);
    };

    public readonly logout = (): Promise<void> => {
        return this.restService.post<void>('/authentication/logout')
            .then(response => response.data);
    };

    public readonly requestPasswordReset = (email: ForgotPasswordFormModel): Promise<void> => {
        return this.restService.get<void>('/authentication/requestPasswordReset', { params: email })
            .then(response => response.data);
    };

    public readonly resetPassword = (resetModel: ResetPasswordRequest): Promise<LoginResponse> => {
        return this.restService.post<LoginResponse>('/authentication/resetPassword', resetModel)
            .then(response => response.data);
    };

    public readonly confirmLogin = (confirmRequest: ConfirmLoginRequest): Promise<LoginResponse> => {
        return this.restService.post<LoginResponse>('/authentication/confirmLogin', confirmRequest)
            .then(response => response.data);
    };
}

const authenticationService = new AuthenticationService();

export { authenticationService, AuthenticationService };

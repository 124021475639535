import React from 'react';
import { Col, Row, Skeleton, Typography } from 'antd';
import moment from 'moment';

import { capacityUnit, dateTimeFormat, ResponseStatusNameMap } from '../../../../config/constants';
import { ResponseDetails } from '../../../../api/model/response-details';
import { LabeledText } from '../../../../components/text/labeled-text';

import './response-information.less';

export interface ResponseInformationProps {
    loading: boolean;
    response?: ResponseDetails;
}

export const ResponseInformation = (props: ResponseInformationProps) => {
    const {
        loading,
        response
    } = props;
    const tradersListInformation = response?.tradersList
        && response.tradersList.name + ' (' + response.tradersList.companies.join(', ') + ')';

    return <Skeleton
        loading={loading}
        active={loading}
        paragraph={{ rows: 10 }}
    >
        <Row className={'response-information'}>

            <Typography.Title level={4} className={'title'}>
                Response
            </Typography.Title>

            <Col xs={24}>
                <LabeledText
                    value={response?.id.toString()}
                    label={'Response ID'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={response?.companyName}
                    label={'Company name'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={`${response?.capacityAmount} ${capacityUnit}`}
                    label={'Capacity amount'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={`${moment(response?.dateFrom).format(dateTimeFormat)}
                                - ${moment(response?.dateTo).format(dateTimeFormat)}`}
                    label={'Period'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={`${moment(response?.createdAt).format(dateTimeFormat)}`}
                    label={'Creation time'}
                    labelAlign={'right'}
                />
                <LabeledText
                    value={response && ResponseStatusNameMap[response.status]}
                    label={'Response status'}
                    labelAlign={'right'}
                />
                {response?.tradersList &&
                    <LabeledText
                        value={tradersListInformation}
                        label={'Applied traders list'}
                        labelAlign={'right'}
                    />
                }
            </Col>
        </Row>
    </Skeleton>;
};

import { ProposalType } from '../pages/private/trade-proposals/new-trade-proposal/form/new-trade-proposal-schema';

export enum AccessRight {
    FULL_CONTROL_USER = 'FULL_CONTROL_USER',
    READ_ONLY_USER = 'READ_ONLY_USER'
}

export const buySellOptions = [
    {
        label: 'BUY',
        value: ProposalType.SELL
    },
    {
        label: 'SELL',
        value: ProposalType.BUY
    }
];

export enum TradeProposalStatus {
    DRAFT = 'DRAFT',
    EXPIRED = 'EXPIRED',
    CANCELLED = 'CANCELLED',
    ACTIVE = 'ACTIVE',
    RESPONSE_RECEIVED = 'RESPONSE_RECEIVED',
    REJECTED_BY_TSO = 'REJECTED_BY_TSO',
    TRADE = 'TRADE'
}

export enum ResponseStatus {
    RESPONSE_SUBMITTED = 'RESPONSE_SUBMITTED',
    RESPONSE_REJECTED = 'RESPONSE_REJECTED',
    EXPIRED = 'EXPIRED',
    REJECTED_BY_TSO = 'REJECTED_BY_TSO',
    TRADE = 'TRADE'
}

export const ResponseStatusNameMap = {
    [ResponseStatus.RESPONSE_SUBMITTED]: 'Response received',
    [ResponseStatus.EXPIRED]: 'Expired',
    [ResponseStatus.RESPONSE_REJECTED]: 'Rejected partial response',
    [ResponseStatus.REJECTED_BY_TSO]: 'Rejected by TSO',
    [ResponseStatus.TRADE]: 'Trade'
};

export const MyResponseStatusNameMap = {
    [ResponseStatus.RESPONSE_SUBMITTED]: 'Response submitted',
    [ResponseStatus.EXPIRED]: 'Expired',
    [ResponseStatus.RESPONSE_REJECTED]: 'Rejected partial response',
    [ResponseStatus.REJECTED_BY_TSO]: 'Rejected by TSO',
    [ResponseStatus.TRADE]: 'Trade'
};

export const ResponseStatusNames = [
    ResponseStatus.RESPONSE_SUBMITTED,
    ResponseStatus.RESPONSE_REJECTED,
    ResponseStatus.EXPIRED,
    ResponseStatus.REJECTED_BY_TSO,
    ResponseStatus.TRADE
];

export const TradeProposalStatusNameMap = {
    [TradeProposalStatus.DRAFT]: 'Draft',
    [TradeProposalStatus.EXPIRED]: 'Expired',
    [TradeProposalStatus.CANCELLED]: 'Cancelled',
    [TradeProposalStatus.ACTIVE]: 'Active',
    [TradeProposalStatus.RESPONSE_RECEIVED]: 'Pending response',
    [TradeProposalStatus.REJECTED_BY_TSO]: 'Rejected by TSO',
    [TradeProposalStatus.TRADE]: 'Trade'
};

export const TradeProposalStatusNames = [
    TradeProposalStatus.DRAFT,
    TradeProposalStatus.EXPIRED,
    TradeProposalStatus.CANCELLED,
    TradeProposalStatus.ACTIVE,
    TradeProposalStatus.RESPONSE_RECEIVED,
    TradeProposalStatus.REJECTED_BY_TSO,
    TradeProposalStatus.TRADE
];

export const proposalCheckboxOptions = [
    {
        label: 'OFFERS',
        value: ProposalType.SELL
    },
    {
        label: 'REQUESTS',
        value: ProposalType.BUY
    }
];

export const displayUpdatedStatusesIn = [
    TradeProposalStatus.ACTIVE,
    TradeProposalStatus.RESPONSE_RECEIVED,
    TradeProposalStatus.CANCELLED,
    TradeProposalStatus.REJECTED_BY_TSO
];

export const dateFormat = 'DD-MM-YYYY';
export const dateTimeFormat = 'DD-MM-YYYY HH:mm';
export const gasDayDateFormat = 'DD-MM-YYYY 07:00';
export const capacityUnit = 'kWh/h';
export const priceUnit = 'EUR/kWh/h';
export const currency = 'EUR';
export const captchaSiteKey = '6Lc39swZAAAAADu8CWBEStPxQxKVkiy72yovNHL-';

import React, { useCallback, useEffect, useState } from 'react';
import { Select, Spin, Empty } from 'antd';
import { Field, FieldProps, useFormikContext } from 'formik';
import { SelectProps as $SelectProps } from 'antd/lib/select';
import { FormikFieldProps } from '../common/field-props';
import { NotificationType, notify } from '../../../service/notification-service';
import { LoadingOutlined } from '@ant-design/icons';

export interface SelectOptionModel {
    label: string;
    value: number | string;
    disabled?: boolean;
}

interface OwnProps<T> {
    service?: () => Promise<T[]>;
    callbackService?: any;
    mapItemToOptions?: (value: T) => SelectOptionModel;
    filter?: (item: T) => boolean;
    options?: SelectOptionModel[];
    lazyLoad?: boolean;
    shouldReload?: boolean;
    label?: string;
}

export type SelectProps<T> = FormikFieldProps & $SelectProps<any> & OwnProps<T>;

const SelectInput = <T extends {}>(props: SelectProps<T>) => {

    const {
        name,
        disabled,
        disableOnSubmit,
        options,
        mapItemToOptions,
        service,
        shouldReload,
        lazyLoad,
        filter,
        allowClear,
        callbackService,
        ...restProps
    } = props;

    const formik = useFormikContext<T>();
    const [asyncOptions, setAsyncOptions] = useState<SelectOptionModel[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);

    const onMenuOpen = useCallback((open: boolean) => {

        if (service && open && (shouldReload || asyncOptions.length < 1)) {
            setLoading(true);
            setAsyncOptions([]);
            service().then(items => {
                mapItemToOptions && filter && setAsyncOptions(items && items.filter(filter).map(mapItemToOptions));
                setLoading(false);
                callbackService && callbackService(items);
            }).catch(() => {
                setLoading(false);
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not load data',
                    description: 'Dropdown could not be filled'
                });
            });
        }
    }, [asyncOptions, callbackService, filter, mapItemToOptions, service, shouldReload]);

    useEffect(() => {
        if (lazyLoad) {
            onMenuOpen(true);
        }
    }, []);

    function handleChange(value: string | number) {
        formik.setFieldValue(name, value);
    }

    function handleBlur() {
        formik.setFieldTouched(name);
    }

    function getOptions(): React.ReactElement[] {

        if (!options && !asyncOptions) {
            return [];
        }
        const selectOptions = options || asyncOptions;

        return selectOptions.map(option => (
            <Select.Option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
            >
                {option.label}
            </Select.Option>
        ));

    }

    return (
        <Field name={name}>
            {({ field: { value }, form: { isSubmitting } }: FieldProps) => (
                <Select
                    {...restProps}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onDropdownVisibleChange={onMenuOpen}
                    showArrow={true}
                    value={(options !== null && options !== undefined && options.length > 0) || (asyncOptions.length > 0)
                        ? value
                        : undefined
                    }
                    loading={isLoading}
                    notFoundContent={isLoading ? <Spin size="large" indicator={<LoadingOutlined/>}> <Empty /></Spin> : null}
                    disabled={disabled || (disableOnSubmit && isSubmitting)}
                    allowClear={allowClear}
                    filterOption={(input, option) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    className="select-input"
                >
                    {getOptions()}
                </Select>
            )}
        </Field>
    );
};

SelectInput.defaultProps = {
    disableOnSubmit: true,
    allowClear: true,
    filter: () => true
};

export { SelectInput };

import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { navigationService } from '../../../service/navigation-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { RecordType, TableComponent } from '../../../components/table/table';
import { TradeProposal, tradeProposalsColumns } from './table/trade-proposals-columns';
import { ProposalType } from './new-trade-proposal/form/new-trade-proposal-schema';
import { FormikHelpers } from 'formik';
import { TradeProposalFilterForm } from './filter/trade-proposal-filter-form';
import {
    CAPACITY_RANGE_MIN,
    TOTAL_PRICE_MIN,
    TradeProposalFilterFormModel
} from './filter/trade-proposal-filter-form-model';
import { ArrayParam, DateTimeParam, NumberParam, useQueryParams } from 'use-query-params';
import { tradeProposalService } from '../../../api/trade-proposal-service';
import { SorterResult } from 'antd/es/table/interface';
import { TablePaginationConfig } from 'antd/lib/table';
import { PageResponse } from '../../../api/page-response';
import {
    getInitialDate, mapFormValuesToUrlParams,
    mapUrlParamsToFilterModel,
    parseQueryParamArrayAsNumber,
    parseQueryParamArrayAsString
} from '../../../utils/query-param-utils';
import { NotificationType, notify } from '../../../service/notification-service';
import { DownloadMenu } from '../../../components/menu/download-menu';
import { TradeProposalsTableHeader } from '../../../components/table/trade-proposals-table-header';
import { AccessRight } from '../../../config/constants';

import './trade-proposals-page.less';

const PAGE_SIZE = 20;

export const tradeProposalsQueryParams = {
    offerTypes: ArrayParam,
    networkPoints: ArrayParam,
    tsos: ArrayParam,
    procedures: ArrayParam,
    dateFrom: DateTimeParam,
    dateTo: DateTimeParam,
    capacityFrom: NumberParam,
    capacityTo: NumberParam,
    totalPriceFrom: NumberParam,
    totalPriceTo: NumberParam
};

export const TradeProposalsPage: React.FC = () => {
    const authentication = useSelector((state: RootState) => state.authentication);
    const selectedCompanyId = authentication.selectedCompany?.id;
    const isFullControlUser: boolean = authentication
        .selectedCompany?.role === AccessRight.FULL_CONTROL_USER;

    const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
    const [tradeProposals, setTradeProposals] = useState<PageResponse<TradeProposal>>({
        content: [],
        number: 0,
        totalElements: 0
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [sorts, setSorts] = useState<string | string[]>([]);
    const [currentPage, setCurrentPage] = useState<number>( 0 );
    const [maxPrice, setMaxPrice] = useState<number>(0);
    const [maxCapacity, setMaxCapacity] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);

    const [urlParams, setUrlParams] = useQueryParams(tradeProposalsQueryParams);

    const initialValues: TradeProposalFilterFormModel = {
        offerTypes: urlParams.offerTypes
            ? urlParams.offerTypes.filter((value): value is ProposalType => value !== null)
            : [ProposalType.SELL, ProposalType.BUY],
        networkPoints: urlParams.networkPoints ? parseQueryParamArrayAsNumber(urlParams.networkPoints) : [],
        tsos: urlParams.tsos ? parseQueryParamArrayAsNumber(urlParams.tsos) : [],
        procedures: urlParams.procedures ? parseQueryParamArrayAsString(urlParams.procedures) : [],
        period: [getInitialDate(urlParams.dateFrom), getInitialDate(urlParams.dateTo)],
        capacityRange: [
            urlParams.capacityFrom ? urlParams.capacityFrom : CAPACITY_RANGE_MIN,
            urlParams.capacityTo ? urlParams.capacityTo : maxCapacity
        ],
        totalPriceRange: [
            urlParams.totalPriceFrom ? urlParams.totalPriceFrom : TOTAL_PRICE_MIN,
            urlParams.totalPriceTo ? urlParams.totalPriceTo : maxPrice
        ]
    };

    useEffect(() => {
        setIsLoading(true);
        tradeProposalService.getMaximumFilterValues()
            .then(response => {
                setMaxPrice(response.price);
                setMaxCapacity(response.capacityAmount);
                tradeProposalService.getTradeProposals(
                    sorts ?? undefined,
                    currentPage ?? 0,
                    pageSize,
                    mapUrlParamsToFilterModel(urlParams, response.capacityAmount, response.price),
                    selectedCompanyId
                ).then(res => {
                    setTradeProposals(res);
                    setIsLoading(false);
                }).catch(() => {
                    notify({
                        type: NotificationType.ERROR,
                        message: 'Could not load trade proposals'
                    });
                });
            }).catch(() => {
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not load filter data'
                });
            });
    }, [currentPage, sorts, urlParams, pageSize, selectedCompanyId]);

    function handleReset() {
        const mappedParams = {
            offerTypes: undefined,
            networkPoints: undefined,
            tsos: undefined,
            procedures: undefined,
            dateFrom: undefined,
            dateTo: undefined,
            capacityFrom: CAPACITY_RANGE_MIN,
            capacityTo: maxCapacity,
            totalPriceFrom: TOTAL_PRICE_MIN,
            totalPriceTo: maxPrice
        };

        setUrlParams(mappedParams);
    }

    function handleSubmit(values: TradeProposalFilterFormModel, formik: FormikHelpers<TradeProposalFilterFormModel>) {
        formik.setSubmitting(true);
        const mappedParams = mapFormValuesToUrlParams(values);

        setUrlParams(mappedParams);
        setCurrentPage(0);
        formik.setSubmitting(false);
    }

    function handleTableChange(
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<RecordType<TradeProposal>>
                | SorterResult<RecordType<TradeProposal>>[]
    ) {
        const sort = Array.isArray(sorter)
            ? sorter.map(s => s.order ? `${s.field},${s.order?.replace('end', '')}` : '')
            : sorter.order ? `${sorter.field},${sorter.order?.replace('end', '')}` : '';

        setSorts(sort);
        setCurrentPage(pagination.current ? pagination.current - 1 : 0);
        pagination.pageSize && setPageSize(pagination.pageSize);
    }

    function handleDownloadClick() {
        tradeProposalService.downloadPublic(sorts, mapUrlParamsToFilterModel(urlParams, maxCapacity, maxPrice))
            .catch(() => {
                notify({
                    type: NotificationType.ERROR,
                    message: 'Could not download trade proposals'
                });
            });
    }

    function renderTable(): React.ReactNode {
        return <TableComponent
            filter={{
                form: <TradeProposalFilterForm
                    handleReset={handleReset}
                    maxCapacity={maxCapacity}
                    maxPrice={maxPrice}
                    disableButtons={isLoading}
                    isPublic={true}
                />,
                initialValues,
                handleSubmit,
                filtersVisible
            }}
            data={tradeProposals.content}
            columns={tradeProposalsColumns}
            loading={isLoading}
            onTableChange={handleTableChange}
            rowClassName={(record: TradeProposal) => record.ownProposal ? 'own-proposal' : ''}
            pagination={{
                total: !tradeProposals ? 1 : tradeProposals.totalElements,
                current: !tradeProposals ? 1 : tradeProposals.number + 1,
                pageSize: pageSize
            }}
            onRowClick={(record: TradeProposal) => {
                return { onClick: () => navigationService.goToTradeProposalDetailsPath(record.id) };
            }}
        />;
    }

    return (
        <Row justify="center">
            <Col xs={20}>
                <Row justify="space-between" gutter={[64, 24]}>
                    <TradeProposalsTableHeader
                        onCheckboxChange={setFiltersVisible}
                        title={'Trade proposals'}
                        showCreateButton={isFullControlUser}
                    />
                </Row>
                <Row gutter={[64, 24]}>
                    <Col xs={24}>
                        {renderTable()}
                    </Col>
                </Row>
                <Row justify="end">
                    <Col xs={4}>
                        <DownloadMenu onDownload={handleDownloadClick}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

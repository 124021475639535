import React from 'react';

import { CompanyInformationForm } from './company-information/company-information';
import { ContactPersonForm } from './contact-person/contact-person';
import { TSOForm } from './tso/tso';
import { WizardStepProps } from '../../../../components/wizard/wizard-step';
import { CompanyCreateRequest } from '../../../../api/model/company-create-request';
import { companyService } from '../../../../api/company-service';
import { ContactPersonModel } from '../../../../api/model/contact-person-model';
import { TSOFormModel, tsoFormSchema } from './tso/tso-schema';
import { companyInformationSchema } from './company-information/company-information-schema';
import { NotificationType, notify } from '../../../../service/notification-service';
import { CompanyResponse } from '../../../../api/model/company-response';
import { navigationService } from '../../../../service/navigation-service';
import { contactPersonSchema } from './contact-person/contact-person-schema';

let companyId: number | undefined = undefined;

export const getCompanyRegistrationSteps = (
    updateCompany: (company: CompanyResponse) => void,
    company?: CompanyResponse,
    id?: number
): WizardStepProps[] => {
    return [{
        title: 'Company Information',
        form: <CompanyInformationForm/>,
        onSubmit: (values: CompanyCreateRequest) => {
            return id
                ? companyService.updateCompany(id, values)
                    .then(response => updateCompany(response))
                    .catch(error => {
                        notify({
                            type: NotificationType.ERROR,
                            message: 'Could not update company information',
                            description: 'Please make sure that company fields are unique to this company'
                        });

                        return Promise.reject(error);
                    })
                : companyService.createCompany(values)
                    .then(response => companyId = response)
                    .catch(error => {
                        notify({
                            type: NotificationType.ERROR,
                            message: 'Could not save company information',
                            description: 'Please make sure that company fields are unique to this company'
                        });

                        return Promise.reject(error);
                    });
        },
        validationSchema: companyInformationSchema,
        initialValues: {
            eicCode: company ? company.eicCode : '',
            acerCode: company ? company.acerCode : '',
            companyName: company ? company.name : '',
            companyCode: company ? company.code : '',
            companyVatCode: company ? company.vatCode : '',
            streetNumber: company ? company.street : '',
            postalCode: company ? company.postalCode : '',
            city: company ? company.city : '',
            country: company ? company.country : '',
            bankName: company ? company.bankName : '',
            iban: company ? company.iban : '',
            bankCode: company ? company.bankCode : '',
            invoicingEmail: company ? company.email : ''
        },
    },
    {
        title: 'Contact Person',
        form: <ContactPersonForm/>,
        onSubmit: (values: ContactPersonModel[]) => {
            if (companyId !== undefined)
                return companyService.updateContactPeople(values, companyId)
                    .then(response => updateCompany(response))
                    .catch((error) => {
                        notify({
                            type: NotificationType.ERROR,
                            message: 'Could not update contact people information'
                        });

                        return Promise.reject(error);
                    });
            else if (id)
                return companyService.updateContactPeople(values, id)
                    .then(response => updateCompany(response))
                    .catch(error => {
                        notify({
                            type: NotificationType.ERROR,
                            message: 'Could not update contact people information'
                        });

                        return Promise.reject(error);
                    });
        },
        validationSchema: contactPersonSchema,
        initialValues: company ? company.contactPeople : []
    },
    {
        title: 'TSO',
        form: <TSOForm/>,
        onSubmit: (values: TSOFormModel) => {
            if (companyId !== undefined)
                return companyService.assignCompanyToTso({ tsoIds: values.TSO }, companyId)
                    .then(() => navigationService.goToUsersManagementPath());
            else if(id)
                return companyService.assignCompanyToTso({ tsoIds: values.TSO }, id)
                    .then(() => navigationService.goToUsersManagementPath());
        },
        validationSchema: tsoFormSchema,
        initialValues: { TSO: company ? company.tso.map(tso => tso.id) : [] }
    }
    ];
};

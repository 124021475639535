import React from 'react';
import { Menu, Button, Row, Col } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import './list.less';

export interface ListProps {
    options: ListOption[];
    hideScroll?: boolean;
}

export interface ListOption {
    label: string | React.ReactNode;
    action?: () => void;
    additionalAction?: ListAdditionalAction;
}

export interface ListAdditionalAction {
    label: string;
    action: () => void;
}

export const List: React.FC<ListProps> = (props: ListProps) => {

    const {
        options,
        hideScroll
    } = props;

    return <Menu className={`list ${hideScroll ? '' : 'scroll'}`} defaultSelectedKeys={['1']}>
        {options.map((option, index) => {
            return <Menu.Item key={index + 1} onClick={option.action}>
                <Row justify="space-between">
                    <Col xs={18} className="label-column">
                        {option.label}
                        {option.additionalAction &&
                        <Button
                            onClick={option.additionalAction?.action}
                            type="link"
                            className="list-additional-action"
                        >
                            {option.additionalAction?.label}
                        </Button>
                        }
                    </Col>
                    <Col xs={4} className="selected-arrow-column">
                        <RightOutlined />
                    </Col>
                </Row>
            </Menu.Item>;
        })}
    </Menu>;
};

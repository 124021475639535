import React from 'react';
import { ColumnProps } from 'antd/es/table';
import Avatar from 'antd/lib/avatar/avatar';
import moment from 'moment';

import { ProposalType } from '../../../trade-proposals/new-trade-proposal/form/new-trade-proposal-schema';
import {
    capacityUnit,
    dateTimeFormat,
    priceUnit,
    ResponseStatus
} from '../../../../../config/constants';
import { ResponseReport } from '../../../../../api/model/response-report';
import { ResponseStatusText } from '../../../../../components/text/response-status-text';

export const responseReportsColumns: ColumnProps<ResponseReport>[] = [
    {
        title: 'Type',
        dataIndex: 'proposalType',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 1
        },
        showSorterTooltip: false,
        render: (proposalType: ProposalType) => {
            return proposalType === ProposalType.SELL
                ? <Avatar className='offer'> B </Avatar>
                : <Avatar className='request'> S </Avatar>;
        }
    },
    {
        title: 'Company',
        dataIndex: 'companyName',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 2
        },
        showSorterTooltip: false
    },
    {
        title: 'Network point',
        dataIndex: 'networkPointName',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 3
        },
        showSorterTooltip: false
    },
    {
        title: 'Amount',
        dataIndex: 'capacityAmount',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 4
        },
        showSorterTooltip: false,
        render: (amount: number) => `${amount} ${capacityUnit}`
    },
    {
        title: 'Period',
        dataIndex: 'dateFrom',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 5
        },
        showSorterTooltip: false,
        render: (dateFrom: string, response: ResponseReport) =>
            `${moment(dateFrom).format(dateTimeFormat)} - ${moment(response.dateTo).format(dateTimeFormat)}`
    },
    {
        title: 'Price',
        dataIndex: 'totalPrice',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 6
        },
        showSorterTooltip: false,
        render: (totalPrice: number) => `${totalPrice} ${priceUnit}`
    },
    {
        title: 'Submission date',
        dataIndex: 'createdAt',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 7
        },
        showSorterTooltip: false,
        render: (submittedAt: string) =>
            `${moment(submittedAt).format(dateTimeFormat)}`
    },
    {
        title: 'Status',
        dataIndex: 'status',
        className: 'table-column',
        sorter: {
            compare: () => { return 0; },
            multiple: 8
        },
        showSorterTooltip: false,
        render: (status: ResponseStatus) =>
            <ResponseStatusText status={status} isRespondingShipper={false}/>
    }
];

import React from 'react';
import { Col, Row, Typography } from 'antd';
import { FormikHelpers } from 'formik';
import { authenticationService } from '../../../api/authentication-service';
import { NotificationType, notify } from '../../../service/notification-service';
import { ForgotPasswordForm } from './form/forgot-password-form';
import { ForgotPasswordFormModel } from './form/forgot-password-form-model';
import { navigationService } from '../../../service/navigation-service';

const ForgotPasswordPage: React.FC = () => {
    const initialValues: ForgotPasswordFormModel = {
        email: '',
    };

    const handleSubmit = (values: ForgotPasswordFormModel, formikHelpers: FormikHelpers<ForgotPasswordFormModel>) => {
        authenticationService.requestPasswordReset(values).finally(() => {
            notify({
                type: NotificationType.SUCCESS,
                message: 'Email sent',
                description: 'If an account is associated with this address an e-mail containing instructions on how to reset your password has been sent to the indicated e-mail address.'
            });
        });
        navigationService.goToLoginPage();
        formikHelpers.setSubmitting(false);
    };

    return (
        <Row justify="center" className="login-page">
            <Col xs={20} sm={8}>
                <Typography.Title level={4}>Password recovery</Typography.Title>
                <ForgotPasswordForm
                    initialValues={initialValues}
                    handleSubmit={handleSubmit}
                />
            </Col>
        </Row>
    );
};

export { ForgotPasswordPage };
